import {
  ConfigWithCallbacks,
  ConfigWithCallbacks as SizeAdvConfigWithCallbacks,
} from '@root/types/config'
import { Data, Response, SizeAdvisorRequest } from '@root/types/sizeadvisor'
import { createApi, fetchBaseQuery, skipToken } from '@reduxjs/toolkit/query/react'

import { defaultList } from '@root/data/intervals'
import { useConfig } from '@root/Context'

const TAG = 'frameSize'

export const sizeAdvisorApi = createApi({
  reducerPath: 'sizeAdvisorApi',
  tagTypes: [TAG],
  // baseQuery: staggeredBaseQueryWithBailOut(
  // 	process.env.REACT_APP_SIZE_ADVISOR_URL ||
  // 		'http://aa4d1aed7e71c466c801b238afe87107-81696736.us-east-1.elb.amazonaws.com:8080/sizeadvisor/api/v1',
  // 	false,
  // 	true,
  // ),
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_SIZE_ADVISOR_URL ||
      'http://aa4d1aed7e71c466c801b238afe87107-81696736.us-east-1.elb.amazonaws.com:8080/sizeadvisor/api/v1',
  }),
  endpoints: builder => ({
    getConfig: builder.query<
      {
        success: boolean
        message: string
        data: null
      },
      undefined
    >({
      query: () => ({
        url: '/config/probe',
      }),
    }),

    getFrameSizeFamily: builder.query<Data, SizeAdvisorRequest>({
      query: body => ({
        url: '/sizeAdvisor',
        method: 'POST',
        body,
      }),
      providesTags: () => [TAG],
      transformResponse: (response: Response) => {
        const length = response.data.advisorIntervals.intervals.length - 1
        if (response.data.advisorIntervals.intervals[0].sizeName === 'empty') {
          response.data.advisorIntervals.intervals[0].enabledList = defaultList
        }
        if (response.data.advisorIntervals.intervals[length].sizeName === 'empty') {
          response.data.advisorIntervals.intervals[length].enabledList = defaultList
        }
        return response.data
      },
    }),
  }),
})

export const formatFaceInfo = (faceBreadth?: number) => {
  if (faceBreadth === undefined) {
    return undefined
  }
  const faceInfo: SizeAdvisorRequest = {
    hingeDistance: parseInt(faceBreadth.toFixed(0)),
    fitValue: 'NORMAL',
  }
  return faceInfo
}

export const useGetFrameSizeFamilyQuery = (faceBreadth: number | undefined) => {
  const { enableSizeAdvisor } = useConfig() as ConfigWithCallbacks | SizeAdvConfigWithCallbacks
  return sizeAdvisorApi.useGetFrameSizeFamilyQuery(
    (enableSizeAdvisor && formatFaceInfo(faceBreadth)) || skipToken,
  )
}
export const useGetFrameSizeFamilyState = () => {
  return sizeAdvisorApi.endpoints.getFrameSizeFamily.useQueryState(skipToken)
}

export const useGetSAFrameSizeFamilyQuery = (glassesInfo: SizeAdvisorRequest | undefined) =>
  sizeAdvisorApi.useGetFrameSizeFamilyQuery(glassesInfo || skipToken)

export const { useGetConfigQuery } = sizeAdvisorApi
