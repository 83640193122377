const Events = {
  PAUSE: 'vm_pause_event',
  RESUME: 'vm_resume_event',
  TOPIC_SCREENSHOT_REQ: 'TOPIC_SCREENSHOT_REQ',
  TOPIC_SCREENSHOT_RES: 'TOPIC_SCREENSHOT_RES',
};

export {
  Events,
};
