import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppData } from '@root/types/common'

const initialState: AppData = {
  minimized: false,
  onBoardingDone: false,
  lastTryOnPage: '/try-on-live',
}

//
export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMinimized: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      minimized: payload,
    }),
    setOnBoardingDone: (state, { payload }: PayloadAction<boolean>) => {
      state.onBoardingDone = payload
    },
    setLastTryOnPage: (state, { payload }: PayloadAction<AppData['lastTryOnPage']>) => {
      state.lastTryOnPage = payload
    },
  },
})

export default slice.reducer
