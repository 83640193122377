import { name, version } from "../../../package.json";
import { IRemoteAppViewSession } from "../../domain/model/IRemoteAppViewSession";
import { RemoteLogConfig } from "../../domain/RemoteLogConfig";
import { VmAnalyticsApiConfig } from "../../domain/service/config/VmAnalyticsApiConfig";
import { sendXhrRequest } from "../../helpers/RequestHelper";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "AppViewSessionAdapter");

class AppViewSessionAdapter implements LogAdapter<IRemoteAppViewSession> {

  private config = new VmAnalyticsApiConfig();

  public log(message: IRemoteAppViewSession): Promise<boolean> {
    const sessionId = RemoteLogConfig.getInstance().currentUuid;
    if (sessionId) {
      const jsonBody = JSON.stringify({
        browserSessionUuid: sessionId,
        date: new Date().toISOString(),
        statusCode: message.statusCode,
        statusText: message.statusText,
      });

      const requestUrl = this.config.appViewSessionApiEndpoint;
      return sendXhrRequest(
        "POST",
        requestUrl,
        jsonBody,
        "",
        [],
        {
          "Accept": "application/json",
          "Content-type": "application/json",
          // "Referrer-Policy": "unsafe-url"
        },
      ).catch((e) => {
        return Promise.reject(e);
      });

    } else {
      logger.warn("sessionId not available");
      return Promise.reject();
    }
  }
}

export { AppViewSessionAdapter };
