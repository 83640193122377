import { InitializeParameters, InitializeOptions } from '../interfaces/InitializeParameters';

class FrameAdvisorError extends Error {
  public type: string;

  constructor(type: string, message: string, error?: Error) {
    super(message);
    this.type = type;
    this.name = type;
    this.message = message;
    this.stack = error?.stack;

    Object.setPrototypeOf(this, FrameAdvisorError.prototype);
  }
}

class InitializationError extends FrameAdvisorError {
  public static type = 'InitializationError';

  constructor(input: InitializeParameters, message: string, error?: Error) {
    super(InitializationError.type, `failed to initialize component with input=${JSON.stringify(input)}, message=${(!!message) ? message : error?.message}`, error);
    Object.setPrototypeOf(this, InitializationError.prototype);
  }
}

class NotInitializedError extends FrameAdvisorError {
  public static type = 'NotInitializedError';

  constructor() {
    super(NotInitializedError.type, 'component must first be initialized');
    Object.setPrototypeOf(this, NotInitializedError.prototype);
  }
}

class LocalizationError extends FrameAdvisorError {
  public static type = 'LocalizationError';

  constructor(message: string, error?: Error) {
    super(LocalizationError.type, message, error);
    Object.setPrototypeOf(this, LocalizationError.prototype);
  }
}

class MountError extends FrameAdvisorError {
  public static type = 'MountError';

  constructor(divId: string, error?: Error) {
    super(MountError.type, `failed to mount div in id ${divId}`, error);
    Object.setPrototypeOf(this, MountError.prototype);
  }
}

class RequestError extends FrameAdvisorError {
  public static type = 'RequestError';

  constructor(message: string, error?: Error) {
    super(RequestError.type, message, error);
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

class ServerError extends FrameAdvisorError {
  public static type = 'ServerError';

  constructor(message: string, error?: Error) {
    super(ServerError.type, message, error);
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

class PrivacyError extends FrameAdvisorError {
  public static type = 'PrivacyError';

  constructor(message: string) {
    super(PrivacyError.type, message);
    Object.setPrototypeOf(this, PrivacyError.prototype);
  }
}

class TimeoutError extends FrameAdvisorError {
  public static type = 'TimeoutError';

  constructor(duration: number) {
    super(TimeoutError.type, `request has timed out after ${duration}ms`);
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

class InitializationParamsError extends FrameAdvisorError {
  public static type = 'InitializationParamsError';

  constructor(params: Partial<InitializeOptions>) {
    super(InitializationParamsError.type, `Failed to initialize capture component, check your parameters ${JSON.stringify(params)}`);
    Object.setPrototypeOf(this, InitializationParamsError.prototype);
  }
}

class RecordingError extends FrameAdvisorError {
  public static type = 'RecordingError';

  constructor(message: string) {
    super(RecordingError.type, message);
    Object.setPrototypeOf(this, RecordingError.prototype);
  }
}

export {
  FrameAdvisorError,
  InitializationError,
  NotInitializedError,
  LocalizationError,
  MountError,
  RequestError,
  ServerError,
  PrivacyError,
  TimeoutError,
  InitializationParamsError,
  RecordingError,
};
