import { v4 as uuid } from "uuid";
import { DBBrowserSessionFactory } from "../../../../adapter/db/factory/DBBrowserSessionFactory";
import { LogsConfig } from "../../../../config/LogsConfig";
import { IRemoteBrowserSession } from "../../../model/IRemoteBrowserSession";

class BrowserSessionService {

  public log(message: IRemoteBrowserSession): void {
    const timestamp = this.now();
    LogsConfig.getInstance().isBrowserSessionActive().then((isActive) => {
      if (isActive) {
        this.queueMessage(message, timestamp);
      }
    });
  }

  private queueMessage(message: IRemoteBrowserSession, timestamp: string) {
    this.dbInstance().getDB().setItem(uuid(), {
      date: timestamp,
      deviceResolution: message.deviceResolution,
      deviceWebcam: message.deviceWebcam,
      deviceWebgl: message.deviceWebgl,
    });
  }

  private dbInstance() {
    return DBBrowserSessionFactory.getInstance();
  }

  private now() {
    return new Date().toISOString();
  }

}

export { BrowserSessionService };
