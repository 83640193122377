import React from 'react';

const ErrorIcon = () => {
  return (
    <svg className='error-icon' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <circle cx='8' cy='8.5' r='7' stroke='#C83430' strokeWidth='2'/>
      <path fill='#C83430' d='M7 4.5h2v5H7z'/>
      <rect x='7' y='10.5' width='2' height='2' rx='1' fill='#C83430'/>
    </svg>
  );
};

export {
  ErrorIcon
};
