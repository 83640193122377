import { Appender, ILogEvent, LogAppender, LogLevel } from "@log4js2/core";
import { LogMessage } from "../model/LogMessage";
import { RemoteLogService } from "../service/log/producer/RemoteLogService";

@Appender("RemoteLogAppender")
class RemoteLogAppender extends LogAppender<{}> {

  private logService = new RemoteLogService();

  public static get appenderName(): string {
    return "RemoteLogAppender";
  }

  public append(logEvent: ILogEvent) {
    if (logEvent && logEvent.level && logEvent.level <= this.getLogLevel()) {
      this.log(logEvent);
    }
  }

  private log(logEvent: ILogEvent) {
    switch (logEvent.level) {
      case LogLevel.FATAL:
      case LogLevel.ERROR: {
        if (logEvent.error) {
          this.logService.log(this.newLogMessage("E", logEvent));
        } else {
          this.logService.log(this.newLogMessage("E", logEvent));
        }
        break;
      }
      case LogLevel.WARN: {
        this.logService.log(this.newLogMessage("W", logEvent));
        break;
      }
      case LogLevel.INFO: {
        this.logService.log(this.newLogMessage("I", logEvent));
        break;
      }
      case LogLevel.DEBUG:
      case LogLevel.TRACE: {
        break;
      }
      default:
        break;
    }
  }

  private newLogMessage(logLevel: string, logEvent: ILogEvent) {
    return new LogMessage(logLevel, logEvent.logger || "", logEvent.message || "");
  }
}

export { RemoteLogAppender };
