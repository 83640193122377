import * as t from 'io-ts'

import {
  BulletPointsContentItem,
  BulletPointsRequest,
  BulletPointsResult,
  OnBoardingItem,
  OnBoardingItemCodec,
  OnBoardingRequest,
  OnBoardingResult,
  PlaylistItem,
  PlaylistItemCodec,
  PlaylistRequest,
  PlaylistResult,
  ResultCMS,
  getResultCMSCodec,
} from '@root/types/cms'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { fold } from 'fp-ts/lib/Either'
import { formatValidationErrors } from 'io-ts-reporters'
import { pipe } from 'fp-ts/lib/function'

// import { staggeredBaseQueryWithBailOut } from '@root/utils/services'

export const onLeft = (errors: t.Errors) => {
  throw new Error(formatValidationErrors(errors).join(', '))
}

export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  // baseQuery: staggeredBaseQueryWithBailOut(
  // 	process.env.REACT_APP_CMS_BASE_URL ||
  // 		'https://uat-multiplatformh2-cms.luxottica.com/caas/v1/contents',
  // ),
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_CMS_BASE_URL ||
      'https://uat-multiplatformh2-cms.luxottica.com/caas/v1/contents',
  }),
  endpoints: builder => ({
    getOnBoardingData: builder.query<OnBoardingResult[], OnBoardingRequest>({
      query: ({ locale, country, area, channelKey, app }) =>
        `/frameadvisor-${locale}/?docTypes=CMChannel&tagNames=onboarding,${app}&filterRules=ecommerce.frameadvisor.${channelKey}&country=${country.toUpperCase()}&area=${area}`,
      transformResponse: (res: ResultCMS<OnBoardingItem>) => {
        const onRight = (res: ResultCMS<OnBoardingItem>) => {
          const mainRow = res.content.result[0].grid.rows.find(
            row => row.placements[0].name === 'main',
          )
          if (!mainRow) {
            throw Error(
              'Object with name = main not found in response.content.result[0].grid.rows[n].placements[0]',
            )
          }

          const items = mainRow.placements[0].items[0].items
          if (items.length === 0) {
            throw Error('mainRow.placements[0].items[0].items has length 0')
          }

          return items.map(item => ({
            title: item.Title,
            image: item.Image[0]?.data.uri,
            video: item.VideoMp4[0]?.data.uri,
            description: item.BannerDescription,
            links: item.Link.map(link =>
              link.LinkDescriptor ? link.LinkDescriptor[0].MenuLabel : '',
            ),
          }))
        }

        return pipe(getResultCMSCodec(OnBoardingItemCodec).decode(res), fold(onLeft, onRight))
      },
    }),

    getPlaylist: builder.query<PlaylistResult[], PlaylistRequest>({
      query: ({ locale, country, area, channelKey }) =>
        `/frameadvisor-${locale}/?docTypes=CMChannel&tagNames=playlist,frameadvisor&filterRules=ecommerce.frameadvisor.${channelKey}&country=${country.toUpperCase()}&area=${area}`,
      transformResponse: (res: ResultCMS<PlaylistItem>) => {
        const onRight = (res: ResultCMS<PlaylistItem>) => {
          const pageTitle = res.content.result[0].teaserTitle
          const pageDescription = res.content.result[0].teaserText || ''

          const dataRows = res.content.result[0].grid.rows
          const mainRow = dataRows[0]
          if (!mainRow) {
            throw Error('Object with name = main not found in result → grid → rows → placement')
          }
          const items = mainRow.placements[0].items

          if (!items || items.length <= 0) {
            throw Error('Array items in object with name = main are empty or not found')
          }

          return items.map(item => {
            const formattedSize = item.Image[0].crops.find(el => el.name === '16x9')
            const ImageWidth = formattedSize?.sizes[0].width.toString()
            const ImageName = formattedSize?.name
            const formattedCropUrl =
              item.Image &&
              item.Image.length > 0 &&
              item.Image[0].cropUrlTemplate.replace(
                '{cropName}/{width}',
                `${ImageName}/${ImageWidth}`,
              )

            return {
              id: item.id,
              pageTitle,
              pageDescription,
              title: item.Title,
              croopUrl: (formattedCropUrl !== false && formattedCropUrl) || '',
              imageName: (item.Image && item.Image.length > 0 && item.Image[0].crops[0].name) || '',
              imageSizes:
                (item.Image && item.Image.length > 0 && item.Image[0].crops[0].sizes[0].width) || 0,
              imageUri: (item.Image && item.Image.length > 0 && item.Image[0].data.uri) || '',
              videoUri: (item.Video && item.Video.length > 0 && item.Video[0].data.uri) || '',
              productType: item.ProductType.length
                ? item.ProductType[0].externalReference.toLowerCase() === 'sun'
                  ? 'sunglasses'
                  : 'eyeglasses'
                : undefined,
              description: item.Description,
              deboostTags: item.DeboostTags,
              elevate: item.ElevationMOCO,
              exclude: item.ExclusionMOCO,
              tags: item.Tags,
            }
          })
        }

        // because random null value on DeboostTags and Video
        let resItems = res.content.result[0].grid.rows[0].placements[0].items
        const validResItems = Object.values(resItems).map(item => {
          if (item.DeboostTags === null) {
            item.DeboostTags = []
          }

          if (item.Video === null) {
            item.Video = []
          }

          return item
        })
        resItems = validResItems

        return pipe(getResultCMSCodec(PlaylistItemCodec).decode(res), fold(onLeft, onRight))
      },
    }),
    getBulletPoints: builder.query<BulletPointsContentItem[], BulletPointsRequest>({
      query: ({ locale, country, area, channelKey }) =>
        `/frameadvisor-${locale}/?docTypes=CMSSHighlightPoint&filterRules=ecommerce.frameadvisor.${channelKey}&country=${country.toUpperCase()}&area=${area}`,
      transformResponse: (res: BulletPointsResult) => {
        return res.content.result
      },
    }),
  }),
})

export const { useGetOnBoardingDataQuery, useGetPlaylistQuery, useGetBulletPointsQuery } = cmsApi
