import { CatalogConfiguration } from '../interfaces/CatalogConfiguration';
import { getEnvironmentConfig } from './GlassesApiConfig';

const upcsAvailabilityAuthenticatedApi = '/authenticated/upc/{upcs}/availability';
const upcsAvailabilityApiV2 = '/api/upc/v2/{upcs}/availability';

const transitionColorApi = '/api/transition/v2/vocabulary';

const CatalogueApiConfig = {
  getUpcsAvailabilityApiEndpoint(config: CatalogConfiguration, upcString: string): string {
    const envConfig = getEnvironmentConfig(config.env);

    //TODO: remove after prod api is complete
    if (config.env.includes('TEST')) {
      return `${envConfig.catalogHost}${upcsAvailabilityApiV2}`.replace('{upcs}', upcString);
    } else {
      return (!!config.key) ?
        `${envConfig.catalogHost}${upcsAvailabilityApiV2}`.replace('{upcs}', upcString)
          :
        `${envConfig.catalogHost}${upcsAvailabilityApiV2}`.replace('{upcs}', upcString);
    }
  },

  getTransitionEndpoint(config: CatalogConfiguration): string {
    const envConfig = getEnvironmentConfig(config.env);
    return `${envConfig.transitionHost}${transitionColorApi}`;
  }
};

export { CatalogueApiConfig };
