import { LogsConfigurationRetriever } from "./LogsConfigurationRetriever";

class LogsConfig {

  public static getInstance() {
    if (!LogsConfig.instance) {
      LogsConfig.instance = new LogsConfig();
    }
    return LogsConfig.instance;
  }

  private static instance: LogsConfig;

  private appViewSessionActive: Promise<boolean>;
  private browserSessionActive: Promise<boolean>;
  private trackingStatusActive: Promise<boolean>;
  private clientLogActive: Promise<boolean>;
  private serverLogActive: Promise<boolean>;

  private configuration = new LogsConfigurationRetriever().getConfiguration();

  public constructor() {
    this.appViewSessionActive = new Promise((resolve) => {
      this.configuration
        .then((cfg) => {
          resolve(cfg.appviewSessionIsActive);
        })
        .catch((reason) => {
          resolve(true);
        });
    });
    this.browserSessionActive = new Promise((resolve) => {
      this.configuration
        .then((cfg) => {
          resolve(cfg.browserSessionIsActive);
        })
        .catch((reason) => {
          resolve(true);
        });
    });
    this.trackingStatusActive = new Promise((resolve) => {
      this.configuration
        .then((cfg) => {
          resolve(cfg.trackingStatusIsActive);
        })
        .catch((reason) => {
          resolve(true);
        });
    });
    this.clientLogActive = new Promise((resolve) => {
      this.configuration
        .then((cfg) => {
          resolve(cfg.clientLogIsActive);
        })
        .catch((reason) => {
          resolve(true);
        });
    });
    this.serverLogActive = new Promise((resolve) => {
      this.configuration
        .then((cfg) => {
          resolve(cfg.serverLogIsActive);
        })
        .catch((reason) => {
          resolve(true);
        });
    });
  }

  public async isAppViewSessionActive() {
    return this.appViewSessionActive;
  }

  public async isBrowserSessionActive() {
    return this.browserSessionActive;
  }

  public async isTrackingStatusActive() {
    return this.trackingStatusActive;
  }

  public async isServerLogActive() {
    return this.serverLogActive;
  }

  public async isClientLogActive() {
    return this.clientLogActive;
  }

}

export { LogsConfig };
