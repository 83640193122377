import { AbstractNativeBridge, NativeBridgeParameters } from './AbstractNativeBridge';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    webkit: any;
    NativeBridge: NativeBridge;
  }
}

class NativeBridge extends AbstractNativeBridge {

  public static isNativeBridgePresent(): boolean {
    return (
      typeof window.webkit === 'object' &&
      typeof(window.webkit.messageHandlers) === 'object' &&
      typeof(window.webkit.messageHandlers.startNativeCam) === 'object'
      );
  }

  public onStartNativeCam: ((resolution: any, error: any) => void) | undefined;
  public onCamFrame: ((base64: string) => void) | undefined;

  public close = (): void => {
    window.webkit.messageHandlers.closeNativeCam.postMessage(undefined);
  }

  public start = (requiredResolution: NativeBridgeParameters): void => {
    window.webkit.messageHandlers.startNativeCam.postMessage(requiredResolution);
  }

  public pause = (): void => {
    // in old versions of native bridge there's no pause/resume support
    if (window.webkit.messageHandlers.pauseNativeCam) {
      window.webkit.messageHandlers.pauseNativeCam.postMessage(undefined);
    }
  }

  public resume = (): void => {
    // in old versions of native bridge there's no pause/resume support
    if (window.webkit.messageHandlers.resumeNativeCam) {
      window.webkit.messageHandlers.resumeNativeCam.postMessage(undefined);
    }
  }
}

export { NativeBridge };
