import { Id, UserTokens } from '@root/types/common'
import { delFaceBreadth } from './persistance'
import { HingeValueRequest, ModelAttributesRequest } from './types/sizeadvisor'

const TOKENS_KEY = 'sa-tokens'

export const setTokens = (tokens: UserTokens) => {
  window.localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens))
}

export const getTokens = (): UserTokens => {
  const tokens = window.localStorage.getItem(TOKENS_KEY)
  return tokens ? JSON.parse(tokens) : { accessToken: '', refreshToken: '' }
}

export const delTokens = () => {
  window.localStorage.removeItem(TOKENS_KEY)
}

const PICTURE_ID_KEY = 'sa-picture-id'

export const setPictureId = (pictureUuid: Id) => {
  window.localStorage.setItem(PICTURE_ID_KEY, pictureUuid)
}

export const getPictureId = () => {
  return window.localStorage.getItem(PICTURE_ID_KEY)
}

export const delPictureId = () => {
  window.localStorage.removeItem(PICTURE_ID_KEY)
}

const MODEL_INFO_KEY = 'sa-model-info'

export const setModelInfo = (modelInfo: ModelAttributesRequest) => {
  window.localStorage.setItem(MODEL_INFO_KEY, JSON.stringify(modelInfo))
}

export const getModelInfo = () => {
  const modelInfo = window.localStorage.getItem(MODEL_INFO_KEY)
  return modelInfo ? JSON.parse(modelInfo) : null
}

export const delModelInfo = () => {
  window.localStorage.removeItem(MODEL_INFO_KEY)
}

const HINGE_2_HINGE_KEY = 'sa-hinge-2-hinge'

export const setHinge2Hinge = (hinge2hinge: HingeValueRequest) => {
  window.localStorage.setItem(HINGE_2_HINGE_KEY, JSON.stringify(hinge2hinge))
}

export const getHinge2Hinge = () => {
  const hinge2Hinge = window.localStorage.getItem(HINGE_2_HINGE_KEY)
  return hinge2Hinge ? JSON.parse(hinge2Hinge) : null
}

export const delHinge2Hinge = () => {
  window.localStorage.removeItem(HINGE_2_HINGE_KEY)
}

export const delSessionInfoWithoutToken = () => {
  delPictureId()
  delModelInfo()
  delPictureId()
  delFaceBreadth()
  delHinge2Hinge()
}

export const delSessionInfo = () => {
  delTokens()
  delSessionInfoWithoutToken()
}
