import React, { useEffect, useState } from 'react';
import { LocalizationHelper } from '../helpers/LocalizationHelper';
import { BipaCallbacks } from '../interfaces/BipaCallbacks';
import { PrivacyLocalizationKey } from '../interfaces/PrivacyLocalization';
import { BipaPrivacyOptions } from '../interfaces/PrivacyOptions';

import './PrivacyAgreement.scss';
import { YesNoCheckBox } from './YesNoCheckbox';

interface PrivacyAgreementProps {
  region: string,
  isIllinoisUser: boolean;
  privacyOptions: BipaPrivacyOptions;
  callbacks: BipaCallbacks;
  onSetIllinoisUser: (isIllinois: boolean) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const PrivacyAgreement = (props: PrivacyAgreementProps) => {
  
  const [showGradient, setShowGradient] = useState<boolean>(true);

  useEffect(() => {
    props.callbacks?.onDisplayPage?.('PRIVACY');

    try {
      if (document.getElementsByClassName('scrollable-container')[0].clientHeight >= document.getElementsByClassName('scrollable-content')[0].clientHeight) {
        setShowGradient(false);
      }
    } catch (e) { }
  }, []);

  const trackScrolling = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop < 2) {
      !showGradient && setShowGradient(true);
    } else {
      showGradient && setShowGradient(false);
    }
  };

  return (
    <div id='privacy-agreement'>

      <div className='scrollable-container' onScroll={trackScrolling}>

        <div className='scrollable-content'>

          <h1 id='PRIVACY_AGREEMENT_TITLE'>{LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_AGREEMENT_TITLE)}</h1>

          <div id='bipa-record-consent' className='line'>
            <p id='bipa-record-consent-text'>
              <span id='PRIVACY_FIRST_AGREEMENT'
                dangerouslySetInnerHTML={{__html: LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_FIRST_AGREEMENT)}} />
            </p>
          </div>

          {props.region === 'US' &&
            <>
              <div id='bipa-citizen-question-container' className='line'>
                <label htmlFor='bipa-citizen-checkbox'>
                  <span id='BIPA_CITIZEN_QUESTION'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_CITIZEN_QUESTION)}</span>
                </label>
              </div>

              <div id='bipa-citizen-switch' className='line'>
                <YesNoCheckBox tabIndex={0} checked={props.isIllinoisUser} onChange={(checked) => { props.onSetIllinoisUser(checked); }}/>
              </div>
            </>
          }

          <span id='PRIVACY_AGREEMENT_TEXT'
            dangerouslySetInnerHTML={{ __html: `${LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_AGREEMENT_TEXT)}` }}/>

          {props.region === 'US' &&
            <span id='BIPA_FORM_BIPA_AGREEMENT' className='small'
              dangerouslySetInnerHTML={{ __html: `${LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_BIPA_AGREEMENT)}` }}/>
          }

          <div className='blank-footer'/>
        </div>

        <div className={`scroll-gradient${showGradient ? '' : ' hidden'}`}/>
      </div>

      <div id='bipa-footer-bar' className='footer-bar'>
        <div className='footer-content'>
          <p id='bipa-privacy-policy-notice'>

            <span id='PRIVACY_IMPLICIT_AGREEMENT'
              dangerouslySetInnerHTML={{ __html: `${LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_IMPLICIT_AGREEMENT)}&nbsp;` }}/>

            <a target='_blank' rel='noreferrer' href={props.privacyOptions.termsAndConditions.href} onClick={
                props.callbacks.onTermsAndConditionsClick &&
                ((e) => {
                  e.preventDefault();
                  props.callbacks.onTermsAndConditionsClick();
                })
              }>
              <span id='PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS'>{LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS)}</span>
            </a>
          </p>

          <div className='line buttons'>
            <button id='bipa-cancel-button' onClick={props.onCancel}>
              <span id='PRIVACY_BUTTON_CANCEL'>{LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_BUTTON_CANCEL)}</span>
            </button>
            <button id='bipa-submit-button' onClick={props.onSubmit} className='confirm'>
              <span id='PRIVACY_BUTTON_SUBMIT'>{LocalizationHelper.getText(PrivacyLocalizationKey.PRIVACY_BUTTON_SUBMIT)}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  PrivacyAgreement
};
