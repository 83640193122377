import { EventBus } from '@luxottica/vm-eventbus';
import { Events } from '../../constants/Events';
import { NotInitializedError } from '../../errors/errors';
import { createLogger } from '../../remotelog/VmLogger';
import { VmWebCam } from '../mirror/VmWebCam';
import { vmIsInitialized } from '../VmCore';

const logger = createLogger('Resume');

const resume = (): Promise<void> => {
  if (!vmIsInitialized()) {
    return Promise.reject(new NotInitializedError());
  } else {
    return VmWebCam.getInstance().play().then(() => {
      EventBus.publish(Events.RESUME, {});
    }).catch((e) => {
      logger.error(e);
    });
  }
};

export {
  resume
};
