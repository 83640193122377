import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../package.json";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "Version");

class Version {

  public static getInstance() {
    if (!Version.instance) {
      Version.instance = new Version();
    }
    return Version.instance;
  }

  private static instance: Version;

  private constructor() {
    logger.debug("running {} v{}", name, version);
  }

  public getModuleInfo(): [string, string] {
    return [name, version];
  }

}

export { Version };
