import { MicroserviceEnvironment, submitBipaFormRequest } from '@luxottica/vto-microservices';
import React, { useEffect, useRef, useState } from 'react';
import { BipaServiceError } from '../errors/errors';
import { LocalizationHelper } from '../helpers/LocalizationHelper';
import { ParameterHelper } from '../helpers/ParameterHelper';
import { BipaCallbacks } from '../interfaces/BipaCallbacks';
import { BipaFormData } from '../interfaces/BipaFormData';
import { PrivacyLocalizationKey } from '../interfaces/PrivacyLocalization';
import { BipaPrivacyOptions } from '../interfaces/PrivacyOptions';
import './BipaBiometricForm.scss';
import { ErrorIcon } from './ErrorIcon';

interface BipaBiometricFormProps {
  source: string;
  environment: MicroserviceEnvironment;
  accessToken: string;
  refreshToken: string;
  bipaVersion: number;
  formPrefill?: Partial<BipaFormData>;
  privacyOptions: BipaPrivacyOptions;
  callbacks: BipaCallbacks;
  onBipaSubmit: (formData: BipaFormData) => void;
  onCancel: () => void;
  onError: (e: Error) => void;
}

const BipaBiometricForm = (props: BipaBiometricFormProps) => {

  const [firstName, setFirstName] = useState<string>((!!props.formPrefill?.firstName) ? props.formPrefill.firstName : '');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);

  const [lastName, setLastName] = useState<string>((!!props.formPrefill?.lastName) ? props.formPrefill.lastName : '');
  const [lastNameError, setLastNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>((!!props.formPrefill?.email) ? props.formPrefill.email : '');
  const [emailError, setEmailError] = useState<boolean>(false);

  const [serverError, setServerError] = useState<boolean>(false);

  const topInputRef = useRef<HTMLInputElement>(undefined);

  useEffect(() => {
    props.callbacks?.onDisplayPage?.('BIOMETRIC_FORM');
  }, []);

  const onSubmitForm = () => {
    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email
    };

    if (!serverError) { setServerError(false); }

    const fieldErrors = ParameterHelper.validateBipaForm(formData).map(error => error.field);

    if (!firstName) { setFirstNameError(true); }
    if (!lastName) { setLastNameError(true); }
    if (!email) { setEmailError(true); }

    if (!firstName || !lastName || !email) {
      topInputRef.current.scrollIntoView();

    } else if (fieldErrors.length > 0) {
      if (fieldErrors.includes('firstName')) { setFirstNameError(true); }
      if (fieldErrors.includes('lastName')) { setLastNameError(true); }
      if (fieldErrors.includes('email')) { setEmailError(true); }
      topInputRef.current.scrollIntoView();

    } else {
      submitBipaFormRequest({
        source: props.source,
        environment: props.environment,
        accessToken: props.accessToken,
        refreshToken: props.refreshToken,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        bipaVersion: props.bipaVersion,
        onAuthUpdated: props.callbacks.onAuthUpdated
      }).then((response) => {
        if (response.ok) {
          props.onBipaSubmit(formData);
        } else if (response.status < 404) {
          response.json().then((json) => {
            const errorFields: string[] = (!!json?.fieldErrors) ? json.fieldErrors.map(error => error.field) : [];
            console.info(errorFields);
  
            if (errorFields.includes('firstName')) { setFirstNameError(true); }
            if (errorFields.includes('lastName')) { setLastNameError(true); }
            if (errorFields.includes('email')) { setEmailError(true); }
          });
        } else {
          setServerError(true);
          props.onError(new BipaServiceError(`failed to submit bipa form due to response=${response.status} ${response.statusText}`));
        }
      }).catch((e) => {
        setServerError(true);
        props.onError(e);
      });
    }
  };

  const handleInputChange = (
    element: React.ChangeEvent<HTMLInputElement>,
    dispatch: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (!!element.target.value && element.target.value !== '') {
      dispatch(element.target.value);
    } else {
      dispatch('');
    }
  };

  const confirmButtonDisabled = firstName === undefined || firstName === ''
    || lastName === undefined || lastName === ''
    || email === undefined || email === '';
  const showFieldWarning = firstNameError || lastNameError || emailError;

  return (
    <div id='bipa-biometric-agreement'>

      <div className='top'>
        <h1 id='BIOMETRIC_AGREEMENT_TITLE' className='line'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_TITLE)}</h1>

        <p id='BIPA_FORM_SUBTITLE' className='line'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_SUBTITLE)}</p>

        { showFieldWarning &&
          <p id='bipa-field-warning' className='note-warning'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_ERROR)}</p>
        }

        { serverError &&
          <p id='bipa-server-warning' className='note-warning'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_SERVER_ERROR)}</p>
        }

        <div className='field line'>
          <input ref={topInputRef} name='BIPA_FORM_NAME' id='bipa-data-collection-name' className={(firstNameError ? 'error' : '')} autoComplete='given-name'
            value={firstName} onChange={(element) => {
              setFirstNameError(false);
              handleInputChange(element, setFirstName);
            }}
            placeholder={LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_NAME)}/>
            { firstNameError && <ErrorIcon/> }
        </div>
        <div className='field line'>
          <input name='BIPA_FORM_SURNAME' id='bipa-data-collection-surname' className={(lastNameError ? 'error' : '')} autoComplete='family-name'
            value={lastName} onChange={(element) => {
              setLastNameError(false);
              handleInputChange(element, setLastName);
            }}
            placeholder={LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_SURNAME)}/>
            { lastNameError && <ErrorIcon/> }
        </div>
        <div className='field line'>
          <input name='BIPA_FORM_EMAIL' id='bipa-data-collection-email' className={(emailError ? 'error' : '')} autoComplete='email'
            value={email} onChange={(element) => {
              setEmailError(false);
              handleInputChange(element, setEmail);
            }}
            placeholder={LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_EMAIL)}/>
            { emailError && <ErrorIcon/> }
        </div>
        { emailError && <div id='bipa-form-error-mail' className='error-text'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_ERROR_MAIL)}</div> }
        <div id='bipa-mandatory-field' className='note-text'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_MANDATORY_FIELD)}</div>
      </div>

      <div className='bottom'>
        <div className='line buttons'>
          <button id='bipa-cancel-button' onClick={props.onCancel}>
            <span id='BIPA_FORM_BUTTON_CANCEL'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_BUTTON_CANCEL)}</span>
          </button>

          <button id='bipa-submit-button' onClick={onSubmitForm} className='confirm' disabled={confirmButtonDisabled}>
            <span id='BIPA_FORM_BUTTON_SUBMIT'>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_FORM_BUTTON_SUBMIT)}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export {
  BipaBiometricForm
};
