import { name, version } from "../../../../package.json";
import { RemoteLogConfig } from "../../RemoteLogConfig";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "VmAnalyticsApiConfig");

export class VmAnalyticsApiConfig {

  private hostTest = "https://vmcore-test.luxottica.com";
  private hostProd = "https://vmcore.luxottica.com";
  private appViewSessionApi = "/api/appviewsession";
  private browserSessionApi = "/api/browsersession";
  private trackingStatusApi = "/api/trackingstatus";
  private remoteLogApi = "/api/remotelog";
  private currentHostname = window.location.hostname;

  public get appViewSessionApiEndpoint() {
    return this.getHostname() + this.appViewSessionApi;
  }

  public get browserSessionApiEndpoint() {
    return this.getHostname() + this.browserSessionApi;
  }

  public get trackingStatusApiEndpoint() {
    return this.getHostname() + this.trackingStatusApi;
  }

  public get remoteLogApiEndpoint() {
    return this.getHostname() + this.remoteLogApi;
  }

  private getHostname() {
    const host = this.isDevEnvironment() ? this.hostTest : this.hostProd;
    logger.debug("analytics api hostname: {}", host);
    return host;
  }

  private isDevEnvironment() {
    const isDev = this.currentHostname === "localhost";
    logger.debug("is dev environment: {}", isDev);
    return isDev;
  }
}
