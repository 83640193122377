abstract class WebCamAbstractError extends Error {
  public type: string;

  constructor(type: string, message: string, error?: Error) {
    super(message);
    this.type = type;
    this.name = type;
    this.message = message;
    this.stack = error?.stack;

    Object.setPrototypeOf(this, WebCamAbstractError.prototype);
  }
}

export { WebCamAbstractError };
