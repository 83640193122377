import uuid from 'uuid';
import { INativeStreamTrack, NativeTrackSettings } from './INativeStreamTrack';

class NativeStreamTrack implements INativeStreamTrack {
  public readonly id: string;
  public readonly label: string;
  private settings: NativeTrackSettings;

  constructor(resolution: {width: number, height: number}) {
    this.id = uuid();
    this.label = 'native-stream-track';
    this.settings = {
      height: resolution.height,
      width: resolution.width,
    };
  }
  public getSettings() {
    return this.settings;
  }
  public stop() {
    window.NativeBridge.close();
  }
}

export { NativeStreamTrack };
