import { GlassesEnvironment } from '../constants/Environment';

interface EnvironmentConfig {
  name: string;
  catalogHost: string;
  transitionHost: string;
  b3dHost: string;
  gltfHost: string;
  gltfPointer: string;
  customDistributorHost: string;
  customDistributorGetPermutation: string;
  customDistributorKey: string;
  assetType: 'ZIPPED' | 'SEPARATE';
}

const hosts: {
  [key in GlassesEnvironment]: EnvironmentConfig;
} = {
  MOCK: {
    name: 'PROD',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://vmcore.luxottica.com',
    gltfHost: 'https://mobile-catalogue-3d-model-test.s3-accelerate.amazonaws.com/public/vto-poc',
    gltfPointer: '/pointers/gltf',
    customDistributorHost: 'https://distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'HnqGNWeO.Px2v1cUrm3ffk6gC0OOdTgJRoaOfxClC',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'SEPARATE'
  },
  PROD: {
    name: 'PROD',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://vmcore.luxottica.com',
    gltfHost: 'https://vmcore.luxottica.com/api/upc/v1',
    gltfPointer: '/pointers/gltf',
    customDistributorHost: 'https://distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'HnqGNWeO.Px2v1cUrm3ffk6gC0OOdTgJRoaOfxClC',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'SEPARATE'
  },
  PROD_LEGACY: {
    name: 'PROD_LEGACY',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://vto-3d-assets.luxdeepblue.com',
    gltfHost: 'https://vmcore.luxottica.com/api/upc/v1',
    gltfPointer: '/pointers/gltf',
    customDistributorHost: 'https://distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'HnqGNWeO.Px2v1cUrm3ffk6gC0OOdTgJRoaOfxClC',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  TEST: {
    name: 'TEST',
    catalogHost: 'https://vmcore-test.luxottica.com',
    transitionHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://vmcore-test.luxottica.com',
    gltfHost: 'https://vmcore-test.luxottica.com/api/upc/v1',
    gltfPointer: '/pointers/gltf',
    customDistributorHost: 'https://staging-distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'bMhq6JPO.nLtuIsJU61lazsX3pnj10iH0wv2Dr7KA',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  TEST_LEGACY: {
    name: 'TEST_LEGACY',
    catalogHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    transitionHost: 'https://test-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://vto-3d-assets-test.luxdeepblue.com',
    gltfHost: 'https://vmcore-test.luxottica.com/api/upc/v1',
    gltfPointer: '/pointers/gltf',
    customDistributorHost: 'https://staging-distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'bMhq6JPO.nLtuIsJU61lazsX3pnj10iH0wv2Dr7KA',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'SEPARATE'
  },
  QA_GLTF_PROD: {
    name: 'QA_PROD',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://s3.amazonaws.com/vto-3d-assets-qa',
    gltfHost: 'https://gw-api-vtocatalogue.luxdeepblue.com/api/upc/v1',
    gltfPointer: '/pointers/gltf?qa=true',
    customDistributorHost: 'https://distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'HnqGNWeO.Px2v1cUrm3ffk6gC0OOdTgJRoaOfxClC',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  QA_GLTF_TEST: {
    name: 'QA_TEST',
    catalogHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://s3.amazonaws.com/vto-3d-assets-qa-test',
    gltfHost: 'https://vmcore-test.luxottica.com/api/upc/v1',
    gltfPointer: '/pointers/gltf?qa=true',
    customDistributorHost: 'https://staging-distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'bMhq6JPO.nLtuIsJU61lazsX3pnj10iH0wv2Dr7KA',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  QA_B3D_PROD: {
    name: 'QA_PROD',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://s3.amazonaws.com/vto-3d-assets-qa',
    gltfHost: 'https://gw-api-vtocatalogue.luxdeepblue.com/api/upc/v1',
    gltfPointer: '/pointers/gltf?qa=true',
    customDistributorHost: 'https://distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'HnqGNWeO.Px2v1cUrm3ffk6gC0OOdTgJRoaOfxClC',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  QA_B3D_TEST: {
    name: 'QA_TEST',
    catalogHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://s3.amazonaws.com/vto-3d-assets-qa-test',
    gltfHost: 'https://test-api-vtocatalogue.luxdeepblue.com/api/upc/v1',
    gltfPointer: '/pointers/gltf?qa=true',
    customDistributorHost: 'https://staging-distributor-distributor.luxdeepblue.com',
    customDistributorKey: 'bMhq6JPO.nLtuIsJU61lazsX3pnj10iH0wv2Dr7KA',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  // environments for distributor developments
  DISTRIBUTOR_VALIDATION_STAGING: {
    name: 'DISTRIBUTOR_VALIDATION_STAGING',
    catalogHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://distributor-assets-staging.s3.amazonaws.com/validation',
    gltfHost: '',
    gltfPointer: '',
    customDistributorHost: '',
    customDistributorKey: '',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  DISTRIBUTOR_APPROVATION_STAGING: {
    name: 'DISTRIBUTOR_APPROVATION_STAGING',
    catalogHost: 'https://test-api-vtocatalogue.luxdeepblue.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://distributor-assets-staging.s3.amazonaws.com/approvation',
    gltfHost: '',
    gltfPointer: '',
    customDistributorHost: '',
    customDistributorKey: '',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  DISTRIBUTOR_VALIDATION_PRODUCTION: {
    name: 'DISTRIBUTOR_VALIDATION_PRODUCTION',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://distributor-assets-production.s3.amazonaws.com/validation',
    gltfHost: '',
    gltfPointer: '',
    customDistributorHost: '',
    customDistributorKey: '',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  },
  DISTRIBUTOR_APPROVATION_PRODUCTION: {
    name: 'DISTRIBUTOR_APPROVATION_PRODUCTION',
    catalogHost: 'https://vmcore.luxottica.com',
    transitionHost: 'https://qa-gw-api-vtocatalogue.luxdeepblue.com',
    b3dHost: 'https://distributor-assets-production.s3.amazonaws.com/approvation',
    gltfHost: '',
    gltfPointer: '',
    customDistributorHost: '',
    customDistributorKey: '',
    customDistributorGetPermutation: '/api/getPermutation?token=',
    assetType: 'ZIPPED'
  }
};

const getEnvironmentConfig = (env: GlassesEnvironment | undefined): EnvironmentConfig => {
  return (env) ? hosts[env] : hosts.PROD;
};

export { getEnvironmentConfig, EnvironmentConfig };
