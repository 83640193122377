import { v4 as uuid } from "uuid";
import { DBRemoteLogFactory } from "../../../../adapter/db/factory/DBRemoteLogFactory";
import { LogsConfig } from "../../../../config/LogsConfig";
import { LogMessage } from "../../../model/LogMessage";

class RemoteLogService {

  public log(message: LogMessage): void {
    LogsConfig.getInstance().isServerLogActive().then((isActive) => {
      if (isActive) {
        this.queueMessage(message);
      }
    });
  }

  private queueMessage(logMessage: LogMessage) {
    this.dbInstance().getDB().setItem(uuid(), {
      date: logMessage.getDate(),
      level: logMessage.getLevel(),
      logName: logMessage.getLogName(),
      message: logMessage.getMessage(),
    });
  }

  private dbInstance() {
    return DBRemoteLogFactory.getInstance();
  }

}

export { RemoteLogService };
