import { RenderingOptions, RevealFX } from '../interfaces/MirrorOptions';

const DefaultRenderingOptions: RenderingOptions = {
  revealFX: RevealFX.OFF,
  debug: {
    checkError: false,
    hideGlasses: false,
    showMesh: false,
    meshColor: [0, 1, 0, 0.5],
  }
};

export { DefaultRenderingOptions };
