import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query'
import { selectAccessToken, selectRefreshToken } from '@root/store/user/selectors'

import { RootState } from '@root/store'
import userActions from '@root/store/user/actions'

export const staggeredBaseQueryWithBailOut = (
  baseUrl: string,
  bypassContentType = false,
  bypassBearer = false,
) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const state = api.getState() as RootState
      const accessToken = selectAccessToken(state)

      let result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
          bypassContentType || headers.append('Content-Type', 'application/json')
          !bypassBearer && headers.append('Authorization', `Bearer ${accessToken}`)
          return headers
        },
      })(args, api, extraOptions)

      if (result.error && result.error.status === 401) {
        const state = api.getState() as RootState
        const refresh_token = selectRefreshToken(state)
        const refreshTokenResult = (await fetchBaseQuery({
          baseUrl:
            process.env.REACT_APP_AUTH_URL ||
            'https://test-gateway-vtoprofile.luxdeepblue.com/auth/refresh',
          prepareHeaders: headers => {
            headers.append('Content-Type', 'application/json')
            headers.append('X-XSRF-TOKEN', '')
            return headers
          },
        })(
          {
            url: '/auth/refresh',
            method: 'POST',
            body: {
              refresh_token,
            },
          },
          api,
          extraOptions,
        )) as { data?: { access_token: string; refresh_token: string } }

        if (refreshTokenResult.data) {
          api.dispatch(
            userActions.setUserToken({
              accessToken: refreshTokenResult.data.access_token,
              refreshToken: refreshTokenResult.data.refresh_token,
            }),
          )
        }
      }

      // retry only if the error is on the server
      // 401 errors are handled above
      if (
        result.error &&
        result.error.status !== 401 &&
        result.error.status > 304 &&
        result.error.status < 500
      ) {
        // for testing
        // if (result.error?.status === 500) {
        retry.fail(result.error)
      }

      return result
    },
    {
      maxRetries: 3,
    },
  )
