import { name, version } from "../../../../../package.json";
import { DBBrowserSessionFactory } from "../../../../adapter/db/factory/DBBrowserSessionFactory";
import { BrowserSessionAdapter } from "../../../../adapter/rest/BrowserSessionAdapter";
import { IRemoteBrowserSession } from "../../../model/IRemoteBrowserSession";
import { RemoteLogConfig } from "../../../RemoteLogConfig";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "BrowserSessionConsumer");

const dbInstance = () => {
  return DBBrowserSessionFactory.getInstance();
};

const messageAdapter = () => {
  return new BrowserSessionAdapter();
};

const consumeDbRows = () => {
  const dbase = dbInstance().getDB();

  dbase.iterate((
    value: IRemoteBrowserSession,
    key: string
  ) => {
    // if (value === null) {
    //   console.error("undefined: ", { key });
    // }
    messageAdapter().log({
      date: value.date,
      deviceResolution: value.deviceResolution,
      deviceWebcam: value.deviceWebcam,
      deviceWebgl: value.deviceWebgl,
    }).then(() => {
      logger.debug("consumed stored log message: {}", JSON.stringify(value));
      // console.info("consumed: ", {key, value});
      return dbase.removeItem(key);
    }).catch(() => {
      // console.error("failed: ", { key });
    });
  }).then(() => {
    logger.debug("all logs consumed from local indexdb");
  });
};

class BrowserSessionConsumer {

  public consume() {
    const time1 = window.performance.now();
    consumeDbRows();
    const time2 = window.performance.now();
    logger.debug("logs consumed in {} ms", time2 - time1);
  }

}

export { BrowserSessionConsumer };
