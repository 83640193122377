import { getConfig } from '../config/Config';
import { RefreshResponse, RefreshReturn } from '../interfaces/Refresh';
import { VtoRequest } from '../interfaces/Request';

const refreshRequest = (
  request: VtoRequest
): Promise<RefreshReturn> => {

  const url = getConfig(request.environment, 'refreshUrl');
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: request.refreshToken
    })

  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().catch(() => {
        throw new Error(`${response.status}: ${response.statusText}`);
      }).then((json) => {
        throw new Error(JSON.stringify(json));
      });
    }

  }).then((json: RefreshResponse) => {
    // logger.debug(json);
    const refreshReturn = {
      accessToken: json.access_token,
      refreshToken: json.refresh_token
    };
    if (!!request.onAuthUpdated) { request.onAuthUpdated(refreshReturn); }
    return refreshReturn;

  }).catch(err => {
    // logger.error(err);
    throw(err);

  });
};

export {
  refreshRequest
};
