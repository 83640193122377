import { v4 as uuid } from "uuid";
import { DBAppViewSessionFactory } from "../../../../adapter/db/factory/DBAppViewSessionFactory";
import { LogsConfig } from "../../../../config/LogsConfig";
import { IRemoteAppViewSession } from "../../../model/IRemoteAppViewSession";

class AppViewSessionService {

  public log(message: IRemoteAppViewSession): void {
    const timestamp = this.now();
    LogsConfig.getInstance().isAppViewSessionActive().then((isActive) => {
      if (isActive) {
        this.queueMessage(message, timestamp);
      }
    });
  }

  private queueMessage(message: IRemoteAppViewSession, timestamp: string) {
    this.dbInstance().getDB().setItem(uuid(), {
      date: timestamp,
      statusCode: message.statusCode,
      statusText: message.statusText,
    });
  }

  private dbInstance() {
    return DBAppViewSessionFactory.getInstance();
  }

  private now() {
    return new Date().toISOString();
  }

}

export { AppViewSessionService };
