import { getConfig } from '../config/Config';
import { InvalidInputError, RequestError } from '../error/errors';
import { pollProcessingStatus, pollPromise } from '../helpers/RequestHelper';
import { ProcessImageRequestData, UploadImageDataRequestData, UploadImageRequestData, UploadImageResponse, UploadImageReturn } from '../interfaces/Image';
import { ProfileReturn } from '../interfaces/Profile';
import { profileRequest } from './ProfileRequest';
import { refreshRequest } from './RefreshRequest';

const uploadImageFetch = (
  request: UploadImageRequestData
): Promise<Response> => {

  const form = new FormData();
  form.append('file', new File([request.img], 'take0.jpg', { type: 'image/jpeg' }));

  return fetch(`${request.uploadPath}/take0.jpg`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      Authorization: `Bearer ${request.accessToken}`
    },
    body: form
  });
};

const processImageFetch = (
  request: ProcessImageRequestData
): Promise<Array<Response | undefined>> => {
  const mlUrl = getConfig(request.environment, 'enrichUrl');
  const vtoUrl = getConfig(request.environment, 'vtoUrl');

  return Promise.all([
    (request.enableMachineLearning) ? fetch(mlUrl, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${request.accessToken}`
      },
      'body': `{ "uuid": "${request.videoId}" }`
    }): undefined,
    (request.enableVto) ? fetch(vtoUrl, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${request.accessToken}`
      },
      'body': `{ "uuid": "${request.videoId}" }`
    }) : undefined
  ]);
};

const uploadImageRequest = (
  request: UploadImageRequestData
): Promise<void> => {

  return uploadImageFetch(request).then((uploadImageResponse) => {
    if (uploadImageResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return uploadImageFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return uploadImageResponse;
    }
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json().catch(() => {
        throw new Error(`${response.status}: ${response.statusText}`);
      }).then((json) => {
        throw new Error(JSON.stringify(json));
      });
    }
  });
};

const uploadImageDataRequest = (
  request: UploadImageDataRequestData
): Promise<void> => {

  return new Promise<Blob>((resolve, reject) => {
    const w = request.imgData.width;
    const h = request.imgData.height;
  
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
  
    const ctx = canvas.getContext('2d');
    if (!!ctx) {
      ctx.putImageData(request.imgData, 0, 0);
      canvas.toBlob((blob) => {
        if (!!blob) {
          resolve(blob);
        } else {
          reject(new RequestError('failed to extract image from canvas'));
        }
      }, 'image/jpeg', 0.95);
      
    } else {
      reject(new RequestError('failed to extract image from canvas'));
    }
  }).then((imgBlob) => {
    return uploadImageRequest({
      ...request,
      img: imgBlob
    });
  });
};

const processImageRequest = (
  request: ProcessImageRequestData
): Promise<ProfileReturn> => {

  if (!request.enableMachineLearning && !request.enableVto) {
    return Promise.reject(new InvalidInputError('enableMachineLearning and enableVto cannot both be set to false'));
  }

  return processImageFetch(request).then((processImageResponse) => {
    if ((!!processImageResponse[0] && processImageResponse[0].ok && processImageResponse[0].status === 401)
    || (!!processImageResponse[1] && processImageResponse[1].ok && processImageResponse[1].status === 401)) {
      return refreshRequest(request).then((refreshResponse) => {
        return processImageFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return processImageResponse;
    }

  }).then((response) => {
    if ((response[0] === undefined || response[0].ok)
    && (response[1] === undefined || response[1].ok)) {

      const promises: Promise<boolean>[] = [];
      const entries: string[] = [];

      if (request.enableVto) {
        entries.push('BASEL_MESH');
      }

      if (request.enableMachineLearning) { 
        entries.push('ML_INFORMATION');

        const pdStatusUrl = `${getConfig(request.environment, 'pdStatusUrl')}/${request.videoId}` ;
        promises.push(pollPromise(
          1000,
          30000,
          () => pollProcessingStatus(request, pdStatusUrl, ['PD']),
          request.shouldCancel
        ));
      }

      const statusUrl = `${getConfig(request.environment, 'statusUrl')}/${request.videoId}` ;
      promises.push(pollPromise(
        1000,
        30000,
        () => pollProcessingStatus(request, statusUrl, entries),
        request.shouldCancel
      ));

      return Promise.all(promises).then(results => results.every(result => !!result));

    } else {
      throw new RequestError(`failed to poll status with sessionId=${request.videoId}`);
    }

  }).then((success) => {
    if (success) {
      return profileRequest(request);
    } else {
      throw new RequestError(`failed to poll status with sessionId=${request.videoId}`);
    }
  });
};

export {
  uploadImageRequest,
  uploadImageDataRequest,
  processImageRequest
};