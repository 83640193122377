import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'
import { logger } from './logger'

const extractErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  if ('message' in error) {
    return error.message
  }

  if (
    'status' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data &&
    typeof (error.data as Error).message === 'string'
  ) {
    return (error.data as Error).message as string
  }
}

const extractErrorName = (error: FetchBaseQueryError | SerializedError) => {
  if ('name' in error) {
    return error.name
  }

  if (
    'status' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error.data as any).error === 'string'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error.data as any).error as string
  }
}

export const extractErrorStatus = (error: FetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    return error.status
  }
}

const extractErrorPath = (error: FetchBaseQueryError | SerializedError) => {
  if ('stack' in error) {
    return error.stack
  }

  if (
    'status' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error.data as any).path === 'string'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error.data as any).path as string
  }
}

export const showError = ({
  error,
  origin,
}: {
  error: FetchBaseQueryError | SerializedError
  origin: string
}) => {
  const errorCode = 'status' in error ? error.status.toString() : error.code
  const isClientError = errorCode === '400'

  const message = extractErrorMessage(error)
  const errorName = extractErrorName(error)
  const errorPath = extractErrorPath(error)

  const mainMessage = isClientError
    ? `Frame Advisor committed an error calling the externa service ${origin}`
    : `The external service ${origin} responded with an error`

  //  eslint-disable-next-line
  console.error(mainMessage) // XXX: log to the console to simplify the integrator debug

  logger.error(errorName, 'API')
  logger.error(message, 'API')
  logger.error(errorPath, 'API')
}
