export class LogMessage {

  public level: string;
  public logName: string;
  public message: string;
  public date: string = new Date().toISOString();

  constructor(level: string, logName: string, message: string) {
    this.level = level;
    this.message = message;
    this.logName = logName;
  }

  public getLevel() {
    return this.level;
  }

  public getLogName() {
    return this.logName;
  }

  public getMessage() {
    return this.message;
  }

  public getDate() {
    return this.date;
  }

}
