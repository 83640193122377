import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../package.json';
import { AssetDecoder } from './AssetDecoder';
import { EnvironmentConfig, getEnvironmentConfig } from './config/GlassesApiConfig';
import { assetNames } from './constants/AssetNames';
import { GlassesEnvironment } from './constants/Environment';
import { gltfDefaults } from './constants/GltfDefault';
import { MockAssetData } from './constants/MockAssetData';
import { GlassesDownloadError } from './error/GlassesDownloadError';
import { GlassesDownloadForbiddenError } from './error/GlassesDownloadForbiddenError';
import { GlassesNotFoundError } from './error/GlassesNotFoundError';
import { isDistributorAsset } from './helpers/AssetHelper';
import { AssetResolution } from './interfaces/AssetResolution';
import { B3dAsset } from './interfaces/B3dAsset';
import { GltfAssetPointer, GltfLodUrls, GltfResponse, MiscUrl } from './interfaces/GltfAsset';
import { AppViewSessionKeys } from './remotelog/AppViewSessionKeys';
import { ZipDownloader } from './ZipDownloader';
// import { GlassesAsset } from "./GlassesAsset";
// import { GlassesLandmarks } from "./GlassesLandmarks";
// import { GLTFJson } from "./GLTFJson";
// import { isCustomDistributorAsset } from "./helpers/AssetHelper";
// import { LensBoundingBox } from "./LensBoundingBox";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'GlassesDownloader');

// temporary mock function for GLTF misc/DC assets
const miscUrls = (envConfig: EnvironmentConfig, upc: string): MiscUrl => {
  return {
    boundsUrl: `${envConfig.gltfHost}/${upc}/webGL/${upc}_misc/bounds.glb`,
    landmarksUrl: `${envConfig.gltfHost}/${upc}/webGL/${upc}_misc/landmarks.glb`,
  };
};

class GlassesDownloader {

  private remoteLog: RemoteLog = new RemoteLog();

  public downloadGltfGlasses(options: {
    upc: string,
    glassesEnvironment: GlassesEnvironment,
    landmarksAndBBox?: boolean
  }): Promise<GltfAssetPointer> {
    
    if (options.glassesEnvironment === GlassesEnvironment.MOCK) {
      return Promise.resolve(this.getMockGltfGlassesUrl(options));
    } else {
      const envConfig = getEnvironmentConfig(options.glassesEnvironment);
      const gltfUrl = `${envConfig.gltfHost}/${options.upc}${envConfig.gltfPointer}`;

      return fetch(gltfUrl).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(new GlassesDownloadError(`${options.upc} - ${response.status}`));
        }
      }).then((json: GltfResponse) => {
        const assetData = json[options.upc];

        // at the moment this is the only way we can understand if the catalog gave us an empty response
        // TODO: ask backend team to improve the answer
        if (!assetData.LOD1 && !assetData.LOD2) {
          return Promise.reject(new GlassesDownloadError(`${options.upc} - missing fundamental LOD`));
        }
        return {
          upc: options.upc,
          lod: 0,
          gltfLodUrls: {
            lod1: assetData.LOD1.gltfUrl,
            ...(!!assetData.LOD2 && { lod2: assetData.LOD2.gltfUrl })
          },
          nosepadType: assetData.nosepadType,
          fitting: assetData.fitting,
          flare: assetData.flare,
          ...(!!assetData.misc && !!options.landmarksAndBBox && { misc: assetData.misc })
        };
      });
    }
  }

  private getMockGltfGlassesUrl(options: {
    upc: string,
    glassesEnvironment: GlassesEnvironment,
    landmarksAndBBox?: boolean
  }): GltfAssetPointer {
    const envConfig = getEnvironmentConfig(options.glassesEnvironment);

    const assetNumLods = MockAssetData[options.upc].lodNumber;
    const gltfLodUrls: GltfLodUrls = {
      lod1: ''
    };
    
    if (assetNumLods === 1) {
      // asset without lods (third-party)
      gltfLodUrls.lod1 = `${envConfig.gltfHost}/${options.upc}/webGL/${options.upc}_LOD0/${options.upc}.gltf`;
    } else {
      gltfLodUrls.lod1 = `${envConfig.gltfHost}/${options.upc}/webGL/${options.upc}_LOD1/${options.upc}.gltf`;
      if (assetNumLods >= 2) {
        gltfLodUrls.lod2 = `${envConfig.gltfHost}/${options.upc}/webGL/${options.upc}_LOD2/${options.upc}.gltf`;
      }
    }

    return {
      upc: options.upc,
      gltfLodUrls: gltfLodUrls,
      lod: assetNumLods,  // probably not needed, remove?
      nosepadType: (!!MockAssetData[options.upc]?.nosepad_type) ? MockAssetData[options.upc].nosepad_type : gltfDefaults.nosepad_type,
      fitting: (!!MockAssetData[options.upc]?.fitting) ? MockAssetData[options.upc].fitting : gltfDefaults.fitting,
      flare: (!!MockAssetData[options.upc]?.flare) ? MockAssetData[options.upc].flare : gltfDefaults.flare,
      misc: !!MockAssetData[options.upc]?.misc &&
        options.landmarksAndBBox !== undefined &&
        options.landmarksAndBBox ?
        miscUrls(envConfig, options.upc) : undefined,
    };
  }

  public downloadGlasses(options: {
    upc: string,
    resolution: AssetResolution,
    glassesEnvironment: GlassesEnvironment,
    lensMap?: boolean,
    lensBoundingBoxes?: boolean,
    glassesLandmarks?: boolean,
    singleTexture?: number,
  }): Promise<B3dAsset> {
    const envConfig = getEnvironmentConfig(options.glassesEnvironment);

    if (this.shouldDownloadZip(options.upc, envConfig)) {
      return this.downloadZippedGlasses(
        options.upc,
        options.resolution,
        options.singleTexture,
        (options.lensMap) ? true : false,
        (options.lensBoundingBoxes) ? true : false,
        (options.glassesLandmarks) ? true : false,
        envConfig
      );
    } else {
      return this.downloadSeparatedGlasses(
        options.upc,
        options.resolution,
        options.singleTexture,
        (options.lensMap) ? true : false,
        (options.lensBoundingBoxes) ? true : false,
        (options.glassesLandmarks) ? true : false,
        envConfig
      );
    }
  }

  private shouldDownloadZip(upc: string, envConfig: EnvironmentConfig) {
    return isDistributorAsset(upc) || envConfig.assetType === 'ZIPPED';
  }

  private downloadZippedGlasses(
    upc: string,
    resolution: AssetResolution = '512',
    singleTexture: number | undefined,
    lensMap: boolean,
    lensBoundingBoxes: boolean,
    glassesLandmarks: boolean,
    envConfig: EnvironmentConfig
  ): Promise<B3dAsset> {

    // prepare lensmap url
    const lensMapUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.lensMap}`;
    const lensBoundsUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.lensBounds}`;
    const glassesLandmarksUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.glassesLandmarks}`;
    const qaToolEnv = ['QA_B3D_PROD', 'QA_GLTF_PROD', 'QA_B3D_TEST', 'QA_GLTF_TEST', 'QA_PROD', 'QA_TEST'];

    const qaTool = qaToolEnv.includes(envConfig.name);

    // download the zip and lensmap if present
    return Promise.all([
      ZipDownloader.getInstance().downloadZippedGlasses(upc, resolution, singleTexture, envConfig),
      (lensMap && !qaTool) ? this.downloadB3dAsset(lensMapUrl) : Promise.resolve(new Blob()),
      (lensBoundingBoxes && !qaTool) ? this.downloadB3dAsset(lensBoundsUrl) : Promise.resolve(new Blob()),
      (glassesLandmarks && !qaTool) ? this.downloadB3dAsset(glassesLandmarksUrl) : Promise.resolve(new Blob()),
    ]).then((responses) => {
      // load the glasses image
      return Promise.all([
        Promise.resolve(responses[0]),
        (responses[1].size > 0) ? AssetDecoder.getImage(responses[1]) : undefined,
        (responses[2].size > 0) ? AssetDecoder.getGLTFAsset(upc, responses[2]) : undefined,
        (responses[3].size > 0) ? AssetDecoder.getGLTFAsset(upc, responses[3]) : undefined,
      ]);
    }).then((glassesAndLensMap) => {
      // TODO is necessary to track download success?

      // compose the asset with lensmaps
      const glassesAsset = glassesAndLensMap[0];
      //if landmarks and bboxes option are false we set relative field undefined
      glassesAsset.lensBoundingBox = lensBoundingBoxes ? glassesAsset.lensBoundingBox : undefined;
      glassesAsset.glassesLandmarks = glassesLandmarks ? glassesAsset.glassesLandmarks : undefined;
      // NOTE: QA tool still uses zipped glasses, if we are outside QA tool new assets must be unzipped
      if (!qaTool) {
        glassesAsset.lensMap = glassesAndLensMap[1];
        glassesAsset.lensBoundingBox = glassesAndLensMap[2];
        glassesAsset.glassesLandmarks = glassesAndLensMap[3];
      }
      return Promise.resolve(glassesAsset);
    });
  }

  private downloadSeparatedGlasses(
    upc: string,
    resolution: AssetResolution,
    singleTexture: number | undefined,
    lensMap: boolean,
    lensBoundingBoxes: boolean,
    glassesLandmarks: boolean,
    envConfig: EnvironmentConfig
  ): Promise<B3dAsset> {

    // const normalizedUpc = normalizeSize(normalizeRemix(upc));

    const b3dUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.b3d}`;
    const transparencyUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.transparency}`;
    const shadowUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.shadow}`;
    const lensMapUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.lensMap}`;
    const lensBoundsUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.lensBounds}`;
    const glassesLandmarksUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.glassesLandmarks}`;

    const textureIndexes = (singleTexture !== undefined) ?
      [singleTexture] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    const textureUrls = textureIndexes.map((x) => {
      return `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.color(x)}`;
    });

    const time1 = performance.now();

    return Promise.all([
      this.downloadB3dAsset(b3dUrl),
      this.downloadB3dAsset(transparencyUrl),
      this.downloadB3dAsset(shadowUrl),
      (lensMap) ? this.downloadB3dAsset(lensMapUrl) : Promise.resolve(new Blob()),
      (lensBoundingBoxes) ? this.downloadB3dAsset(lensBoundsUrl) : Promise.resolve(new Blob()),
      (glassesLandmarks) ? this.downloadB3dAsset(glassesLandmarksUrl) : Promise.resolve(new Blob()),
      ...textureUrls.map((url) => this.downloadB3dAsset(url))
    ]).then((responses) => {
      return Promise.all([
        AssetDecoder.getArrayBuffer(responses[0]),
        AssetDecoder.getImage(responses[1]),
        AssetDecoder.getImage(responses[2]),
        (responses[3].size > 0) ? AssetDecoder.getImage(responses[3]) : undefined,
        (responses[4].size > 0) ? AssetDecoder.getGLTFAsset(upc, responses[4]) : undefined,
        (responses[5].size > 0) ? AssetDecoder.getGLTFAsset(upc, responses[5]) : undefined,
        Promise.all(responses.slice(6, responses.length).map((textureResponse) => {
          return AssetDecoder.getImage(textureResponse);
        }))
      ]);
    }).then((assetsResponses) => {
      this.traceDownloadSuccess(upc, performance.now() - time1);
      return {
        upc: upc,
        objModel: assetsResponses[0],
        transparency: assetsResponses[1],
        shadow: assetsResponses[2],
        lensMap: assetsResponses[3],
        lensBoundingBox: assetsResponses[4],
        glassesLandmarks: assetsResponses[5],
        textures: assetsResponses[6],
        resolution: resolution
      };
    }).catch((e) => {
      this.traceDownloadFailure(upc, performance.now() - time1);
      logger.error(e);
      return Promise.reject(e);
    });
  }

  // TODO, is this used anymore?
  private glassesArchiveUrls(
    upc: string,
    resolution: AssetResolution,
    singleTexture: number | undefined,
    lensMap: boolean,
    envConfig: EnvironmentConfig
  ): string[] {
    // const normalizedUpc = normalizeSize(normalizeRemix(upc));

    const b3dUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.b3d}`;
    const transparencyUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.transparency}`;
    const shadowUrl = `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.shadow}`;
    const lensMapUrl = (lensMap) ? `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.lensMap}` : '';

    const textureIndexes = (singleTexture !== undefined) ?
      [singleTexture] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    const textureUrls = textureIndexes.map((x) => {
      return `${envConfig.b3dHost}/${resolution}/${upc}/${assetNames.color(x)}`;
    });

    return [
      b3dUrl,
      transparencyUrl,
      shadowUrl,
      lensMapUrl,
      ...textureUrls
    ];
  }

  private downloadB3dAsset(glassesAssetUrl: string): Promise<Blob> {
    return fetch(glassesAssetUrl)
    .then((response: Response) => {
      if (response.ok) {
        return response.blob();
      } else {
        logger.error('b3d asset {} failed: [{} {}]',
          response.url, response.status, response.statusText);
        switch (response.status) {
          case 403:
            return Promise.reject(new GlassesDownloadForbiddenError(glassesAssetUrl));
          case 404:
            return Promise.reject(new GlassesNotFoundError(glassesAssetUrl));
          default:
            return Promise.reject(new GlassesDownloadError(glassesAssetUrl));
        }
      }
    }).catch((e) => {
      logger.error(e);
      return Promise.reject(new GlassesDownloadError(glassesAssetUrl));
    });
  }

  // private downloadGltfAsset(glassesAssetUrl: string): Promise<ArrayBuffer> {
  //   return fetch(glassesAssetUrl)
  //     .then((response: Response) => {
  //       if (response.ok) {
  //         return response.arrayBuffer();
  //       } else {
  //         logger.error('gltf asset {} failed: [{} {}]',
  //           response.url, response.status, response.statusText);
  //         switch (response.status) {
  //           case 403:
  //             return Promise.reject(new GlassesDownloadForbiddenError(glassesAssetUrl));
  //           case 404:
  //             return Promise.reject(new GlassesNotFoundError(glassesAssetUrl));
  //           default:
  //             return Promise.reject(new GlassesDownloadError(glassesAssetUrl));
  //         }
  //       }
  //     }).catch((e) => {
  //       logger.error(e);
  //       return Promise.reject(new GlassesDownloadError(glassesAssetUrl));
  //     });
  // }

  private traceDownloadSuccess(upc: string, ttime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.GLASSES_OK + AppViewSessionKeys.GLASSES_DOWNLOAD_SUCCESS_UPC,
        statusText: upc,
      }, {
        statusCode: AppViewSessionKeys.GLASSES_OK + AppViewSessionKeys.GLASSES_DOWNLOAD_SUCCESS_TIME,
        statusText: ttime.toString(),
      },
    );
  }

  private traceDownloadFailure(upc: string, ttime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.GLASSES_KO + AppViewSessionKeys.GLASSES_DOWNLOAD_FAILURE_UPC,
        statusText: upc,
      }, {
        statusCode: AppViewSessionKeys.GLASSES_KO + AppViewSessionKeys.GLASSES_DOWNLOAD_FAILURE_TIME,
        statusText: ttime.toString(),
      },
    );
  }
}

export {
  GlassesDownloader
};
