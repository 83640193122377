import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../package.json';
import { UserMedia } from './domain/adapter/UserMedia';
import { WebCamStream } from './domain/adapter/WebCamStream';
import { CameraFacingMode } from './domain/CameraFacingMode';
import { MockStream } from './domain/mockWebcam/MockStream';
import { NativeStream } from './domain/nativeWebcam/NativeStream';
import { NativeStreamTrack } from './domain/nativeWebcam/NativeStreamTrack';
import { WebCamConstraints } from './domain/WebCamConstraints';
import { WebCamAbstractError } from './error/WebCamAbstractError';
import { WebCamAccessNotFoundError } from './error/WebCamAccessNotFoundError';
import { WebCamAccessRejectedError } from './error/WebCamAccessRejectedError';
import { WebCamAccessSystemError } from './error/WebCamAccessSystemError';
import { AppViewSessionKeys } from './remotelog/AppViewSessionKeys';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'WebCam');

class WebCam {

  private remoteLog = new RemoteLog();

  private userMediaPromise: Promise<MediaStream | NativeStream | MockStream> | undefined;
  private userReqPermissionTime = 0;
  private userResPermissionTime = 0;

  public tryToOpenWebCam(
    w: number,
    h: number,
    cameraFacingMode: CameraFacingMode,
  ): Promise<MediaStream | NativeStream | MockStream> {
    return new Promise((resolve, reject) => {
      WebCamConstraints.getConstraints(w, h, cameraFacingMode).then((constraints) => {
        this.tryToOpen(constraints)
          .then((mediaStream) => resolve(mediaStream))
          .catch((reason) => {
            switch (reason.name) {
              case 'AbortError':
              case 'NotReadableError':
                reject(new WebCamAccessSystemError());
              case 'NotFoundError':
              case 'TypeError':
              case 'OverconstrainedError':
                reject(new WebCamAccessNotFoundError());
              case 'NotAllowedError':
              case 'SecurityError':
                reject(new WebCamAccessRejectedError());
              default:
                reject(new WebCamAccessSystemError());
            }
          });
      });
    });
  }

  public closeCurrentMediaStream(): void {
    if (this.userMediaPromise) {
      this.userMediaPromise.then((mediaStream) => {
        try {
          mediaStream.getTracks().forEach((track: MediaStreamTrack | NativeStreamTrack) => {
            const totalWebCamTime = this.streamOpenedTotalTime();
            logger.info('closing webcam mediastream {} track {} [after {} secs]',
              mediaStream.id, track.label, totalWebCamTime);
            track.stop();
            this.logCloseSucc(mediaStream.id, totalWebCamTime);
          });
        } catch (error) {
          logger.warn('error closing mediastream {} tracks', mediaStream.id);
        }
      }).catch((reason) => {
        logger.warn('error closing mediastream: {}', reason);
      });
    }
  }

  public listMediaDevices() {
    const devices = navigator.mediaDevices.enumerateDevices();
    devices.then((devicesInfo) => {
      devicesInfo.forEach((deviceInfo) => {
        logger.info('found {}: {}', deviceInfo.deviceId, deviceInfo.label);
      });
    });
  }

  public createStreamObject(
    options: { target: string, id?: string, className?: string },
    stream: MediaStream | NativeStream,
  ): WebCamStream {
    return new WebCamStream(options, stream);
  }

  private tryToOpen(
    constraints: MediaStreamConstraints,
  ): Promise<MediaStream | NativeStream | MockStream> {

    logger.info('try to open webcam fow with constraints {}', JSON.stringify(constraints));
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_OPENING,
        statusText: JSON.stringify(constraints),
      },
    );

    this.userReqPermissionTime = performance.now();
    this.userMediaPromise = new UserMedia().get().getUserMedia(constraints);

    this.userMediaPromise.then((mediaStream) => {
      this.userResPermissionTime = performance.now();

      const permissionWaitingTime = this.permissionWaitingTime();
      logger.info('webcam opened in {} secs [mediastream id: {}]', permissionWaitingTime, mediaStream.id);

      this.logOpenSucc(mediaStream.id, permissionWaitingTime);

      mediaStream.getVideoTracks().map((track) => {
        this.logTrackSettings(track);
      });

    }).catch((reason: any) => { // TODO: MediaStreamError has been depricated
      logger.warn('webcam not opened after {} secs ([{}] {})',
        this.permissionWaitingTime(), reason.name, reason.message);
      if (reason.constraintName) {
        logger.warn('failed constraint: {}' + reason.constraintName);
      }
      this.logOpenFail(reason);
    });

    return this.userMediaPromise;
  }

  private logCloseSucc(mediaStreamId: string, totalWebCamTime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_CLOSED,
        statusText: mediaStreamId,
      },
      {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_POWERED_TIME,
        statusText: totalWebCamTime ? totalWebCamTime.toFixed(2) : 'n.a.',
      },
    );
  }

  private logOpenSucc(mediaStreamId: string, permissionWaitingTime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.WEBCAM_OK,
        statusText: mediaStreamId,
      },
    );
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_OPENING_TIME,
        statusText: permissionWaitingTime ? permissionWaitingTime.toFixed(2) : 'n.a.',
      },
    );
  }

  private logOpenFail(reason: any) { // TODO: MediaStreamError has been depricated
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.WEBCAM_KO,
        statusText: reason.name,
      },
      {
        statusCode: AppViewSessionKeys.WEBCAM_KO_REASON_MESS,
        statusText: reason.name + ': ' + reason.message,
      },
    );
  }

  private logTrackSettings(track: MediaStreamTrack | NativeStreamTrack) {
    if (track && track.getSettings()) {
      const deviceW = track.getSettings().width;
      const deviceH = track.getSettings().height;
      const wcamfps = track.getSettings().frameRate;
      logger.info('webcam mediastream track available \'{}\' [id {} - w {} - h {} - framerate {}]',
        track.label, track.id, deviceW, deviceH, wcamfps);
      this.remoteLog.sendAppViewSession(
        {
          statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_SIZE_W,
          statusText: deviceW ? deviceW.toString() : 'n.a.',
        }, {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_SIZE_H,
        statusText: deviceH ? deviceH.toString() : 'n.a.',
      }, {
        statusCode: AppViewSessionKeys.WEBCAM_OK + AppViewSessionKeys.WEBCAM_FRAMERATE,
        statusText: wcamfps ? Math.round(wcamfps).toString() : 'n.a.',
      },
      );
    }
  }

  private streamOpenedTotalTime(): number {
    return this.userResPermissionTime === 0 ? 0 : (performance.now() - this.userResPermissionTime) / 1000;
  }

  private permissionWaitingTime(): number {
    return this.userReqPermissionTime === 0 ? 0 : (performance.now() - this.userReqPermissionTime) / 1000;
  }

}

export {
  WebCam,
  WebCamAbstractError,
  WebCamAccessNotFoundError,
  WebCamAccessRejectedError,
  WebCamAccessSystemError,
  WebCamStream,
  NativeStream,
  CameraFacingMode,
};
