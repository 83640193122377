import { AgreementType, BipaFormData, BipaManager, BipaPage, PrivacyLocalization, PrivacyLocalizationKey } from '@luxottica/bipa-manager';
import { MicroserviceEnvironment } from '@luxottica/vto-microservices';
import { GlassesEnvironment, UpcAvailability } from '@luxottica/vm-glasses';
import { version } from '../package.json';
import { animateOpacity } from './app/functions/AnimateOpacity';
import { closeMirror } from './app/functions/CloseMirror';
import { fitMeBetter } from './app/functions/FitMeBetter';
import { getBipaVersion, GetBipaVersionResponse } from './app/functions/GetBipaVersion';
import { getScreenshot } from './app/functions/GetScreenshot';
import { initialize } from './app/functions/Initialize';
import { pause } from './app/functions/Pause';
import { renderMirror } from './app/functions/RenderMirror';
import { resume } from './app/functions/Resume';
import { saveBipaEntry, SaveBipaEntryParams } from './app/functions/SaveBipaEntry';
import { setTransitionOpacity } from './app/functions/SetTransitionOpacity';
import { setTransitionOverrideColor } from './app/functions/SetTransitionOverrideColor';
import { Catalogue } from './Catalogue';
import { performanceEvents } from './constants/DOMEvents';
import { UpcMissingError, UpcSupportedError, VtoError } from './errors/errors';
import { MirrorTranslation } from './helpers/LocalizationDefaults';
import { LocalizationHelper } from './helpers/LocalizationHelper';
import { DeepPartial } from './interfaces/DeepPartial';
import { InitializationOptions, InitializationParams, InitializationReturns, PrivacyOptions } from './interfaces/InitializationTypes';
import { AnimateOpacityParams, RenderMirrorParams, SetTransitionOpacityParams } from './interfaces/RenderingTypes';
import { Screenshot } from './interfaces/Screenshot';
import { SupportedFeatures } from './interfaces/SupportedFeatures';

const VirtualMirror = {
  version(): string {
    return 'v' + version;
  },

  initialize(params: InitializationParams): Promise<InitializationReturns> {
    return initialize(params);
  },

  overrideLocalization(translation: DeepPartial<MirrorTranslation>): void {
    return LocalizationHelper.setOverrides(translation);
  },

  getMirrorDefaultLocalization(): MirrorTranslation {
    return LocalizationHelper.getDefaults();
  },

  // ***
  // BIPA FUNCTIONS
  // ***

  getBipaVersion(): Promise<GetBipaVersionResponse> {
    return getBipaVersion();
  },

  saveBipaEntry(params: SaveBipaEntryParams): Promise<Response> {
    return saveBipaEntry(params);
  },

  resetBipaState(): void {
    document.cookie = `${BipaManager.getCookieName(AgreementType.BASIC)}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    document.cookie = `${BipaManager.getCookieName(AgreementType.BIOMETRIC)}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  },

  // ***
  // RENDER FUNCTIONS
  // ***

  renderMirror(params: RenderMirrorParams): Promise<void> {
    return renderMirror(params);
  },

  pause(): void {
    return pause();
  },

  resume(): Promise<void> {
    return resume();
  },

  fitMeBetter(): Promise<void> {
    return fitMeBetter();
  },

  getScreenshot(type = 'image/jpeg', quality = 0.9): Promise<Screenshot> {
    return getScreenshot(type, quality);
  },

  setTransitionOpacity(params: SetTransitionOpacityParams): void {
    return setTransitionOpacity(params?.opacity);
  },

  setTransitionOverrideColor(transitionColor: TransitionColor): void {
    return setTransitionOverrideColor(transitionColor);
  },

  animateOpacity(params: AnimateOpacityParams = { duration: 1000 }): void {
    return animateOpacity(params?.duration);
  },

  closeMirror(params: { target: string }): Promise<void> {
    return closeMirror(params?.target);
  }
};

export { VirtualMirror, RenderMirrorParams };
export { VtoError as VmAbstractError, VtoError };
export { Catalogue as VirtualMirrorCatalogue };
export { GlassesEnvironment, MicroserviceEnvironment, BipaPage };
export { UpcAvailability, UpcMissingError, UpcSupportedError };
export { Screenshot };
export { SupportedFeatures };
export { InitializationParams, InitializationOptions, InitializationReturns, PrivacyOptions, BipaFormData, PrivacyLocalization, PrivacyLocalizationKey };
export { performanceEvents };
