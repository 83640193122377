import { BipaPage } from '@luxottica/bipa-manager';
import { DOMDefaultTarget, onCaptureError, onCaptureProcess, onDisplayPrivacyPage, onFACaptureComplete, onFACaptureLoading, onPrivacyDisplay } from '../constants/DOMEvents';
import { AuthenticationOptions, FrameAdvisorCallbacks } from '../interfaces/InitializeParameters';
import { setAuthData } from './AuthHelper';

const onLoadingEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onFACaptureLoading)); 
};

const onAvisorResultEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onFACaptureComplete)); 
};

const onCaptureErrorEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onCaptureError)); 
};

const onDisplayPrivacyPageEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onDisplayPrivacyPage));
};

const onPrivacyDisplayEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onPrivacyDisplay));
};

const onCaptureProcessEvent = () => {
  DOMDefaultTarget().dispatchEvent(new Event(onCaptureProcess));
};

const CallbackHelper: FrameAdvisorCallbacks = {
  // default events, we want to call DOM events in any case
  onDisplayPrivacyPage: () => { onDisplayPrivacyPageEvent(); },
  onPrivacyDisplay: () => { onPrivacyDisplayEvent(); },
  onPrivacyFail: () => { return; },
  onPrivacyReject: () => { return; },
  onVtoCaptureStep: () => { return; },
  onCountdown: () => { return; },
  onNudging: () => { return; },
  onImageConfirm: undefined,
  onLoading: () => { onLoadingEvent(); },
  onAdvisorResult: () => { onAvisorResultEvent(); },
  onCaptureProcess: () => { onCaptureProcessEvent(); },
  onCaptureError: () => { onCaptureErrorEvent(); },
  onAuthUpdated: () => { return; }
};

const setCallbacks = (callbacks: Partial<FrameAdvisorCallbacks>) => {

  CallbackHelper.onAuthUpdated = (authData: AuthenticationOptions) => {
    setAuthData(authData);
    callbacks?.onAuthUpdated?.(authData);
  };
  
  if (callbacks) {
    if (!!callbacks.onPrivacyFail) { CallbackHelper.onPrivacyFail = callbacks.onPrivacyFail; }
    if (!!callbacks.onPrivacyReject) { CallbackHelper.onPrivacyReject = callbacks.onPrivacyReject; }
    
    if (!!callbacks.onVtoCaptureStep) { CallbackHelper.onVtoCaptureStep = callbacks.onVtoCaptureStep; }
    if (!!callbacks.onCountdown) { CallbackHelper.onCountdown = callbacks.onCountdown; }
    if (!!callbacks.onNudging) { CallbackHelper.onNudging = callbacks.onNudging; }

    CallbackHelper.onImageConfirm = (!!callbacks.onImageConfirm) ? callbacks.onImageConfirm : undefined;

    // we want to call DOM events in any case for loading and result
    if (!!callbacks.onLoading) {
      CallbackHelper.onLoading = () => {
        onLoadingEvent();
        callbacks.onLoading();
      };
    }
    if (!!callbacks.onAdvisorResult) {
      CallbackHelper.onAdvisorResult = (result) => {
        onAvisorResultEvent();
        callbacks.onAdvisorResult(result);
      };
    }
    if (!!callbacks.onCaptureError) {
      CallbackHelper.onCaptureError = (result) => {
        onCaptureErrorEvent();
        callbacks.onCaptureError(result);
      };
    }
    if (!!callbacks.onDisplayPrivacyPage) {
      CallbackHelper.onDisplayPrivacyPage = (page: BipaPage) => {
        onDisplayPrivacyPageEvent();
        callbacks.onDisplayPrivacyPage(page);
      };
    }
    if (!!callbacks.onPrivacyDisplay) {
      CallbackHelper.onPrivacyDisplay = () => {
        onPrivacyDisplayEvent();
        callbacks.onPrivacyDisplay();
      };
    }
    if (!!callbacks.onCaptureProcess) {
      CallbackHelper.onCaptureProcess = () => {
        onCaptureProcessEvent();
        callbacks.onCaptureProcess();
      };
    }
  }
};

export {
  CallbackHelper,
  setCallbacks
};
