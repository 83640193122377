import { MockBridgeMedia } from '../mockWebcam/MockBridgeMedia';
import { NativeBridgeMedia } from '../nativeWebcam/NativeBridgeMedia';
import { IUserMedia } from './IUserMedia';
import { WebCamStream } from './WebCamStream';

class UserMedia implements IUserMedia {
  private userMedia: any;
  private readonly mockOptionName =  'vm.mock.video';

  constructor() {

    if (localStorage.getItem(this.mockOptionName) === 'true') { // mock enabled
      this.userMedia = new MockBridgeMedia();
    } else {
      this.userMedia = navigator.mediaDevices || new NativeBridgeMedia();
    }
  }

  public get(): MediaDevices | NativeBridgeMedia | MockBridgeMedia {
    return this.userMedia;
  }
}

export { UserMedia };
