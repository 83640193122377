import { InitializationError, WebcamRejectedError, WebcamUnavailableError } from '../errors/errors';
import { AnalyticsConfig } from '../interfaces/AnalyticsConfig';
import { AdobeConfig } from '../interfaces/InitializationTypes';
import { AdobeHelper } from './AdobeHelper';

let analyticsConfig: AnalyticsConfig;
let lastInteractionTime: number;
let sessionTimeoutMs: number;
const  FIVE_MINUTES = 5 * 60 * 1000;

const setSessionTimeout = (sessionTimeout: boolean) => {
  sessionTimeoutMs = sessionTimeout ? FIVE_MINUTES : Infinity;
};

const updateInteractionTime = () => {
  if (lastInteractionTime) {
    lastInteractionTime = Date.now();
  }
};

const sendEvent = (eventName: string, ...params: any) => {
  try {
    if (eventName in analyticsConfig) {
      if (analyticsConfig[eventName] !== undefined) {
        analyticsConfig[eventName]!(...params);
      }
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error('Analytics Event Failed');
  }
};

const AnalyticsHelper = {
  initialize: (
    environment: MicroserviceEnvironment,
    config: AnalyticsConfig = {},
    adobeConfig: AdobeConfig,
  ) => {
    updateInteractionTime();
    analyticsConfig = config;
    AdobeHelper.initialize(adobeConfig, environment);
  },
  onSessionStart: () => {
    if (!lastInteractionTime || (Date.now() - lastInteractionTime > sessionTimeoutMs)) {
      lastInteractionTime = Date.now();
      AdobeHelper.sendEvent({ eventCode: 'event1' });
      sendEvent('onSessionStart');
    }
  },
  onInitializeSuccess: () => {
    updateInteractionTime();
    sendEvent('onInitializeSuccess');
  },
  onInitializeError: () => {
    updateInteractionTime();
    AdobeHelper.sendError(new InitializationError());
    sendEvent('onInitializeError');
  },
  onRequestWebcam: () => {
    updateInteractionTime();
    AdobeHelper.sendEvent({
      eventCode: 'event2' });
    sendEvent('onRequestWebcam');
  },
  onWebcamAllowed: () => {
    updateInteractionTime();
    AdobeHelper.sendEvent({
      eventCode: 'event3' });
    sendEvent('onWebcamAllowed');
  },
  onWebcamDenied: () => {
    updateInteractionTime();
    AdobeHelper.sendError(new WebcamRejectedError());
    sendEvent('onWebcamDenied');
  },
  onWebcamUnavailable: () => {
    updateInteractionTime();
    AdobeHelper.sendError(new WebcamUnavailableError());
    sendEvent('onWebcamUnavailable');
  },
  onMirrorWasmFailure: () => {
    updateInteractionTime();
    sendEvent('onMirrorWasmFailure');
  },
  onGlassesOn: () => {
    updateInteractionTime() ;
    sendEvent('onGlassesOn');
  },
  onGlassesOff: () => {
    updateInteractionTime();
    sendEvent('onGlassesOff');
  },
  onRender: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    sendEvent('onRender', upcIdLens);
  },
  onRenderReady: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    AdobeHelper.sendEvent({
      eventCode: (!!upcIdLens) ? `event4:${upcIdLens}` : 'event4',
      upc: upcIdLens,
      lensName: lensName
    });
    sendEvent('onRenderReady', upcIdLens);
  },
  onMirrorRenderFailure: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    sendEvent('onMirrorRenderFailure', upcIdLens);
  },
  onFitGlasses: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    AdobeHelper.sendEvent({
      eventCode: (!!upcIdLens) ? `event5:${upcIdLens}` : 'event5',
      upc: upcIdLens,
      lensName: lensName
    });
    sendEvent('onFitGlasses');
  },
  onAdjustGlasses: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    AdobeHelper.sendEvent({
      eventCode: (!!upcIdLens) ? `event6:${upcIdLens}` : 'event6',
      upc: upcIdLens,
      lensName: lensName
    });
    sendEvent('onAdjustGlasses'); // TODO: should this have upc?
  },
  onCloseMirror: () => {
    updateInteractionTime();
    AdobeHelper.sendEvent({ eventCode: 'event7' });
    sendEvent('onCloseMirror');
  },
  onError: () => {
    updateInteractionTime();
    sendEvent('onError');
  },
  onScreenshot: (upc: string, lensName?: string) => {
    updateInteractionTime();
    const upcIdLens = upcLensName(upc, lensName);
    AdobeHelper.sendEvent({
      eventCode: (!!upcIdLens) ? `event9:${upcIdLens}` : 'event9',
      upc: upcIdLens,
      lensName: lensName
    });
    sendEvent('onScreenshot');
  },
};

const upcLensName = (upc: string, lensName?: string) => {
  return (!!lensName) ? `${upc}_${lensName}` : upc;
};

export {
  setSessionTimeout,
  AnalyticsHelper
};
