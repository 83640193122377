import { EnabledList, Interval } from '@root/types/sizeadvisor'

export const defaultList: EnabledList[] = [
  {
    inRange: false,
    bestFit: false,
    size: 0,
  },
  {
    inRange: false,
    bestFit: false,
    size: 1,
  },
  {
    inRange: false,
    bestFit: false,
    size: 2,
  },
  {
    inRange: false,
    bestFit: false,
    size: 3,
  },
]

export const advisorIntervals: Interval[] = [
  {
    sizeName: 'XXL',
    enabledList: [
      {
        inRange: false,
        bestFit: false,
        size: 149,
      },
      {
        inRange: false,
        bestFit: false,
        size: 150,
      },
      {
        inRange: false,
        bestFit: false,
        size: 151,
      },
      {
        inRange: false,
        bestFit: false,
        size: 152,
      },
    ],
    bestFitInterval: false,
    lowerBoundSize: 149,
    upperBoundSize: 152,
    inRangeLowerBoundSize: 0,
    inRangeUpperBoundSize: 0,
  },
  {
    sizeName: 'XXL',
    enabledList: [
      {
        inRange: true,
        bestFit: false,
        size: 153,
      },
      {
        inRange: true,
        bestFit: false,
        size: 154,
      },
      {
        inRange: true,
        bestFit: false,
        size: 155,
      },
      {
        inRange: true,
        bestFit: true,
        size: 156,
      },
    ],
    bestFitInterval: true,
    lowerBoundSize: 153,
    upperBoundSize: 156,
    inRangeLowerBoundSize: 153,
    inRangeUpperBoundSize: 156,
  },
  {
    sizeName: 'empty',
    enabledList: [
      {
        inRange: false,
        bestFit: false,
        size: 0,
      },
      {
        inRange: false,
        bestFit: false,
        size: 1,
      },
      {
        inRange: false,
        bestFit: false,
        size: 2,
      },
      {
        inRange: false,
        bestFit: false,
        size: 3,
      },
    ],
    bestFitInterval: false,
    lowerBoundSize: 0,
    upperBoundSize: 0,
    inRangeLowerBoundSize: 0,
    inRangeUpperBoundSize: 0,
  },
]
