import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getTokens, setPictureId, setTokens } from '@root/sizeAdvPersistance'

import { UserPersistDataSA } from '@root/types/user'

const { accessToken, refreshToken } = getTokens()

const initialState: UserPersistDataSA = {
  pictureId: '',
  accessToken: accessToken,
  refreshToken: refreshToken,
}

//
export const slice = createSlice({
  name: 'sizeAdvUser',
  initialState,
  reducers: {
    setPictureId: (state, { payload }: PayloadAction<string>) => {
      setPictureId(payload)
      state.pictureId = payload
    },
    setUserToken: (
      state,
      { payload }: PayloadAction<{ accessToken: string; refreshToken: string }>,
    ) => {
      setTokens(payload)
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken
    },
    resetUser: () => {
      return {
        pictureId: '',
        accessToken: '',
        refreshToken: '',
      }
    },
    resetUserWithoutToken: () => {
      const token = getTokens()

      return {
        pictureId: '',
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      }
    },
  },
})

export default slice.reducer
