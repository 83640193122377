import { WebCamAbstractError } from './WebCamAbstractError';

class WebCamAccessSystemError extends WebCamAbstractError {
  public static type = 'WebCamAccessSystemError';

  constructor(error?: Error) {
    super(WebCamAccessSystemError.type, 'webcam access was rejected', error);
    Object.setPrototypeOf(this, WebCamAccessSystemError.prototype);
  }
}

export { WebCamAccessSystemError };
