import { WebCamAbstractError } from './WebCamAbstractError';

class WebCamAccessNotFoundError extends WebCamAbstractError {
  public static type = 'WebCamAccessNotFoundError';

  constructor(error?: Error) {
    super(WebCamAccessNotFoundError.type, 'webcam not found', error);
    Object.setPrototypeOf(this, WebCamAccessNotFoundError.prototype);
  }
}

export { WebCamAccessNotFoundError };
