import { AssetResolution, GlassesEnvironment } from '@luxottica/vm-glasses';
import { TrackingOptions } from '@luxottica/vm-posetracker';
import { GlRenderDebugOptions } from '@luxottica/vm-renderer-threejs';
import { VtoServerEnvironment } from '@luxottica/vm-vtoserver-client';

interface MirrorOptions {
  width?: number;
  height?: number;
  transitionColor?: string;
  videoResolution?: number;
  glassesResolution?: AssetResolution;
  device?: DeviceOptions;
  nosePosition?: NosePositionOptions;
  rendering?: RenderingOptions;
  tracking?: TrackingOptions;
  fittingModel?: MeshOptions;
  environment?: AssetEnvironmentOptions;
}

interface MirrorInternalOptions extends Required<MirrorOptions> {
  glassesType: GlassesType;
}

interface DeviceOptions {
  evaluationThreshold: number;
}

interface MeshOptions {
  videoId?: string;
  pupillaryDistance?: number;
}

interface AssetEnvironmentOptions {
  glasses?: GlassesEnvironment;
  video?: VtoServerEnvironment;
}

interface NosePositionOptions {
  enabled: boolean;
  touches?: number;
}

enum RevealFX {
  OFF = 'OFF',
  STANDARD = 'STANDARD',
}

interface RenderingOptions {
  debug: GlRenderDebugOptions;
  revealFX?: RevealFX;
}

export {
  AssetEnvironmentOptions,
  MirrorOptions,
  RenderingOptions,
  MeshOptions,
  NosePositionOptions,
  GlassesEnvironment as EnvironmentName,
  RevealFX,
  MirrorInternalOptions,
};
