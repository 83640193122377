import {
  GlassType,
  Product,
  ProductsReqFilters,
  ProductsResData,
  SearchProductApi,
} from '@root/types/products'
import { convertProductApi, convertProductType } from '@root/utils/products'
import { useGetUserInfoField, useGetUserInfoQuery } from './user'

import { ConfigWithCallbacks } from '@root/types/config'
import { PlaylistResult } from '@root/types/cms'
import { UserSearchInformation } from '@root/types/search'
import { createApi } from '@reduxjs/toolkit/query/react'
import { selectActivePlayList } from '@root/store/playlist/selectors'
import { staggeredBaseQueryWithBailOut } from '@root/utils/services'
import { useConfig } from '@root/Context'
import { useGetFrameSizeFamilyQuery } from './sizeAdvisor'
import { useSelector } from 'react-redux'

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: staggeredBaseQueryWithBailOut(
    process.env.REACT_APP_SEARCH_URL || 'https://d3kn98tkhmhpcw.cloudfront.net/api/v1',
  ),
  endpoints: builder => ({
    getMatchingProducts: builder.query<
      { numFound: number; products: Product[] },
      {
        searchInfo: UserSearchInformation
        playList: PlaylistResult
        glassType: GlassType
        filters: ProductsReqFilters
        start?: number
        rows?: number
        config: ConfigWithCallbacks
      }
    >({
      query: ({ searchInfo, glassType, playList, filters, start, rows }) => ({
        url: '/recommend/products',
        method: 'POST',
        cache: 'no-cache',
        body: {
          filters,
          features: {
            featureMap: {
              age: searchInfo.age,
              face_length: searchInfo.face_length,
              face_shape: searchInfo.face_shape,
              gender: searchInfo.gender,
              hair_color: searchInfo.hair_color,
              mood: glassType === 'SUNGLASSES' ? searchInfo.sun_mood : searchInfo.eye_mood,
              skin_undertones: searchInfo.skin_undertones,
            },
          },
          playlist: {
            elevate: playList.elevate.map(({ externalReference }) => externalReference),
            exclude: playList.exclude.map(({ externalReference }) => externalReference),
            tags: playList.tags.map(({ externalReference }) => externalReference),
            deboostTags: playList.deboostTags.map(({ externalReference }) => externalReference),
            enableTagsAsFilter: false,
          },
          rows,
          start,
        },
      }),
      transformResponse: async (
        res: {
          data: {
            numFound: number
            products: SearchProductApi[]
          }
        },
        _,
        args,
      ) => {
        return {
          numFound: res.data.numFound,
          products: await args.config.resultCallback({
            products: res.data.products.map(convertProductApi),
            numFound: res.data.numFound,
          }),
        }
      },
    }),
    getProductsFromUpcList: builder.query<
      { data: ProductsResData },
      {
        frameAdvAPICore: string
        frameAdvAPIStore: string
        upc: string[]
      }
    >({
      query: ({ frameAdvAPICore, frameAdvAPIStore, upc }) => ({
        url: `/search/${frameAdvAPICore}/upcList`,
        method: 'POST',
        body: { store: frameAdvAPIStore, upcList: upc },
      }),
    }),
  }),
})

export const useGetProductsFromUpcListQuery = (upc: string[]) => {
  const config = useConfig() as ConfigWithCallbacks

  const productsFromUpcList = searchApi.useGetProductsFromUpcListQuery({
    frameAdvAPICore: config.frameAdvAPICore,
    frameAdvAPIStore: config.frameAdvAPIStore,
    upc,
  })

  return {
    ...productsFromUpcList,
    products: productsFromUpcList.data?.data?.products,
  }
}

export const useGetMatchingProductsQuery = (start = 0) => {
  const getUserInfoQueryResult = useGetUserInfoQuery()

  const playList = useSelector(selectActivePlayList)

  const config = useConfig() as ConfigWithCallbacks

  const glassType =
    (getUserInfoQueryResult.data?.userInformation.glassesType as GlassType | undefined) ||
    (config.defaultProductType as GlassType | undefined) ||
    'SUNGLASSES'

  const filters: ProductsReqFilters = {
    core: config.frameAdvAPICore,
    store: config.frameAdvAPIStore,
    productType: [convertProductType(glassType, config)],
    vto: true,
    gender: [getUserInfoQueryResult.data?.userSearchInformation.gender || 'MALE'],
  }

  const faceBreadth = useGetUserInfoField<number>('faceBreadth')
  const frameSizeResult = useGetFrameSizeFamilyQuery(faceBreadth)
  if (config.enableSizeAdvisor && frameSizeResult) {
    // sizeadvisor hinge options
    filters.optimalHinge = frameSizeResult.data?.optimalHinge
    filters.minHinge = frameSizeResult.data?.minHinge
    filters.maxHinge = frameSizeResult.data?.maxHinge
  }

  const searchResult = searchApi.useGetMatchingProductsQuery(
    {
      searchInfo:
        getUserInfoQueryResult.data?.userSearchInformation || ({} as UserSearchInformation),
      glassType,
      playList,
      filters,
      start,
      config,
      rows: config.productRequestRowsLimit,
    },
    {
      skip:
        !getUserInfoQueryResult.data?.userSearchInformation ||
        (config.enableSizeAdvisor && !frameSizeResult.data),
    },
  )

  return {
    ...searchResult,
    numFound: searchResult.data?.numFound,
    data: searchResult.data?.products,
  }
}
