import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../package.json';
import { EnvironmentConfig } from './config/GlassesApiConfig';
import { GlassesExtractor } from './GlassesExtractor';
import { GlassesDownloadError } from './error/GlassesDownloadError';
import { GlassesDownloadForbiddenError } from './error/GlassesDownloadForbiddenError';
import { GlassesNotFoundError } from './error/GlassesNotFoundError';
import { AssetResolution } from './interfaces/AssetResolution';
import { B3dAsset } from './interfaces/B3dAsset';
import { AppViewSessionKeys } from './remotelog/AppViewSessionKeys';
import { distributorBasename, isDistributorAsset, isQADistributorAsset } from './helpers/AssetHelper';
import { GenericError } from './error/GenericError';
/* import { GlassesAsset } from "./GlassesAsset"; */

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'ZipDownloader');

/*

Some sample tokens for custom glasses coming from the distributor

available
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500681_GRIDFK~NULL~50
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500633_GRIDFK~NULL~50

not available (uses fallback)
TKN~0RB3592CP~1RB3592061_001...55~NULL~1TE3941A00_A1812.AA~1LSRB3592_2540..AB~NULL~RBCP..55
TKN~0RB3592CP~1RB3592061_001...50~NULL~1TE3941A00_A1814.AA~1LSRB3592_1766..AA~NULL~RBCP..50

with slashes (%2F)
TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F13AA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F8GAA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RX6465CP~1RX6465061_2945..51~NULL~1TE4183A00_X523%2FHAA~2064-DE~NULL~RBCP..51~K-MC5~K-RB0

*/

/*

Some sample tokens for custom glasses coming from the distributor

available
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500681_GRIDFK~NULL~50
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500633_GRIDFK~NULL~50

not available (uses fallback)
TKN~0RB3592CP~1RB3592061_001...55~NULL~1TE3941A00_A1812.AA~1LSRB3592_2540..AB~NULL~RBCP..55
TKN~0RB3592CP~1RB3592061_001...50~NULL~1TE3941A00_A1814.AA~1LSRB3592_1766..AA~NULL~RBCP..50

with slashes (%2F)
TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F13AA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F8GAA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RX6465CP~1RX6465061_2945..51~NULL~1TE4183A00_X523%2FHAA~2064-DE~NULL~RBCP..51~K-MC5~K-RB0

*/

/*

Some sample tokens for custom glasses coming from the distributor

available
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500681_GRIDFK~NULL~50
TKN~0RB2140CP~2RB2140P61_902...AA~2AP3031111_954...AA~NULL~1RB00500633_GRIDFK~NULL~50

not available (uses fallback)
TKN~0RB3592CP~1RB3592061_001...55~NULL~1TE3941A00_A1812.AA~1LSRB3592_2540..AB~NULL~RBCP..55
TKN~0RB3592CP~1RB3592061_001...50~NULL~1TE3941A00_A1814.AA~1LSRB3592_1766..AA~NULL~RBCP..50

with slashes (%2F)
TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F13AA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F8GAA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55

TKN~0RX6465CP~1RX6465061_2945..51~NULL~1TE4183A00_X523%2FHAA~2064-DE~NULL~RBCP..51~K-MC5~K-RB0

*/

class ZipDownloader {
  private remoteLog: RemoteLog = new RemoteLog();
  private static instance: ZipDownloader;

  public static getInstance(): ZipDownloader {
    if (!this.instance) {
      this.instance = new ZipDownloader();
    }
    return this.instance;
  }

  public downloadZippedGlasses(
    upc: string,
    resolution: AssetResolution,
    singleTexture: number | undefined,
    envConfig: EnvironmentConfig
  ): Promise<B3dAsset> {
    return new Promise((resolve, reject) => {
      this.tryToDownload(upc, resolution, envConfig)
        .then((blob) => {
          resolve(new GlassesExtractor().unzip(upc, blob, resolution, singleTexture));
        }).catch((e) => {
          logger.error(e);
          reject(new GlassesDownloadError(upc));
        });
    });
  }

  private tryToDownload(upc: string, resolution: AssetResolution, envConfig: EnvironmentConfig) {
    const time1 = performance.now();

    let glassesUrl: string;
    let requestOptions: RequestInit;

    const customDistributorAsset = isDistributorAsset(upc);
    const qaDistributorAsset = isQADistributorAsset(upc);

    if (customDistributorAsset) {
      // custom assets requests requires a token, these asset are distributed through a system
      // that automatically generates combinations of custom assets if not available
      glassesUrl = this.getCustomUrl(upc, envConfig);
      requestOptions = {
        headers: {
          'x-api-key': envConfig.customDistributorKey
        }
      };

    } else if (qaDistributorAsset) {
      // this URL is used locally by people that creates the pieces that
      // are combined by above distributor
      glassesUrl = this.getDistributorUrl(upc, envConfig);

    } else {
      // this is the classic b3d asset cdn
      glassesUrl = this.getUrl(upc, resolution, envConfig);
    }

    let triedLegacyCustom = true;
    const fetchGlasses = (): Promise<Blob | undefined> => {
      return fetch(glassesUrl, requestOptions)
        .then((response: Response) => {
          if (response.ok) {
            logger.debug('glasses archive {} downloaded', response.url);
            const time2 = performance.now();
            const ttime = time2 - time1;
            this.traceDownloadSuccess(upc, ttime);
            return response.blob();
          } else {
            logger.error('glasses download {} failed: [{} {}]',
              response.url, response.status, response.statusText);
            const time2 = performance.now();
            const ttime = time2 - time1;
            this.traceDownloadFailure(upc, ttime);
            switch (response.status) {
              case 403:
                throw new GlassesDownloadForbiddenError(upc);
              case 404:
                throw new GlassesNotFoundError(upc);
              default:
                throw new GlassesDownloadError(upc);
            }
          }
        })
        .catch((reason: GenericError) => {
          logger.error('glasses download {} error {}', glassesUrl, reason);

          // if we are downloading a custom asset (TKN) is worth try to download it from legacy bucket
          if (customDistributorAsset && triedLegacyCustom) {
            triedLegacyCustom = false;

            logger.debug('try to download the custom asset from legacy bucket');
            glassesUrl = this.getLegacyUrl(upc, '256', envConfig);

            // remove the token from the request because it breaks S3 bucket
            requestOptions = {};

            return fetchGlasses();
          } else {
            // is possible that the catched error is already been initialised by above promise
            if (reason.genericError) {
              throw reason;
            }
            throw new GlassesDownloadError(glassesUrl);
          }
        });
    };

    return new Promise<Blob>((resolve, reject) => {
      fetchGlasses()
        .then((blob) => {
          if (!!blob) {
            resolve(blob);
          }
          reject(new GlassesDownloadError(`${upc} returned empty blob`));
        })
        .catch((reason: GenericError) => {
          logger.error('glasses download {} error {}', glassesUrl, reason);
          reject(reason);
        });
    });
  }

  private getUrl(upc: string, resolution: AssetResolution, envConfig: EnvironmentConfig): string {
    return `${envConfig.b3dHost}/${resolution}/${upc}.zip`;
  }

  private getLegacyUrl(upc: string, resolution: AssetResolution, envConfig: EnvironmentConfig): string {
    return `${envConfig.b3dHost}/${resolution}/${upc}.zip`;
  }

  private getCustomUrl(tkn: string, envConfig: EnvironmentConfig): string {
    return `${envConfig.customDistributorHost}${envConfig.customDistributorGetPermutation}${tkn}`;
  }

  private getDistributorUrl(upc: string, envConfig: EnvironmentConfig): string {
    const baseName = distributorBasename(upc);
    return `${envConfig.b3dHost}/${baseName}/${upc}.zip`;
  }

  private traceDownloadSuccess(upc: string, ttime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.GLASSES_OK + AppViewSessionKeys.GLASSES_DOWNLOAD_SUCCESS_UPC,
        statusText: upc,
      }, {
        statusCode: AppViewSessionKeys.GLASSES_OK + AppViewSessionKeys.GLASSES_DOWNLOAD_SUCCESS_TIME,
        statusText: ttime.toString(),
      },
    );
  }

  private traceDownloadFailure(upc: string, ttime: number) {
    this.remoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.GLASSES_KO + AppViewSessionKeys.GLASSES_DOWNLOAD_FAILURE_UPC,
        statusText: upc,
      }, {
        statusCode: AppViewSessionKeys.GLASSES_KO + AppViewSessionKeys.GLASSES_DOWNLOAD_FAILURE_TIME,
        statusText: ttime.toString(),
      },
    );
  }

}

export {
  ZipDownloader,
};
