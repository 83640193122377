enum Features {
  VM_TRANSITIONS = 'VM_TRANSITIONS',
  VM_SCREENSHOT = 'VM_SCREENSHOT',
  VM_MOVE_GLASSES = 'VM_MOVE_GLASSES',
  VM_ANALYTICS = 'VM_ANALYTICS',
  VM_TAP_TO_PAUSE = 'VM_TAP_TO_PAUSE',
  VM_BIPA = 'VM_BIPA',
  VM_GET_BIPA_VERSION = 'VM_GET_BIPA_VERSION',
  VM_SAVE_BIPA_ENTRY = 'VM_SAVE_BIPA_ENTRY'
}

export {
  Features,
};
