import { AgreementType } from '../constants/AgreementType';

const BASIC_COOKIE_NAME = 'BasicImageConsent=true';
const BIOMETRIC_COOKIE_NAME = 'MlProcessImageConsent=true';
const COOKIE_PATH = 'path=/';

const CookieHelper = {
  setBiometricCookie: (agreementType: AgreementType): void => {
    if (!CookieHelper.biometricCookieExists(agreementType)) {
      document.cookie = `${CookieHelper.getBiometricCookieName(agreementType)};${COOKIE_PATH}`;
    }
  },

  biometricCookieExists: (agreementType: AgreementType): boolean => {
    if (agreementType === AgreementType.BASIC) {
      return document.cookie.includes(CookieHelper.getBiometricCookieName(AgreementType.BASIC))
       || document.cookie.includes(CookieHelper.getBiometricCookieName(AgreementType.BIOMETRIC));

    } else if (agreementType === AgreementType.BIOMETRIC) {
      return document.cookie.includes(CookieHelper.getBiometricCookieName(AgreementType.BIOMETRIC));

    } else {
      return false;
    }
  },

  getBiometricCookieName: (agreementType: AgreementType): string => {
    return agreementType === AgreementType.BIOMETRIC ? BIOMETRIC_COOKIE_NAME : BASIC_COOKIE_NAME;
  }
};

export {
  CookieHelper
};