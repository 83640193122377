import React, { useEffect } from 'react'
import { fireAppLoaded, fireAppLoading } from '@root/utils/events'

import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .spinner-content--text {
    display: none;
  }
`

const SpinnerContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SpinnerContent = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(199, 199, 199, 0.2);
  border-right: 1.1em solid rgba(199, 199, 199, 0.2);
  border-bottom: 1.1em solid rgba(169, 169, 169, 0.2);
  border-left: 1.1em solid #bababa;
  transform: translateZ(0);
  animation: faSpinner 1.1s infinite linear;

  @keyframes faSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export const Spinner: React.FC = (): JSX.Element => {
  // const { t } = useTranslation()
  useEffect(() => {
    fireAppLoading()
    return () => {
      fireAppLoaded()
    }
  }, [])

  return (
    <Wrapper className="spinner-wrapper">
      <SpinnerContentWrapper className="spinner-content-wrapper">
        <SpinnerContent className="spinner-content">
          {/* NOTE: Do not translate it - ecommerce should override the spinner */}
          <div className="spinner-content--text">Loading</div>
        </SpinnerContent>
      </SpinnerContentWrapper>
    </Wrapper>
  )
}
