import { VtoError } from '../errors/errors';
import { InitializationOptions } from '../interfaces/InitializationTypes';
import { SupportedFeatures } from '../interfaces/SupportedFeatures';
import { LocalizationHelper } from './LocalizationHelper';
import { SupportedFeaturesHelper } from './SupportedFeaturesHelper';

export class InitializationHelper {

  public static initialize(
    initOptions: InitializationOptions,
    privacyOptions: PrivacyOptions
  ): Promise<{
    userStore: UserStoreState,
    supportedFeatures: SupportedFeatures
  }> {
    return Promise.all([
      LocalizationHelper.initializeRemote(
        initOptions.environment,
        initOptions.locale,
        privacyOptions.region,
        privacyOptions.bipaActive),
      SupportedFeaturesHelper.getSupportedFeatures()
    ]).then((result) => {
      return {
        userStore: {
          locale: result[0],
          region: privacyOptions.region,
          source: privacyOptions.source,
          brand: initOptions.brand,
          storeId: initOptions.storeId,
          channel: initOptions.channel,
        },
        supportedFeatures: result[1]
      };
    }).catch((error: VtoError) => {
      throw error;
    });
  }
}
