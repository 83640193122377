const MockAssetData: {
  [key: string]: {
    nosepad_type: string,
    flare: number,
    fitting: string,
    lodNumber: number,
    misc: boolean,
  }
} = {
  'RB2140ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.023,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'RB2140ROSETTA002': {
    'nosepad_type': 'integrated',
    'flare': 0.023,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'RB3025ROSETTA001': {
    'nosepad_type': 'floating',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': true
  },
  'OO9406ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.024,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'THIRDPARTYROSETTA-NOGROUP': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'THIRDPARTYROSETTA-WGROUP': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'dbyd_dbsu5003_8719154814969': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'unofficial_unsf0146_8719154842764': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'unofficial_unsu0094_8719154843136': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'dbyd_dbsm0029_8719154744198': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'fb-quality': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'fb-standard': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'costa00000001': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'goccia00000001': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'frogskins00000001': {
    'nosepad_type': 'undefined',
    'flare': 0.025,
    'fitting': 'fast',
    'lodNumber': 1,
    'misc': false
  },
  'A03116ROSETTA003': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'DG4315BROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'HC6149ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'OO9013ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  '06A2003VROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'OO9102ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'PO3210SROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'RB3016ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  '06S4010ROSETTA001': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
  'OO9406ROSETTA002': {
    'nosepad_type': 'integrated',
    'flare': 0.025,
    'fitting': 'standard',
    'lodNumber': 2,
    'misc': false
  },
};

export {
  MockAssetData,
};
