import { PrivacyLocalization } from '../interfaces/PrivacyLocalization';

export const DefaultPrivacyLocalization: PrivacyLocalization = {
  PRIVACY_AGREEMENT_TITLE: 'Biometric data consent',
  PRIVACY_FIRST_AGREEMENT: 'We need your consent to record your face\'s biometric data and proceed with our eyewear suggestion experience. We will not store any of your information in our database and once the process is finished, we will delete all of your personal data.',
  PRIVACY_IMPLICIT_AGREEMENT: 'By continuing you accept',
  PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS: 'Terms and Conditions',
  PRIVACY_IMPLICIT_TERMS_PRIVACY_CONJUNCTION: ' and ',
  PRIVACY_IMPLICIT_PRIVACY_POLICY: 'Privacy Policy',
  PRIVACY_BUTTON_SUBMIT: 'Submit',
  PRIVACY_BUTTON_CANCEL: 'Cancel',
  BIPA_FORM_TITLE: 'Biometric data consent',
  BIPA_CITIZEN_DECLARATION: 'I declare I\'m an Illinois citizen',
  BIPA_FORM_SUBTITLE: 'Due to some Illinois regulations we need to ask you some information, please fill in your details below.',
  BIPA_FORM_NAME: 'Name',
  BIPA_FORM_SURNAME: 'Surname',
  BIPA_FORM_EMAIL: 'E-mail',
  BIPA_IMPLICIT_AGREEMENT: 'By continuing you accept',
  BIPA_IMPLICIT_TERMS_AND_CONDITIONS: 'Terms and Conditions',
  BIPA_IMPLICIT_TERMS_PRIVACY_CONJUNCTION: ' and ',
  BIPA_IMPLICIT_PRIVACY_POLICY: 'Privacy Policy',
  BIPA_FORM_IMPLICIT_AGREEMENT: 'By continuing you accept Luxottica\'s Terms and Conditions and below BIPA Notice.',
  BIPA_FORM_BUTTON_SUBMIT: 'Submit',
  BIPA_FORM_BUTTON_CANCEL: 'Cancel',
  BIPA_SERVER_ERROR: 'There was a problem with the server, please check insered informations or network connection'
};
