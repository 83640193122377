/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, createStore, Store } from 'redux';
import rootReducer from './reducers/combinedReducers';
import { MirrorStoreState } from './reducers/mirrorReducer';

export interface RootState {
  app: AppStoreState;
  user: UserStoreState;
  mirror: MirrorStoreState;
}

export function configureStore(initialState?: RootState): Store<RootState> {
  const composeEnhancers =
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'virtual-mirror'
      }) : compose;

  const enhancer = composeEnhancers();
  return createStore(rootReducer, initialState, enhancer) as Store<RootState>;
}
