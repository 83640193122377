import uuid from 'uuid';
import { IMockStreamTrack, MockTrackSettings } from './IMockStreamTrack';

class MockStreamTrack implements IMockStreamTrack {
  public readonly id: string;
  public readonly label: string;
  private settings: MockTrackSettings;

  constructor() {
    this.id = uuid();
    this.label = 'mock-stream-track';
    this.settings = {};
  }

  public getSettings() {
    return this.settings;
  }
  
  public stop() {
    return;
  }
}

export { MockStreamTrack };
