export enum TrackResult {
  OK = 0,
  LostWillTryRecovery = -3,
  LostWillReset = -4,
  FaceFittingTooNarrow = -5,
  AnglesOutOfRange = -6,
  NoFaceFound = -10,
  FaceTooSmallDetected = -11,
  FaceDetectedWaitForMoreSamples = -12,
  TrackerStopped = -20
}
