import { MeshVideoData } from '@luxottica/vm-vtoserver-client';
import { Action, createAction, handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { MeshOptions, MirrorInternalOptions } from '../interfaces/MirrorOptions';

export interface MirrorStoreState {
  glasses: MirrorB3dAsset | MirrorGltfAsset | {upc: string; status: AssetStatus};
  video: MirrorVideoData | {videoId: string; status: AssetStatus};
  mirrorOptions: MirrorInternalOptions;
  mirrorStatus: MirrorStatus;
}

const initialState: MirrorStoreState = {
  glasses: undefined,
  video: undefined,
  mirrorStatus: 'LOADING',
  mirrorOptions: undefined,
};

export const MirrorActions = {
  setMirrorOptions: createAction<MirrorInternalOptions>(Actions.SET_MIRROR_OPTIONS),
  setMirrorStatus: createAction<MirrorStatus>(Actions.SET_MIRROR_STATUS),
  setMirrorGlasses: createAction<{ upc: string, type: GlassesType }>(Actions.SET_MIRROR_GLASSES),
  setMirrorGlassesData: createAction<MirrorB3dAsset | MirrorGltfAsset>(Actions.SET_MIRROR_GLASSES_DATA),
  setMirrorGlassesStatus: createAction<{ upc: string, status: AssetStatus }>(Actions.SET_MIRROR_GLASSES_STATUS),
  setGltfMirrorGlassesStatus: createAction<{ upc: string, status: AssetStatus }>(Actions.SET_GLTF_MIRROR_GLASSES_STATUS),
  setMesh: createAction<MeshOptions>(Actions.SET_MESH),
  setMeshData: createAction<MirrorVideoData>(Actions.SET_MESH_DATA),
  setMeshStatus:
    createAction<{upc: string, status: AssetStatus}>(Actions.SET_MESH_STATUS),
};

export const MirrorReducer = handleActions<MirrorStoreState, any>({

  [Actions.SET_MIRROR_OPTIONS]: (state, action: Action<MirrorInternalOptions>): MirrorStoreState => {
    return {
      ...state,
      mirrorOptions: action.payload,
    };
  },

  [Actions.SET_MIRROR_STATUS]: (state, action: Action<MirrorStatus>): MirrorStoreState => {
    return {
      ...state,
      mirrorStatus: action.payload,
    };
  },

  [Actions.SET_MIRROR_GLASSES]: (state, action: Action<{ upc: string, type: GlassesType }>): MirrorStoreState => {
    return {
      ...state,
      glasses: {
        upc: action.payload.upc,
        type: action.payload.type,
        status: 'LOADING'
      }
    };
  },

  [Actions.SET_MIRROR_GLASSES_DATA]: (state, action: Action<MirrorB3dAsset>): MirrorStoreState => {
    return {
      ...state,
      glasses: action.payload,
    };
  },

  [Actions.SET_MIRROR_GLASSES_STATUS]: (state, action: Action<{upc: string, status: AssetStatus}>): MirrorStoreState => {
    return {
      ...state,
      glasses: {
        ...state.glasses,
        upc: action.payload.upc,
        status: action.payload.status
      },
    };
  },

  [Actions.SET_GLTF_MIRROR_GLASSES_STATUS]: (state, action: Action<{upc: string, status: AssetStatus}>): MirrorStoreState => {

    return (action.payload.upc === state.glasses.upc) ? {
      ...state,
      glasses: {
        ...state.glasses,
        upc: action.payload.upc,
        status: action.payload.status
      },
    } : {
      ...state
    };
  },

  [Actions.SET_MESH]: (state, action: Action<MeshOptions>): MirrorStoreState => {
    return {
      ...state,
      video: {
        videoId: action.payload.videoId,
        pupillaryDistance: action.payload.pupillaryDistance,
        status: 'LOADING'
      }
    };
  },

  [Actions.SET_MESH_DATA]: (state, action: Action<MeshVideoData>): MirrorStoreState => {
    return {
      ...state,
      video: {
        ...state.video,
        ...action.payload,
        status: 'READY'
      },
    };
  },

  [Actions.SET_MESH_STATUS]: (state, action: Action<{videoId: string, status: AssetStatus}>): MirrorStoreState => {
    return {
      ...state,
      video: {
        ...state.video,
        videoId: action.payload.videoId,
        status: action.payload.status
      },
    };
  },
}, initialState);
