import { UserAgentParser } from "./parser/UserAgentParser";
import { WasmDetection } from "./wasm/WasmDetection";
import { WebGlDetection } from "./webgl/WebGlDetection";
import { WebRtcDetection } from "./webrtc/WebRtcDetection";

class DeviceDetection {

    private uaData: IUAParser.IResult;

    constructor() {
        this.uaData = UserAgentParser.getInstance().getUserAgentData();
    }

    public isAppleiOS13() {
        let isIOS13 = false;
        if (this.uaData.os && this.uaData.os.name && this.uaData.os.name === "iOS") {
            if (this.uaData.os.version && this.uaData.os.version.startsWith("13")) {
                isIOS13 = true;
                // tslint:disable-next-line: no-console
                console.info("--- detected iOS 13 ---");
            }
        } else if (this.isiPadOS()) {
            isIOS13 = true;
            // tslint:disable-next-line: no-console
            console.info("--- detected iPad 13 ---");
            // tslint:disable-next-line: no-console
            console.warn("iPad OS detection is still a workaround, Apple does not use a specific UA for iPad");
        }
        return isIOS13;
    }

    public isiOS(): boolean {
        return ((this.uaData.os && this.uaData.os.name && this.uaData.os.name === "iOS") || this.isiPadOS());
    }

    // Workaround for iPadOS which is currently not detectable via UA
    // https://github.com/faisalman/ua-parser-js/issues/387#issuecomment-579147248
    public isiPadOS() {
        const osName = (this.uaData.os && this.uaData.os.name && this.uaData.os.name) || undefined;
        const iPadOS = window.orientation !== undefined && osName === "Mac OS";
        return osName === "iOS" || iPadOS;
    }

    public getUserAgentData(): IUAParser.IResult {
        return this.uaData;
    }

    public isWebView(): boolean {
        let isWebView = false;
        if (this.uaData.os && this.uaData.os.name && this.uaData.os.name === "iOS") {
            if (this.uaData.engine && this.uaData.engine.name && this.uaData.engine.name === "WebKit") {
                if (this.uaData.browser && this.uaData.browser.name && this.uaData.browser.name === "WebKit") {
                    isWebView = true;
                }
            }
        }
        return isWebView;
    }

    public isWebViewBrowser(): boolean {
        let isWebViewBrowser = false;
        if (this.uaData.os && this.uaData.os.name && this.uaData.os.name === "iOS") {
            if (this.uaData.engine && this.uaData.engine.name && this.uaData.engine.name === "WebKit") {
                if (this.uaData.browser && this.uaData.browser.name && this.uaData.browser.name !== "Mobile Safari") {
                    isWebViewBrowser = true;
                }
            }
        }
        return isWebViewBrowser;
    }

    public isWebAssemblySupported(): boolean {
        return WasmDetection.getInstance().isWebAssemblySupported();
    }

    public isUserMediaSupported(): boolean {
        return WebRtcDetection.getInstance().isUserMediaSupported();
    }

    public isVideoInputDeviceAvailable() {
        return WebRtcDetection.getInstance().isVideoInputDeviceAvailable();
    }

    public isWebGlSupported() {
        return WebGlDetection.getInstance().isWebGlSupported();
    }

}

export { DeviceDetection };
