import { name, version } from "../../../package.json";
import { LogMessage } from "../../domain/model/LogMessage";
import { RemoteLogConfig } from "../../domain/RemoteLogConfig";
import { VmAnalyticsApiConfig } from "../../domain/service/config/VmAnalyticsApiConfig";
import { sendXhrRequest } from "../../helpers/RequestHelper";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "RemoteLogAdapter");

class RemoteLogAdapter implements LogAdapter<LogMessage> {

  private config = new VmAnalyticsApiConfig();

  public log(logMessage: LogMessage): Promise<boolean> {
    try {
      const sessionId = RemoteLogConfig.getInstance().currentUuid;
      if (sessionId) {
        const jsonBody = JSON.stringify({
          browserSessionUuid: sessionId,
          date: new Date().toISOString(),
          level: logMessage.getLevel(),
          logName: logMessage.getLogName(),
          message: logMessage.getMessage(),
        });

        const requestUrl = this.config.remoteLogApiEndpoint;

        return sendXhrRequest(
          "POST",
          requestUrl,
          jsonBody,
          "",
          [],
          {
            "Accept": "application/json",
            "Content-type": "application/json",
            // "Referrer-Policy": "unsafe-url"
          },
        ).catch((e) => {
          return Promise.reject(e);
        });

      } else {
        console.warn("sessionId not available");
        return Promise.reject("sessionId not available");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export { RemoteLogAdapter };
