const assetNames = {
  b3d: 'glasses.b3d',
  transparency: 'mult.jpg',
  shadow: 'shadow-crop.jpg',
  lensMap: 'lens-map.png',
  lensBounds: 'bounds.glb',
  glassesLandmarks: 'landmarks.glb',
  color: (index: string | number) => `color-${index}.jpg`,
};

export {
  assetNames,
};
