import { getConfig } from '../config/Config';
import { InvalidInputError, RequestError } from '../error/errors';
import { pollProcessingStatus, pollPromise } from '../helpers/RequestHelper';
import { ProfileReturn } from '../interfaces/Profile';
import { ProcessVideoRequestData, UploadVideoRequestData } from '../interfaces/Video';
import { profileRequest } from './ProfileRequest';
import { refreshRequest } from './RefreshRequest';

const uploadVideoFetch = (
  request: UploadVideoRequestData
): Promise<Response> => {

  const form = new FormData();
  const mimeType = `video/${request.fileType}`;
  form.append('file', new File(request.video, `videoTake.${request.fileType}`, { type: mimeType }));

  return fetch(`${request.uploadPath}/videoTake.${request.fileType}`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      Authorization: `Bearer ${request.accessToken}`,
    },
    body: form
  }).then(response => {
    const url = getConfig(request.environment, 'additionalInfoUrl');

    if (response.ok) {
      return fetch(`${url}/${request.videoId}`, {
        method: 'PUT',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${request.accessToken}`,
        },
        'body': `{ "mimeType": "${mimeType}" }`
      });
    } else {
      const e = new Error('failed to upload');
      throw e;
    }
  });
};

const processVideoFetch = (
  request: ProcessVideoRequestData
): Promise<Array<Response | undefined>> => {
  const mlUrl = getConfig(request.environment, 'enrichUrl');
  const vtoUrl = getConfig(request.environment, 'poseExtractionUrl');

  return Promise.all([
    (request.enableMachineLearning) ? fetch(mlUrl, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${request.accessToken}`
      },
      'body': `{ "uuid": "${request.videoId}" }`
    }): undefined,
    (request.enableVto) ? fetch(vtoUrl, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${request.accessToken}`
      },
      'body': `{ "uuid": "${request.videoId}" }`
    }) : undefined
  ]);
};

const uploadVideoRequest = (
  request: UploadVideoRequestData
): Promise<void> => {

  return uploadVideoFetch(request).then((uploadVideoResponse) => {
    if (uploadVideoResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return uploadVideoFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return uploadVideoResponse;
    }
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json().catch(() => {
        throw new Error(`${response.status}: ${response.statusText}`);
      }).then((json) => {
        throw new Error(JSON.stringify(json));
      });
    }
  });
};

const processVideoRequest = (
  request: ProcessVideoRequestData
): Promise<ProfileReturn> => {

  if (!request.enableMachineLearning && !request.enableVto) {
    return Promise.reject(new InvalidInputError('enableMachineLearning and enableVto cannot both be set to false'));
  }

  return processVideoFetch(request).then((processVideoResponse) => {
    if ((!!processVideoResponse[0] && processVideoResponse[0].ok && processVideoResponse[0].status === 401)
    || (!!processVideoResponse[1] && processVideoResponse[1].ok && processVideoResponse[1].status === 401)) {
      return refreshRequest(request).then((refreshResponse) => {
        return processVideoFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return processVideoResponse;
    }

  }).then((response) => {
    if ((response[0] === undefined || response[0].ok)
    && (response[1] === undefined || response[1].ok)) {

      const promises: Promise<boolean>[] = [];
      const entries: string[] = ['POSES_EXTRACTION'];

      if (request.enableVto) {
        entries.push('BASEL_MESH');
      }

      if (request.enableMachineLearning) {
        entries.push('ML_INFORMATION');

        const pdStatusUrl = `${getConfig(request.environment, 'pdStatusUrl')}/${request.videoId}` ;
        promises.push(pollPromise(
          1000,
          30000,
          () => pollProcessingStatus(request, pdStatusUrl, ['PD']),
          request.shouldCancel
        ));
      }

      const statusUrl = `${getConfig(request.environment, 'statusUrl')}/${request.videoId}` ;
      promises.push(pollPromise(
        1000,
        30000,
        () => pollProcessingStatus(request, statusUrl, entries),
        request.shouldCancel
      ));

      return Promise.all(promises).then(results => results.every(result => !!result));

    } else {
      throw new RequestError(`failed to poll status with sessionId=${request.videoId}`);
    }

  }).then((success) => {
    if (success) {
      return profileRequest(request);
    } else {
      throw new RequestError(`failed to poll status with sessionId=${request.videoId}`);
    }
  });
};

export {
  uploadVideoRequest,
  processVideoRequest
};
