import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { UpcAvailability } from './interfaces/UpcAvailability';
import { name, version } from '../package.json';
import { TransitionColor } from './interfaces/TransitionColor';
import { CatalogConfiguration } from './interfaces/CatalogConfiguration';
import { CatalogueApiConfig } from './config/CatalogueApiConfig';
import { notAvailableResponse } from './helpers/UpcAvailabilityResponse/NotAvailable';
import { qaToolAvailability } from './helpers/UpcAvailabilityResponse/QATool';
import { standardResponse } from './helpers/UpcAvailabilityResponse/Standard';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'CatalogService');

const fetchCatalogResponse = (
  config: CatalogConfiguration,
  upc: string
): Promise<Response> => {

  const apiEndpoint = CatalogueApiConfig.getUpcsAvailabilityApiEndpoint(config, upc);
  const requestInit: RequestInit = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      ...(config.key && { 'x-api-key': config.key })
    },
    mode: 'cors',
    referrerPolicy: 'unsafe-url',
  };
  return fetch(apiEndpoint, requestInit);
};

const CatalogService = {
  getUpcAvailability(
    config: CatalogConfiguration,
    upc: string
  ): Promise<UpcAvailability> {

    // inside the QA tool there's no UPC availability API for unpublished assets
    if (config.env.includes('QA')) {
      return Promise.resolve<UpcAvailability>(qaToolAvailability(upc, config));
    }

    // otherwise we fetch the answer from remote
    return fetchCatalogResponse(config, upc)
    .then((response)=> {
      if (response.ok) {
        return response.json();
      }
      throw new Error(`catalog failed to respond. Response status: ${response.status}`);

    }).then((json: CatalogResponses) => {
      if (!!json[upc]) {
        return standardResponse(json[upc], upc, config);
      }
      return notAvailableResponse(upc);
    });
  },

  downloadTransitionColors(
    config: CatalogConfiguration,
  ): Promise<{ [name: string]: TransitionColor }> {

    const apiEndpoint = CatalogueApiConfig.getTransitionEndpoint(config);
    return fetch(apiEndpoint).then((response) => {
      return response.json();
    }).then((json) => {
      try {
        const result: {
          [upc: string]: TransitionColor
        } = {};

        (json as any[]).forEach((color: any) => {
          result[color.name] = {
            mirror: color.mirror,
            active: color.active,
            clear: color.clear
          };
        });
        return result;
      } catch (e) {
        logger.error(e);
      }
      return {};
    });
  }
};

export {
  CatalogService,
};
