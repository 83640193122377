import { Environment, getHostname } from "./Environment.js";

// const requireFromUrl = require('require-from-url');
// TODO: modify the path for the new wasmVersion
import Module from "../artifacts/headtracker/3.1.3/vm_ht.js";

// returns the javascript glue code
// TODO: here probably is where we should introduce a dynamic return in order to load localhost releases
// TODO: modify the path for the new wasmVersion
const getWasmUrl = (env: Environment): string => {
  return `${getHostname(env)}/headtracker/3.1.3/vm_ht.wasm`;
};

const loadWasm = (env: Environment) => {
  const wasmUrl = getWasmUrl(env);
  const wsm = Module({
    locateFile(path: string) {
      if (path.endsWith(".wasm")) {
        return wasmUrl;
      } else {
        return undefined;
      }
    },
  });
  return wsm;
};

export {
  Environment as WasmEnvironment,
  loadWasm,
};
