import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { UpcAvailability } from './interfaces/UpcAvailability';
import { name, version } from '../package.json';
import { TransitionColor } from './interfaces/TransitionColor';
import { CatalogConfiguration } from './interfaces/CatalogConfiguration';
import { CatalogService } from './CatalogService';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'Catalog');

const Catalog = {
  getUpcAvailability(
    config: CatalogConfiguration,
    upc: string
  ): Promise<UpcAvailability> {

    logger.info('getUpcAvailability for {}', upc);
    return CatalogService.getUpcAvailability(config, upc);
  },

  // getUpcsAvailability(
  //   config: CatalogConfiguration,
  //   ...upcs: string[]
  // ): Promise<{ [upc: string]: UpcAvailability }> {
  //   return CatalogService.getUpcsAvailability(config, ...upcs);
  // },

  downloadTransitionColors(
    config: CatalogConfiguration
  ): Promise<{ [name: string]: TransitionColor }> {

    logger.info('downloadTransitionColors');
    return CatalogService.downloadTransitionColors(config);
  }
};

export {
  Catalog,
};
