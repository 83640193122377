import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { vec3 } from "gl-matrix";
import { name, version } from "../../package.json";

import { TrackingOptions } from "../PoseTracker.js";
import StringHelper from "./StringHelper.js";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "PoseHelper");

export interface PitchYawRollRange {
  pitch: {start: number, end: number};
  yaw: {start: number, end: number};
  roll: {start: number, end: number};
}

export interface FormattedPose {
  position: {
    x: string,
    y: string,
    z: string,
  };
  rotation: {
    p: string,
    y: string,
    r: string
  };
}

export class PoseHelper {

  public static checkPoseSanity(
    pose: Float32Array,
    options: TrackingOptions,
    focalLength: number,
    processingWidth: number,
    processingHeight: number
  ): boolean {

    if (pose && pose[3] && pose[4] && pose[5]) {
      const poseSanity = (pose[5] > options.depthRange[0] && pose[5] < options.depthRange[1])
        && this.checkPoseEdge(pose, options, focalLength, processingWidth, processingHeight);
      if (!poseSanity) {
        logger.debug("pose sanity check failed");
      }
      return poseSanity;
    } else {
      return false;
    }
  }

  public static checkPoseEdge(
    poseArray: Float32Array,
    trackingOptions: TrackingOptions,
    focalLength: number,
    width: number,
    height: number
  ): boolean {
    const topEdgeBound = trackingOptions.edgeDetectionPercentage[0] * height;
    const rightEdgeBound = trackingOptions.edgeDetectionPercentage[1] * width;
    const bottomEdgeBound = height - (trackingOptions.edgeDetectionPercentage[2] * height);
    const leftEdgeBound = width - (trackingOptions.edgeDetectionPercentage[3] * width);

    const xProjection = (poseArray[3] * focalLength / poseArray[5]) + (width / 2);
    const yProjection = (poseArray[4] * focalLength / poseArray[5]) + (height / 2);

    return (yProjection >= topEdgeBound
      && xProjection >= rightEdgeBound
      && yProjection <= bottomEdgeBound
      && xProjection <= leftEdgeBound
    );
  }

  public static formatPose(pose: Float32Array): FormattedPose {
    if (pose && pose[3] && pose[4] && pose[5]) {
      const eulerAngles: number[] = PoseHelper.axisAngleToEuler(pose);
      return {
        position: {
          x: pose[3].toFixed(2),
          y: pose[4].toFixed(2),
          z: pose[5].toFixed(2),
        },
        rotation: {
          p: StringHelper.radToDeg(eulerAngles[0], 2),
          y: StringHelper.radToDeg(eulerAngles[1], 2),
          r: StringHelper.radToDeg(eulerAngles[2], 2),
        },
      };
    } else {
      return undefined;
    }
  }

  public static axisAngleToEuler = (axisAngle: Float32Array) => {
    const axis = vec3.fromValues(axisAngle[0], axisAngle[1], axisAngle[2]);
    const angle = vec3.length(axis);
    if (angle < 1e-6) {
      return [0.0, 0.0, 0.0];
    }
    axis[0] /= angle;
    axis[1] /= angle;
    axis[2] /= angle;

    const q = new Float32Array(4);
    const sine = Math.sin(angle);
    q[0] = Math.sqrt(2 + 2 * Math.cos(angle)) / 2;
    q[1] = sine * axis[0] / (2 * q[0]);
    q[2] = sine * axis[1] / (2 * q[0]);
    q[3] = sine * axis[2] / (2 * q[0]);
    const pitch = Math.atan2(2 * (q[0] * q[1] - q[2] * q[3]),
      q[0] * q[0] - q[1] * q[1] - q[2] * q[2] + q[3] * q[3]);
    const yaw = Math.asin(2 * (q[0] * q[2] + q[1] * q[3]));
    const roll = Math.atan2(2 * (q[0] * q[3] - q[1] * q[2]),
      q[0] * q[0] + q[1] * q[1] - q[2] * q[2] - q[3] * q[3]);
    return [pitch, yaw, roll];
  }

  public static convertDegreeRangeToRadian(axisRange: number[]): PitchYawRollRange {
    logger.debug("axis range: {}", axisRange);
    const axisRad = [
      axisRange[0] * Math.PI / 180.0,
      axisRange[1] * Math.PI / 180.0,
      axisRange[2] * Math.PI / 180.0,
    ];
    const axisRangeRad = {
      pitch: { start: -(axisRad[0] / 2), end: (axisRad[0] / 2) },
      yaw: { start: -(axisRad[1] / 2), end: (axisRad[1] / 2) },
      roll: { start: -(axisRad[2] / 2), end: (axisRad[2] / 2) }
    };
    logger.debug("radian range: {}", JSON.stringify(axisRangeRad));

    return axisRangeRad;
  }
}
