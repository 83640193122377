import { Id, QueryResult } from '@root/types/common'

import { Base64Image } from '@root/types/image'
import { Region } from '@root/types/config'
import { convertToDataUrl } from '@root/utils/image'
import { createApi, skipToken } from '@reduxjs/toolkit/query/react'
import { isNotUndefined } from '@root/utils/types'
import { staggeredBaseQueryWithBailOut } from '@root/utils/services'
import { useConfig } from '@root/Context'
import { userApi } from './user'
import { getUserId } from '@root/persistance'

export const profileMediaApi = createApi({
  reducerPath: 'profileMediaApi',
  baseQuery: staggeredBaseQueryWithBailOut(
    process.env.REACT_APP_PROFILE_URL ||
      'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtoprofilemsupload/mock/v2',
    true,
  ),
  endpoints: builder => ({
    getProfilePicture: builder.query<Base64Image, { region: Region; profileUuid: Id }>({
      query: ({ region, profileUuid }) => ({
        url: `/${region}/${profileUuid}/take0.jpg`,
        responseHandler: response => response.blob(),
      }),
      transformResponse: (imageFile: File) => {
        return URL.createObjectURL(imageFile)
      },
    }),
    getUserPicture: builder.query<Base64Image, { region: Region; pictureUuid: Id }>({
      query: ({ region, pictureUuid }) => ({
        url: `/${region}/${pictureUuid}/take0.jpg`,
        responseHandler: response => response.blob(),
      }),
      transformResponse: (imageFile: File) => {
        return URL.createObjectURL(imageFile)
      },
    }),

    getUserVideo: builder.query<Base64Image[], { region: Region; videoUuid: Id }>({
      queryFn: async ({ region, videoUuid }, _, __, baseQuery) => {
        const takes = new Array(14).fill(0).map((_, index) => index)
        const imagesResponse = await Promise.all(
          takes.map(async take => {
            const imageFileResponse = (await baseQuery({
              url: `/${region}/${videoUuid}/resultImageOrig${take}.jpg`,
            })) as QueryResult<File>

            if (imageFileResponse.data) {
              const base64Image = await convertToDataUrl(imageFileResponse.data)
              return {
                ...imageFileResponse,
                data: base64Image,
              }
            } else {
              return imageFileResponse
            }
          }),
        )
        const error = imagesResponse.find(({ error }) => error)?.error
        const data = imagesResponse.map(({ data }) => data).filter(isNotUndefined)
        return {
          error,
          data,
        } as QueryResult<Base64Image[]>
      },
    }),
  }),
})

export const useGetProfilePicture = () => {
  const config = useConfig()

  const userUuid = getUserId()
  const userInfo = userApi.useGetUserInfoQuery(userUuid || skipToken)
  const profileUuid = userInfo.data?.profileUuid

  return profileMediaApi.useGetProfilePictureQuery(
    { region: config.facescanRegion, profileUuid: profileUuid || '' },
    { skip: !profileUuid },
  )
}

export const useGetUserPicture = () => {
  const config = useConfig()

  const userUuid = getUserId()
  const userInfo = userApi.useGetUserInfoQuery(userUuid || skipToken)
  const pictureUuid = userInfo.data?.pictureUuid

  return profileMediaApi.useGetUserPictureQuery(
    { region: config.facescanRegion, pictureUuid: pictureUuid || '' },
    { skip: !pictureUuid },
  )
}
