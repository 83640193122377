import React from 'react';
import ReactDOM from 'react-dom';
import { ROOT_STYLE_NAME } from '../../constants/Styling';
import { CaptureOptions } from '../../interfaces/CaptureParameters';
import { FrameAdvisorContainer } from './FrameAdvisorContainer';

let currentContainer;

const loadFrameAdvisor = (
  container: HTMLElement,
  options: CaptureOptions,
  onMount: () => void
) => {
  currentContainer = container;
  currentContainer.classList.add(ROOT_STYLE_NAME);
  ReactDOM.render(
    <FrameAdvisorContainer options={options}/>,
    container,
    onMount
  );
};

const closeFrameAdvisorComponent = () => {
  if (!!currentContainer) {
    ReactDOM.unmountComponentAtNode(currentContainer);
  }
};

export {
  loadFrameAdvisor,
  closeFrameAdvisorComponent
};
