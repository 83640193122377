import { bipaAgreementRequest, loginRequest, MicroserviceEnvironment } from '@luxottica/vto-microservices';
import React, { useEffect, useRef, useState } from 'react';
import './BipaOverlay.scss';
import { BipaBiometricForm } from './components/BipaBiometricForm';
import { PrivacyAgreement } from './components/PrivacyAgreement';
import { AgreementType } from './constants/AgreementType';
import { ROOT_STYLE_NAME } from './constants/Styling';
import { CookieHelper } from './helpers/CookieHelper';
import { LocalizationHelper } from './helpers/LocalizationHelper';
import { fetchBipaJson } from './helpers/RequestHelper';
import { BipaCallbacks } from './interfaces/BipaCallbacks';
import { BipaFormData } from './interfaces/BipaFormData';
import { BipaPage } from './interfaces/BipaPage';
import { BipaPrivacyOptions } from './interfaces/PrivacyOptions';

interface BipaOverlayProps {
  type: AgreementType;
  environment: MicroserviceEnvironment;
  tokenVersion: 1 | 2;
  accessToken: string;
  refreshToken: string;
  locale: string;
  region: string;
  source: string;
  formPrefill?: Partial<BipaFormData>;
  privacy: BipaPrivacyOptions;
  callbacks: BipaCallbacks;
}

const BipaOverlay = (props: BipaOverlayProps) => {

  const isFirstRender = useRef(true);

  const [illinoisUser, setIlliniosUser] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<BipaPage>(undefined);
  const [bipaVersion, setBipaVersion] = useState<number>(undefined);

  const onClickSubmit = () => {
    if (illinoisUser) {
      setCurrentPage('BIOMETRIC_FORM');
    } else {
      CookieHelper.setBiometricCookie(props.type);
      props.callbacks.onSubmit();
    }
  };

  const onClickBipaSubmit = (formData: BipaFormData) => {
    CookieHelper.setBiometricCookie(props.type);
    props.callbacks.onSubmit(formData);
  };

  const onClickCancel = () => {
    props.callbacks.onUserReject();
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      loginRequest({
        environment: props.environment,
        version: props.tokenVersion,
        accessToken: props.accessToken,
        refreshToken: props.refreshToken,
        onAuthUpdated: props.callbacks.onAuthUpdated
      }).then((loginReturn) => {
        return Promise.all([
          fetchBipaJson(props.type, props.environment, props.locale),
          (props.region === 'US') ? bipaAgreementRequest({
            locale: props.locale,
            environment: props.environment,
            accessToken: loginReturn.accessToken,
            refreshToken: loginReturn.refreshToken,
            onAuthUpdated: props.callbacks.onAuthUpdated
          }) : Promise.resolve(undefined)
        ]);
      }).then((response) => {
        const bipaAgreementText = (!!response?.[1]?.text) ? response[1].text : undefined;
        const bipaAgreementVersion = (!!response?.[1]?.version) ? response[1].version : undefined;
        
        LocalizationHelper.setText({
          ...response[0],
          BIPA_FORM_BIPA_AGREEMENT: bipaAgreementText,
          ...props.privacy.localizationText,
        });
        setBipaVersion(bipaAgreementVersion);
        setCurrentPage('PRIVACY');
      }).catch((e) => {
        props.callbacks.onError(e);
      });
    }
  }, []);

  const renderPage = (props: BipaOverlayProps, currentPage: BipaPage) => {
    if (currentPage === 'PRIVACY') {
      return (
        <PrivacyAgreement
          privacyOptions={props.privacy}
          callbacks={props.callbacks}
          region={props.region}
          isIllinoisUser={illinoisUser}
          onSetIllinoisUser={(isIllinoisUser) => { setIlliniosUser(isIllinoisUser); }}
          onSubmit={onClickSubmit}
          onCancel={onClickCancel}/>
      );
    } else if (currentPage === 'BIOMETRIC_FORM') {
      return (
        <BipaBiometricForm
          accessToken={props.accessToken}
          refreshToken={props.refreshToken}
          environment={props.environment}
          source={props.source}
          bipaVersion={bipaVersion}
          privacyOptions={props.privacy}
          callbacks={props.callbacks}
          formPrefill={props.formPrefill}
          onBipaSubmit={onClickBipaSubmit}
          onCancel={() => setCurrentPage('PRIVACY')}
          onError={props.callbacks.onError}/>
      );
    }
  };

  return (
    <div id={ROOT_STYLE_NAME} className='bipa-overlay'>
      <div id='bipa-component-container'>
        {renderPage(props, currentPage)}
      </div>
    </div>
  );
};

export {
  BipaOverlay,
  BipaOverlayProps
};
