import { Base64Image } from '@root/types/image'

export const convertToDataUrl = (file: File): Promise<Base64Image> =>
  new Promise((res, rej) => {
    const reader = new FileReader()

    reader.addEventListener('loadend', () => {
      if (typeof reader.result !== 'string') {
        rej('reader result is not a string')
        return
      }

      res(reader.result)
    })

    reader.readAsDataURL(file)
  })
