import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setHinge2Hinge, setModelInfo } from '@root/sizeAdvPersistance'
import { HingeValueRequest, ModelAttributesRequest } from '@root/types/sizeadvisor'

type SizeAdvisorInfo = {
  hingeValue: HingeValueRequest | undefined
  modelAttributes: ModelAttributesRequest | undefined
}

const initialState: SizeAdvisorInfo = {
  hingeValue: undefined,
  modelAttributes: undefined,
}

export const slice = createSlice({
  name: 'sizeAdvisorInfo',
  initialState,
  reducers: {
    setHingeValue: (state, { payload }: PayloadAction<HingeValueRequest>) => {
      setHinge2Hinge(payload)
      return {
        ...state,
        hingeValue: payload,
      }
    },
    setModelAttributes: (state, { payload }: PayloadAction<ModelAttributesRequest>) => {
      setModelInfo(payload)
      return {
        ...state,
        modelAttributes: payload,
      }
    },
    resetSizeAdvisorInfo: () => ({
      hingeValue: undefined,
      modelAttributes: undefined,
    }),
  },
})

export default slice.reducer
