import { RemoteLog, RemoteLogConfig } from "@luxottica/vm-remotelog";
import { loadWasm, WasmEnvironment } from "@luxottica/vm-webassembly";
import { name, version } from "../package.json";
import { GuidanceCommand } from "./constants/GuidanceCommand";
import { NudgingType } from "./constants/NudgingType";
import { StandstillStatus } from "./constants/StandstillStatus";
import { TrackResult } from "./constants/TrackResult";
import { GuidanceEngine, StandstillOptions } from "./guidance/GuidanceEngine";
import { FormattedPose, PoseHelper } from "./helpers/PoseHelper";
import { NudgingEventProducer } from "./nudging/adapter/NudgingEventProducer";
import { INudgingEventProducer } from "./nudging/domain/INudgingEventProducer";
import { Version } from "./Version";
import { WasmCapabilities } from "./WasmCapabilities";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "PoseTracker");

declare interface TrackingOptions {
  debug?: boolean;
  inspect?: boolean;
  onPoseTrack?: (VtoMirrorPose) => void;
  recoveryLimit?: number;
  areaSize?: number;
  areaOffset?: number[];
  depthRange?: number[];
  axisRange?: number[];
  minimumFaceSizeRatio?: number;
  fittedPointsRatio?: number;
  edgeDetectionPercentage?: number[];
  nudgingType?: NudgingType;
  standstill?: StandstillOptions;
  // this field is not used by the posetracker but at the moment of writing
  // is used by Virtual Mirror to set a FOV different from default (52.0)
  fov?: number;
}

declare interface PoseResponse {
  pose: Float32Array;
  renderDelay: boolean;
  guidanceCommand: GuidanceCommand;
  standstillStatus: StandstillStatus;
  wasmStatus: TrackResult;
  wearsGlasses: boolean;
}

// No more needed, the processing is an input parameter
// const MAX_PROCESSING_DIMENSION = 480;
const htConfigurableValidRanges = false;

let mappedMemoryBuffer: any;

class PoseTracker {

  private FOV_PORTRAIT;

  private guidanceEngine: GuidanceEngine;

  private nudgingEventProducer: INudgingEventProducer;

  private headTrackerPromise: Promise<any>;
  private headTrackerIsReady: boolean;
  private mappedArray: Uint8ClampedArray;

  private processingW: number;
  private processingH: number;
  private verticalFocalLength: number;

  private poseTrackerStatus = TrackResult.TrackerStopped;
  private headTracker: any;
  private trackingOptions: TrackingOptions;

  private static initializerPromise;

  public static warmUp(env: WasmEnvironment) {
    if (!PoseTracker.initializerPromise) {
      PoseTracker.initializerPromise = import(/* webpackChunkName: "HeadTrackerInitializer" */ "./wasm/HeadTrackerInitializer")
      .then(({HeadTrackerInitializer}) => {
        return {
          wasm: loadWasm(env),
          HeadTrackerInitializer,
        };
      });
    }
  }

  constructor(
    processingW: number,
    processingH: number,
    processingVerticalFoVDeg: number,
    trackingOptions: TrackingOptions,
    wasmCapabilities: WasmCapabilities,
    onWasmLoaded: () => void,
    onFail?: () => void,
  ) {
    logger.debug("PoseTracker created: {}", Version.getInstance().getModuleInfo());
    this.trackingOptions = {
      ...trackingOptions,
    };
    this.guidanceEngine = new GuidanceEngine(this.trackingOptions);

    this.nudgingEventProducer = new NudgingEventProducer();
    this.processingW = processingW;
    this.processingH = processingH;
    this.setFocalLength(processingVerticalFoVDeg);

    try {
      this.headTrackerPromise = PoseTracker.initializerPromise
        .then(({HeadTrackerInitializer, wasm}) => {
          return new Promise((resolve, reject) => {
            try {
              // don't be fooled by this signature, loadWasm is not a promise, blame webassembly for this
              wasm.then((wasmModule) => {
                new HeadTrackerInitializer(this.processingW, this.processingH, this.verticalFocalLength)
                  .init(wasmModule)
                  .then((ht) => {
                    this.headTracker = ht;
                    this.headTrackerIsReady = true;
                    resolve(ht);
                    onWasmLoaded();
                  }).catch((reason) => {
                    reject(reason);
                    logger.error("posetracker initialization failed: {}", reason);
                  });

                // Passing also processings size,
                // since now the total area may be larger than MAX_PROCESSING_DIMENSION^2
                this.initialiseSharedMemory(wasmModule, this.processingW, this.processingH);
              });
            } catch (error) {
              reject(error);
            }
          });
        });
    } catch (e) {
      logger.error(e);
      if (onFail) {
        onFail();
      }
    }
  }

  private static deg2rad(fov: number): number {
    return fov * Math.PI / 180.0;
  }

  public isReady(): boolean {
    if (this.headTrackerIsReady) {
      logger.debug("headtracker is ready");
      return true;
    } else {
      logger.debug("headtracker not ready");
      return false;
    }
  }

  // Added getter
  public get trackingWidth(): number {
    return this.processingW;
  }

  // Added getter
  public get trackingHeight(): number {
    return this.processingH;
  }

  public get trackingVerticalFoVDeg(): number {
    return this.FOV_PORTRAIT * 180.0 / Math.PI;
  }

  public setDebug(enable: boolean) {
    this.headTrackerPromise.then((ht) => {
      ht.setDebug(enable);
      logger.debug("config headtracker debug: {}", enable);
    }).catch((reason) => {
      logger.warn("config headtracker debug failed: {}", reason);
    });
  }

  public setGlassesDetection(enable: boolean) {
    this.headTrackerPromise.then((ht) => {
      ht.setGlassesDetection(enable);
      logger.debug("config headtracker glasses detection: {}", enable);
    }).catch((reason) => {
      logger.warn("config headtracker glasses detection failed: {}", reason);
    });
  }

  public setLightingDetection(enable: boolean) {
    this.headTrackerPromise.then((ht) => {
      ht.setLightingDetection(enable);
      logger.debug("config headtracker lighting detection: {}", enable);
    }).catch((reason) => {
      logger.warn("config headtracker lighting detection failed: {}", reason);
    });
  }

  public set3DHead(head3DPoints: Float32Array) {
    this.headTrackerPromise.then((ht) => {
      ht.set3DHeadModel(head3DPoints);
      logger.debug("config headtracker 3d head model: {}", head3DPoints);
    }).catch((reason) => {
      logger.warn("config headtracker 3d head model failed: {}", reason);
    });
  }

  // not used
  // be careful because when FOV changes is ALWAYS required
  // to call setCameraParameters to update the headtracker
  // TODO: check if wasm reset itself after setCameraParameters
  public setFovDegrees(fov: number) {
    logger.debug("updating FOV to {}", fov);
    // set FOV_LANDSCAPE and FOV_PORTRAIT in radians
    // set verticalFocalLength
    this.setFocalLength(fov);
    this.headTrackerPromise.then((ht) => {
      ht.setCameraParameters(
        this.verticalFocalLength,
        this.processingW / 2.0,
        this.processingH / 2.0,
      );
    });
  }

  private setFocalLength(fov: number) {
    this.FOV_PORTRAIT = PoseTracker.deg2rad(fov);
    this.verticalFocalLength = this.getVerticalFocalLength();
  }

  public setFaceDetectionArea(
    processingWidth: number,
    processingHeight: number,
    minimumFaceSizeRatio: number,
    fittedPointsRatio: number
  ) {
    this.processingW = processingWidth;
    this.processingH = processingHeight;
    this.verticalFocalLength = this.getVerticalFocalLength();
    this.headTrackerPromise.then((ht) => {
      const area = {
        x: 0,
        y: 0,
        width: this.processingW,
        height: this.processingH
      };

      ht.setImageSize(this.processingW, this.processingH);
      ht.setCameraParameters(
        this.verticalFocalLength,
        this.processingW / 2.0,
        this.processingH / 2.0,
      );
      ht.setFaceDetectionArea(area, minimumFaceSizeRatio, fittedPointsRatio);
      logger.debug("config headtracker face detection area              : {}", JSON.stringify(area));
      logger.debug("config headtracker face detection minFaceSizeRatio  : {}", minimumFaceSizeRatio);
      logger.debug("config headtracker face detection fittedPointsRatio : {}", fittedPointsRatio);

    }).catch((reason) => {
      logger.warn("config headtracker face detection area failed: {}", reason);
    });
  }

  public setInitialisationValidRanges(axisRange: number[]) {
    if (htConfigurableValidRanges) {
      this.headTrackerPromise.then((ht) => {
        const validRanges = PoseHelper.convertDegreeRangeToRadian(axisRange);
        ht.setInitialisationValidRanges(
          validRanges[0],
          validRanges[1],
          validRanges[2]);
      }).catch((reason) => {
        logger.warn("config headtracker valid ranges failed: {}", reason);
      });
    } else {
      logger.debug(("ht valid ranges configuration OFF"));
    }
  }

  // count is the number of times the face has to be detected on roughly
  // the same area for the tracker to be initialised
  /**
   * Set minimum detections of the same face before running the tracker
   * @param count Minimum number of continuous detections
   * @param overlap Minimum overlap between the last detection and
   *  the current one. [0-1.0]
   */
  public setMinimumFaceDetectionCount(howManyTimeTheFaceHasToBeDetected: number, overlap: number) {
    this.headTrackerPromise.then((ht) => {
      ht.setMinimumFaceDetectionCount(howManyTimeTheFaceHasToBeDetected, overlap);
      logger.debug("config headtracker min face detection: {} {}", howManyTimeTheFaceHasToBeDetected, overlap);
    }).catch((reason) => {
      logger.warn("config headtracker min face detection failed: {}", reason);
    });
  }

  // defaults to 10 in the native side
  public setFastRecoveryLimit(limit: number) {
    this.headTrackerPromise.then((ht) => {
      ht.setFastRecoveryLimit(limit);
      logger.debug("config headtracker recovery limit: {}", limit);
    }).catch((reason) => {
      logger.warn("config headtracker recovery limit failed: {}", reason);
    });
  }

  public reset() {
    this.headTrackerPromise.then((ht) => {
      ht.reset();
      logger.debug("headtracker reset");
    }).catch((reason) => {
      logger.warn("headtracker reset failed: {}", reason);
    });
  }

  public setTrackingOptions(options: TrackingOptions): void {
    this.trackingOptions = options;
    this.guidanceEngine.updateTrackingOptions(this.trackingOptions);
  }

  public getPose(imageData: ImageData): PoseResponse {
    logger.debug("image data size: w {}, h {}", imageData.width, imageData.height);

    this.mappedArray.set(imageData.data, 0);
    if (this.headTrackerIsReady) {
      const retCode = this.getHeadTrackerStatus();
      if (this.poseTrackerStatus !== retCode.status.value) {
        this.poseTrackerStatus = retCode.status.value;
        this.sendPoseTrackerEvent(retCode.status.value.toString(), TrackResult[retCode.status.value]);
      }
      const poseArray: Float32Array = this.checkPoseSanity(retCode.pose, this.trackingOptions);
      this.guidanceEngine.addPose(poseArray, retCode.status.value, imageData);
      if (this.guidanceEngine.shouldReset) {
        logger.debug("resetting posetracker");
        this.reset();
        this.guidanceEngine.shouldReset = false;
      }
      // TODO
      // const glasses = (retCode.wearsGlasses === this.wasmModule.UserWearsGlasses.Yes);
      // const badLighting = (retCode.lighting === this.wasmModule.LightingEnvironment.Bad);
      const glasses = false;

      if (retCode.status.value === TrackResult.OK && poseArray === undefined) {
        this.reset();
      }

      return {
        guidanceCommand: this.guidanceEngine.guidanceStatus,
        pose: poseArray,
        renderDelay: this.guidanceEngine.renderDelay,
        standstillStatus: this.guidanceEngine.standstillStatus,
        wasmStatus: retCode.status.value,
        wearsGlasses: glasses,
      };
    }
    return {
      guidanceCommand: this.guidanceEngine.guidanceStatus,
      pose: undefined,
      renderDelay: this.guidanceEngine.renderDelay,
      standstillStatus: this.guidanceEngine.standstillStatus,
      wasmStatus: TrackResult.TrackerStopped,
      wearsGlasses: false,
    };
  }

  public formatPose = (pose: Float32Array) => {
    return PoseHelper.formatPose(pose);
  }

  private getHeadTrackerStatus(): any {
    if (this.headTrackerIsReady) {
      try {
        const headTrackerStatus = this.headTracker.track(mappedMemoryBuffer);
        return headTrackerStatus;
      } catch (e) {
        logger.error(e.message);
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private sendPoseTrackerEvent(poseTrackerStatusCode: string, poseTrackerStatusMess: string) {
    this.nudgingEventProducer.newNudgingState(poseTrackerStatusCode, poseTrackerStatusMess).then(() => {
      logger.debug("new nudging state notified");
    }).catch((e) => {
      logger.error("new nudging state notification error: {}", e);
    });
  }

  // private initialiseSharedMemory(wasmModule: WebAssembly.Module) {
  //   const imageBufferSize = MAX_PROCESSING_DIMENSION * MAX_PROCESSING_DIMENSION * 4;
  //   const wasm: any = wasmModule;
  //   this.mappedMemoryBuffer = wasm._malloc(imageBufferSize);
  //   this.mappedArray = new Uint8ClampedArray(wasm.HEAPU8.buffer, this.mappedMemoryBuffer, imageBufferSize);
  //   logger.debug("initialized memory for wasm module: {} bytes", imageBufferSize);
  // }

  private initialiseSharedMemory(wasmModule: WebAssembly.Module, maxWidth: number, maxHeight: number) {
    const imageBufferSize = maxWidth * maxHeight * 4;
    const wasm: any = wasmModule;

    // TODO: check if this works as intented
    // This should not be needed anyway... but since "malloc" was called, maybe also "free" is needed
    if (mappedMemoryBuffer) {
      wasm._free(mappedMemoryBuffer);
    }

    mappedMemoryBuffer = wasm._malloc(imageBufferSize);
    this.mappedArray = new Uint8ClampedArray(wasm.HEAPU8.buffer, mappedMemoryBuffer, imageBufferSize);
    logger.debug("initialized memory for wasm module: {} bytes", imageBufferSize);
  }

  private checkPoseSanity(pose: Float32Array, options: TrackingOptions): Float32Array {
    const isPoseValid = PoseHelper.checkPoseSanity(
      pose, options, this.verticalFocalLength, this.processingW, this.processingH);
    return (isPoseValid) ? pose : undefined;
  }

  private getVerticalFocalLength = () => {
    // we have been using a 45 degree FOV for the cropped central
    // part of the image (480x640), scaled down from the cropped larger image.
    // For landscape images, we use 60degrees FOV
    /* if (this.processingW > this.processingH) {
      return 0.5 * this.processingW / Math.tan(0.5 * this.FOV_LANDSCAPE);
    } */
    return 0.5 * this.processingH / Math.tan(0.5 * this.FOV_PORTRAIT);
  }
}

export { PoseResponse, PoseTracker };
export { GuidanceCommand, StandstillStatus, NudgingType, FormattedPose, TrackingOptions, TrackResult };
export { WasmCapabilities, WasmEnvironment };
