import { CaptureOptions } from '../interfaces/CaptureParameters';
import { DefaultTrackingOptions } from './DefaultTrackingOptions';

const DefaultCaptureOptions: CaptureOptions = {
  enableCountdown: true,
  countdownDuration: 3000,
  countdownDelay: 1000,
  tracking: DefaultTrackingOptions,
  showLoading: true,
  enableMachineLearning: false,
  enableVto: true,
};

export { DefaultCaptureOptions };
