import * as t from 'io-ts'

import { Product, ProductsResData } from './products'
import {
  DefaultProductTypeDecoder,
  defaultTryOnPageDecoder,
  ProductTypeDecoder,
  ProductTypeMapDecoder,
} from './filters'

import { SessionData } from './session'
import { UserData } from './user'
import { optional } from '@root/utils/types'

export const RegionDecoder = t.union([t.literal('US'), t.literal('AU'), t.literal('EU')])

export type Region = t.TypeOf<typeof RegionDecoder>

export const FacescanPrivacyDecoder = t.type({
  /**  This url will be used by the BIPA/Privacy manager */
  privacyPolicy: t.string,

  /** this url will be used by the BIPA/Privacy manager */
  termsAndConditions: t.string,

  /** localization textes, see relevant guide section for more details */
  localizationText: optional(t.string),
})

export const ConfigCodec = t.type({
  /**
   * The CSS selector of the element in which the widget will be printed
   */
  selector: t.string,

  /**
   * startMinimized property is used to choose whether to start the app as a Floating Button
   */
  startMinimized: optional(t.boolean),

  /**
   * defaultTryOnPage property is used to choose the default try-on page to land on
   * when unset (undefined), the last TryOn mode page visited will be used
   */
  defaultTryOnPage: optional(defaultTryOnPageDecoder),

  /**
   * Initial Position of the Floating Button
   */
  initPositionFloatingBtn: optional(
    t.type({
      x: t.number,
      y: t.number,
    }),
  ),

  /**
   * This property is used to set a color for the loader animation. Should be set in HEX format. Ex: #000000. Do not cut to #000.
   */
  loaderAnimationColor: optional(t.string),

  /**
   * The language of the widget (if not present, there is a fallback on English)
   *
   * @default: en-US
   */
  locale: t.string,

  /**
   * Multibrand property is used to detect whether the store is multibrand or not
   * by default is false
   */
  multibrand: optional(t.boolean),

  /**
   * The source of the facescan integration
   *
   * Possible values:
   * - FASA_RBCOM
   * - FASA_OOCOM
   * - FASA_SGHCOM
   * - FASA_LCCOM
   * - FASA_GLASSESCOM
   * - FASA_COSTACOM
   *
   */
  facescanSource: t.string,

  /**
   * The region of the e-commerce integration - used by facescan app
   *
   * Possible values:
   * - US
   * - EU
   * - AU
   *
   */
  facescanRegion: RegionDecoder,

  /**
   * Privacy policy propertires used by facescan app
   */
  facescanPrivacy: FacescanPrivacyDecoder,

  /**
   * The core to be used by frame adv API
   *
   */
  frameAdvAPICore: t.string,

  /**
   * The key of Virtual Mirror Component
   *
   */
  frameAdvKey: t.string,

  /**
   * The store to be used by frame adv API
   *
   */
  frameAdvAPIStore: t.string,

  resultCallback: t.Function,
  saveProfileDataCallback: t.Function,
  getProfileDataCallback: t.Function,
  openRXConfigurator: t.Function,
  addToBagCallback: t.Function,

  /**
   * The absolute path of the language file in json format
   *
   */
  overrideTranslationsUrl: optional(t.string),

  /**
   * This property is used to set the selected mood on PLP
   * By default it is 'harmony'
   */
  defaultMood: optional(t.union([t.literal('harmony'), t.literal('contrast')])),

  /**
   * This property is used to set the selected productType on PLP
   * By default it is 'sunglass'
   */
  defaultProductType: optional(DefaultProductTypeDecoder),

  /**
   * This property is used to remap the product types sunglass/eyeglass
   * eg:.
   * {
   *   sunglass: 'eyeware',
   *   eyeglass: 'glasses',
   * }
   */
  productTypesMap: optional(t.record(ProductTypeMapDecoder, t.string)),

  /**
   * Customize this property to choose which collections will be available.
   * The possible values are:
   * - sunglass
   * - eyeglass
   *
   * default: ['sunglass', 'eyeglass']
   *
   * For instance set this property to `collections: ['sunglasses']`
   * in order to hide the selection of the collection on the survey step
   * and hide the filter on PLP
   */
  productTypes: optional(t.array(ProductTypeDecoder)),

  /**
   * This property is used to set row limit on api for request product data.
   * by default is 24. Valid values: > 0 <= 50
   */
  productRequestRowsLimit: optional(t.number),

  saveSessionData: optional(t.Function),
  getSessionData: optional(t.Function),

  /**
   * This property is used to show or hide the size advisor functionality.
   * by default is true
   */
  enableSizeAdvisor: optional(t.boolean),

  /**
   * This property is used to show or hide the Video Mode functionality.
   * by default is true
   */
  enableVideoMode: optional(t.boolean),

  /**
   * This property is used to close PDP while adding product to bag.
   * by default is false
   */
  closePDPonAddToBag: optional(t.boolean),
})

type ConfigCallbacks = {
  /**
   * The callback called at the end of the survey process
   */
  resultCallback: (products: ProductsResData) => Promise<Product[]>
  /**
   * This function could be used to customize the way where the session is saved
   */
  saveSessionData?: (data: SessionData) => void

  /**
   * This function could be used to customize the way to retrieve the session data
   */
  getSessionData?: () => SessionData

  /**
   */
  playlistTypes?: 'sunglass' | 'eyeglass'

  /** This function is used to save the user profie information outside the app */
  saveProfileDataCallback: (data: UserData) => Promise<void>

  /** This function is used to retrieve the user profie information outside the app */
  getProfileDataCallback: () => Promise<UserData>

  /** This function is used to reset the user profie information outside the app */
  resetProfileDataCallback: (userId: string) => Promise<void>

  /** This function is used to open the RX configurator from the PDP */
  openRXConfigurator: (product: Product) => void

  /** Callback to add a product to the cart */
  addToBagCallback: (product: Product) => void
}

export type Config = t.TypeOf<typeof ConfigCodec>

export type ConfigParams = Omit<Config, 'selector'>

export type ConfigWithCallbacks = Config & ConfigCallbacks
