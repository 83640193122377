import { AppViewSessionConsumerJob } from "./AppViewSessionConsumerJob";
import { BrowserSessionConsumerJob } from "./BrowserSessionConsumerJob";
import { RemoteLogConsumerJob } from "./RemoteLogConsumerJob";
import { TrackingStatusConsumerJob } from "./TrackingStatusConsumerJob";

class JobsStarter {

  public static getInstance() {
    if (!JobsStarter.instance) {
      JobsStarter.instance = new JobsStarter();
    }
    return JobsStarter.instance;
  }

  private static instance: JobsStarter;

  public constructor() {
    this.createJobs();
  }

  private createJobs() {
    BrowserSessionConsumerJob.getInstance().start();
    AppViewSessionConsumerJob.getInstance().start();
    TrackingStatusConsumerJob.getInstance().start();
    RemoteLogConsumerJob.getInstance().start();
  }

}

export { JobsStarter };
