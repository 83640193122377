import { BipaPrivacyOptions } from '@luxottica/bipa-manager';
import { MicroserviceEnvironment } from '@luxottica/vto-microservices';
import { FrameAdvisorConfiguration } from '../interfaces/Config';
import { FrameAdvisorRegion } from '../interfaces/InitializeParameters';

let CONFIG: FrameAdvisorConfiguration;

const setConfig = (
  env: MicroserviceEnvironment,
  region: FrameAdvisorRegion,
  source: string,
  locale: string,
  privacy: BipaPrivacyOptions,
  tokenVersion: 1 | 2
) => {
  CONFIG = {
    environment: env,
    region,
    source,
    locale,
    privacy,
    tokenVersion
  };
};

export {
  CONFIG,
  setConfig
};
