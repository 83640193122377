import { AnalyticsHelper } from '../../helpers/AnalyticsHelper';
import { MirrorActions } from '../../reducers/mirrorReducer';
import { createLogger, sendAppViewSession } from '../../remotelog/VmLogger';
import { vmIsInitialized, VmStore } from '../VmCore';

const logger = createLogger('FitMeBetter');

const fitMeBetter = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      if (vmIsInitialized() && VmStore.getState().mirror.mirrorStatus === 'TRACKING') {
        AnalyticsHelper.onAdjustGlasses(
          VmStore.getState().mirror.glasses.upc,
          VmStore.getState().mirror.mirrorOptions.transitionColor
        );
        VmStore.dispatch(MirrorActions.setMirrorStatus('RESET'));
        sendAppViewSession.fitMeBetter();
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      logger.error('resetting mirror failed: {}', e.message);
      sendAppViewSession.fitMeBetterError();
      reject();
    }
  });
};

export {
  fitMeBetter
};
