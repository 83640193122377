import localforage from "localforage";

const dbName = "db_remotelog";

class DBRemoteLogFactory {

  public static getInstance() {
    if (!DBRemoteLogFactory.instance) {
      DBRemoteLogFactory.instance = new DBRemoteLogFactory();
    }
    return DBRemoteLogFactory.instance;
  }

  private static instance: DBRemoteLogFactory;
  private db: LocalForage;

  private constructor() {
    this.db = localforage.createInstance({ name: dbName });
  }

  public getDB(): LocalForage {
    return this.db;
  }

  public getLogsTableName() {
    return "logs";
  }

}

export { DBRemoteLogFactory };
