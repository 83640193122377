class FpsCounter {
  private fpsQueue: number[];
  private processStartTime: number;
  public static fpsQueueLength = 30;

  constructor() {
    this.fpsQueue = new Array<number>(FpsCounter.fpsQueueLength).fill(0);
  }

  public get fps() {
    return this.fpsQueue.reduce((sum, value) => sum + value) / this.fpsQueue.length;
  }

  private static calcFps(t0, t1): number {
    return (!(t0 && t1)) ? 0 : 1 / ((t1 - t0) / 1000);
  }

  public tick() {
    this.fpsQueue.shift();
    this.fpsQueue.push(FpsCounter.calcFps(this.processStartTime, performance.now()));
    this.processStartTime = performance.now();
  }
}

export { FpsCounter };
