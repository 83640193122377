import React from 'react';
import { LocalizationHelper } from '../../helpers/LocalizationHelper';
import './VtoCaptureErrorOverlay.scss';

const VtoCaptureErrorOverlay = (props: {
  enabled: boolean,
  onClickOk: () => void
}) => {

  return (
    <div className={`vto-capture-error-overlay${props.enabled ? ' active' : ''}`}>
      {props.enabled &&
      <div className='vto-capture-error-overlay-modal'>
        <div className='vto-capture-error-overlay-modal-header'>{LocalizationHelper.getString('CAPTURE_ERROR', 'ERROR')}</div>
        <div className='vto-capture-error-overlay-modal-text'>{LocalizationHelper.getString('CAPTURE_ERROR', 'SUBTEXT')}</div>
        <button className='vto-capture-error-overlay-modal-button' onClick={props.onClickOk}>
          {LocalizationHelper.getString('CAPTURE_ERROR', 'BUTTON')}
        </button>
      </div>
      }
    </div>
  );
};

export {
  VtoCaptureErrorOverlay
};
