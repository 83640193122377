import { NotInitializedError, OpacityError } from '../../errors/errors';
import { TransitionColorHelper } from '../../helpers/TransitionColorHelper';
import { vmIsInitialized } from '../VmCore';

const setTransitionOpacity = (opacity = 0): void => {
  if (!vmIsInitialized()) {
    throw new NotInitializedError();
  }
  
  if (opacity !== undefined) {
    if (typeof opacity !== 'number' || opacity < 0 || opacity > 1) {
      const error = new OpacityError('opacity must be a number between 0 and 1');
      throw error;
    }
    TransitionColorHelper.setCurrentLensOpacity(opacity);

  } else {
    const error = new OpacityError('opacity cannot be set when mirror is not initialized');
    throw error;
  }
};

export {
  setTransitionOpacity
};
