class StringHelper {

  public static radToDegK = (180 / Math.PI);

  public static radToDeg(value: number, trailingDigits = 2): string {
    return (value * StringHelper.radToDegK).toFixed(trailingDigits);
  }
}

export default StringHelper;
