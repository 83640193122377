import { PreloadedState, configureStore } from '@reduxjs/toolkit'

import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import app from '@root/store/app/slice'
import { cmsApi } from '@root/services/cms'
import { colorPaletteApi } from '@root/services/colorPalette'
import playlist from '@root/store/playlist/slice'
import { profileMediaApi } from '@root/services/profileMedia'
import { rtkQueryErrorLogger } from './errorCatchingMiddleware'
import { searchApi } from '@root/services/search'
import { setupListeners } from '@reduxjs/toolkit/query'
import { sizeAdvisorApi } from '@root/services/sizeAdvisor'
import user from '@root/store/user/slice'
import { userApi } from '@root/services/user'

export const reducer = {
  app,
  user,
  playlist,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [profileMediaApi.reducerPath]: profileMediaApi.reducer,
  [colorPaletteApi.reducerPath]: colorPaletteApi.reducer,
  [sizeAdvisorApi.reducerPath]: sizeAdvisorApi.reducer,
}

export const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false, // XXX: removed check because config.resultCallback is a promise so, is not serializable (AFAIK)
  }).concat([
    cmsApi.middleware,
    userApi.middleware,
    searchApi.middleware,
    profileMediaApi.middleware,
    colorPaletteApi.middleware,
    sizeAdvisorApi.middleware,
    rtkQueryErrorLogger,
  ])

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => middleware(getDefaultMiddleware),
})

setupListeners(store.dispatch)

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const getStore = (preloadedState: PreloadedState<RootState>) =>
  configureStore({
    reducer,
    middleware,
    preloadedState,
  })
