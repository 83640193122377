enum UploadStatus {
  INITIAL = 'INITIAL',
  ACCEPT = 'ACCEPT',
  ACCEPT_THIRD_PARTY = 'ACCEPT_THIRD_PARTY',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR'
}

export {
  UploadStatus,
};
