import ReactDOM from 'react-dom';
import { UnmountComponentError } from '../../errors/errors';
import { AdobeHelper } from '../../helpers/AdobeHelper';
import { AnalyticsHelper } from '../../helpers/AnalyticsHelper';
import { AppActions } from '../../reducers/appReducer';
import { createLogger, sendAppViewSession } from '../../remotelog/VmLogger';
import { VmStore } from '../VmCore';

const logger = createLogger('CloseMirror');

const resetLoadingStatus = () => {
  VmStore.dispatch(AppActions.setLoadingStatus({ type: 'WEBCAM_ACCESS', status: false }));
  VmStore.dispatch(AppActions.setLoadingStatus({ type: 'RENDERING', status: false }));
  VmStore.dispatch(AppActions.setLoadingStatus({ type: 'POSETRACKER', status: false }));
};

// we return a promise because it's easy to keep this backwards compatibility here
// but sneaky on client code
const closeMirror = (target: string): Promise<void> => {
  try {
    // reset all loading statuses, this will display the loading screen again on a new VM instance
    resetLoadingStatus();
    AnalyticsHelper.onCloseMirror();
    logger.debug('closing mirror for target div {}', target);
    const container = document.getElementById(target);

    if (ReactDOM.unmountComponentAtNode(container)) {
      logger.debug('closed mirror for target div {}', target);
      sendAppViewSession.close(target);

    } else {
      logger.debug('closing mirror for target div {} failed', target);
      sendAppViewSession.closeError(target);
      const unmountComponentError = new UnmountComponentError(target);
      AdobeHelper.sendError(unmountComponentError);
      return Promise.reject(unmountComponentError);
    }
    return Promise.resolve();
  } catch (e) {
    logger.debug('closing mirror for target div {} failed: {}', target, e.message);
    const unmountComponentError = new UnmountComponentError(target);
    try {
      AdobeHelper.sendError(unmountComponentError);
    } catch(e) {
      return Promise.reject(e);
    }
    return Promise.reject(unmountComponentError);
  }
};

export {
  closeMirror
};
