
class WasmDetection {

  public static getInstance() {
    if (!WasmDetection.instance) {
      WasmDetection.instance = new WasmDetection();
    }
    return WasmDetection.instance;
  }

  private static instance: WasmDetection;

  private simplewasm = Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00);

  public isWebAssemblySupported(): boolean {
    let supported = false;
    try {
      if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
        const module = new WebAssembly.Module(this.simplewasm);
        supported = module instanceof WebAssembly.Module
          && new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
    } catch (e) {
      supported = false;
    }
    return supported;
  }

}

export { WasmDetection };
