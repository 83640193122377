import { DeviceDetection } from "@luxottica/vm-devicedetection";
import { name, version } from "../../../package.json";
import { IRemoteBrowserSession } from "../../domain/model/IRemoteBrowserSession";
import { RemoteLogConfig } from "../../domain/RemoteLogConfig";
import { VmAnalyticsApiConfig } from "../../domain/service/config/VmAnalyticsApiConfig";
import { sendXhrRequest } from "../../helpers/RequestHelper";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "BrowserSessionAdapter");

const VM_BROWSERSESSION_FLAG = RemoteLogConfig.storageIdentifier + ".flag";
const VM_BROWSERSESSION_ETAG = RemoteLogConfig.storageIdentifier + ".etag";

class BrowserSessionAdapter implements LogAdapter<IRemoteBrowserSession> {

  private config = new VmAnalyticsApiConfig();
  private storage = window.localStorage;

  public log(message: IRemoteBrowserSession): Promise<boolean> {
    const deviceDetection: DeviceDetection = new DeviceDetection();
    const userAgentData = deviceDetection.getUserAgentData();
    const persistedSessionFlag = this.storage.getItem(VM_BROWSERSESSION_FLAG);
    const persistedSessionUuid = RemoteLogConfig.getInstance().currentUuid;

    if (persistedSessionFlag) {
      logger.info("skip (session id already created)");
      return Promise.resolve(false);
    } else {
      if (persistedSessionUuid) {
        const jsonBody = JSON.stringify({
          browserSessionUuid: persistedSessionUuid,
          date: message.date,
          device: {
            cpuArchitecture: userAgentData.cpu.architecture,
            model: userAgentData.device.model,
            resolution: {
              dpi: message.deviceResolution.dpi,
              h: message.deviceResolution.h,
              w: message.deviceResolution.w,
            },
            type: userAgentData.device.type,
            vendor: userAgentData.device.vendor,
            webapi: {
              ambientLight: "ondevicelight" in window,
              connection: "connection" in navigator,
              serviceWorker: "serviceWorker" in navigator,
              share: "share" in navigator,
              visibility: "hidden" in document,
            },
            webcam: {
              available: message.deviceWebcam.enabled,
              resolutionW: message.deviceWebcam.resolution,
            },
            webgl: {
              version: message.deviceWebgl.version,
            },
          },
          os: {
            name: userAgentData.os.name,
            version: parseFloat(userAgentData.os.version!),
          },
          userAgent: {
            browserEngineName: userAgentData.engine.name,
            browserEngineVersion: parseFloat(userAgentData.engine.version!),
            browserName: userAgentData.browser.name,
            browserVersion: parseFloat(userAgentData.browser.version!),
            webView: deviceDetection.isWebView(),
          },
        });

        const requestUrl = this.config.browserSessionApiEndpoint;
        return sendXhrRequest(
          "POST",
          requestUrl,
          jsonBody,
          "",
          ["etag"],
          {
            "Accept": "application/json",
            "Content-type": "application/json",
            // "Referrer-Policy": "unsafe-url"
          },
        ).then((response) => {
          const etagsessionId = response.headers.etag;
          this.storage.setItem(VM_BROWSERSESSION_FLAG, "true");
          this.storage.setItem(VM_BROWSERSESSION_ETAG, etagsessionId ? etagsessionId.slice(-1, 1) : "n.a.");
          return true;
        }).catch((e) => {
          return Promise.reject(e);
        });

      } else {
        logger.warn("persistent session uuid not available");
        return Promise.reject("persistent session uuid not available");
      }
    }
  }

}

export { BrowserSessionAdapter };
