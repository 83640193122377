import { loginRequest, LoginReturn, processImageRequest, processVideoRequest, ProfileReturn, sessionRequest, SessionReturn, uploadImageDataRequest, uploadImageRequest, uploadVideoRequest } from '@luxottica/vto-microservices';
import { getAuthData } from './AuthHelper';
import { CallbackHelper } from './CallbackHelper';
import { CONFIG } from './ConfigHelper';
import { RecordingHelper } from './RecordingHelper';

let videoId: string;
let uploadPath: string;

const RequestHelper = {
  login: (tokenVersion: 1 | 2): Promise<LoginReturn> => {
    return loginRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      version: tokenVersion,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
    });
  },

  startSession: (): Promise<SessionReturn> => {
    return sessionRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      region: CONFIG.region,
      source: CONFIG.source
    }).then((result) => {
      videoId = result.videoId;
      uploadPath = result.uploadPath;
      return result;
    });
  },

  uploadImage: (
    img: Blob
  ): Promise<void> => {
    return uploadImageRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      img: img,
      uploadPath: uploadPath
    });
  },

  uploadImageData: (
    imgData: ImageData
  ): Promise<void> => {
    return uploadImageDataRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      imgData: imgData,
      uploadPath: uploadPath
    });
  },

  processImage: (
    enableMachineLearning: boolean,
    enableVto: boolean,
    shouldCancel: () => boolean
  ): Promise<ProfileReturn> => {
    return processImageRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      videoId: videoId,
      enableMachineLearning: enableMachineLearning,
      enableVto: enableVto,
      shouldCancel: shouldCancel
    });
  },

  uploadVideo: (
    video: Blob[]
  ): Promise<void> => {
    return uploadVideoRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      videoId: videoId,
      video: video,
      fileType: RecordingHelper.getFileType(),
      uploadPath: uploadPath,
    });
  },

  processVideo: (
    enableMachineLearning: boolean,
    enableVto: boolean,
    shouldCancel: () => boolean
  ): Promise<ProfileReturn> => {
    return processVideoRequest({
      environment: CONFIG.environment,
      accessToken: getAuthData().accessToken,
      refreshToken: getAuthData().refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated,
      videoId: videoId,
      enableMachineLearning: enableMachineLearning,
      enableVto: enableVto,
      shouldCancel: shouldCancel
    });
  }
};

export {
  RequestHelper,
};
