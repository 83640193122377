import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../../package.json';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { RequestHelper } from '../../helpers/RequestHelper';
import { UploadOptions } from '../../interfaces/CaptureParameters';
import './UploadImage.scss';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'UploadImageData');

const uploadImageData = (
  imageData: ImageData,
  options: UploadOptions
): Promise<void> => {
  if (!!imageData) {
    logger.debug('imageData received');

    return RequestHelper.startSession().then(() => {
      RequestHelper.uploadImageData(imageData).then(() => {
        return RequestHelper.processImage(
          options.enableMachineLearning,
          options.enableVto,
          () => { return false; }
        );
      }).then((result) => {
        logger.debug('upload imageData complete');
        CallbackHelper.onAdvisorResult({
          ...(options.enableMachineLearning && { properties: result.properties }),
          videoId: result.videoId
        });
      }).catch((error) => {
        CallbackHelper.onCaptureError(error);
      });
    });
  } else {
    logger.warn('invalid imageData, aborting upload process');
  }
};

export {
  uploadImageData,
};
