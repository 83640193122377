import React from 'react';
import ReactDOM from 'react-dom';
import { ROOT_STYLE_NAME } from '../../constants/Styling';
import { VtoCaptureOptions } from '../../interfaces/CaptureParameters';
import { VtoCaptureContainer } from './VtoCaptureContainer';

let currentContainer;

const loadVtoCapture = (
  container: HTMLElement,
  options: VtoCaptureOptions,
  onMount: () => void
) => {
  currentContainer = container;
  currentContainer.classList.add(ROOT_STYLE_NAME);
  ReactDOM.render(
    <VtoCaptureContainer options={options}/>,
    container,
    onMount
  );
};

const closeVtoCaptureComponent = () => {
  if (!!currentContainer) {
    ReactDOM.unmountComponentAtNode(currentContainer);
  }
};

export {
  loadVtoCapture,
  closeVtoCaptureComponent
};
