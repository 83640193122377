import { combineReducers } from 'redux';
import { RootState } from '../Store';
import { AppReducer } from './appReducer';
import { MirrorReducer } from './mirrorReducer';
import { UserReducer } from './userReducer';

export default combineReducers<RootState>({
  app: AppReducer,
  mirror: MirrorReducer,
  user: UserReducer,
});
