import { name, version } from "../../../../../package.json";
import { DBTrackingStatusFactory } from "../../../../adapter/db/factory/DBTrackingStatusFactory";
import { TrackingStatusAdapter } from "../../../../adapter/rest/TrackingStatusAdapter";
import { IRemoteTrackingStatus } from "../../../model/IRemoteTrackingStatus.js";
import { RemoteLogConfig } from "../../../RemoteLogConfig";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "TrackingStatusConsumer");

const dbInstance = () => {
  return DBTrackingStatusFactory.getInstance();
};

const messageAdapter = () => {
  return new TrackingStatusAdapter();
};

const consumeDbRows = () => {
  const dbase = dbInstance().getDB();

  dbase.iterate((
    value: IRemoteTrackingStatus,
    key: string
  ) => {
    // if (value === null) {
    //   console.error("undefined: ", { key });
    // }
    messageAdapter().log({
      fps: value.fps,
      trackingCode: value.trackingCode,
      trackingText: value.trackingText,
    }).then(() => {
      logger.debug("consumed stored log message: {}", JSON.stringify(value));
      // console.info("consumed: ", {key, value});
      return dbase.removeItem(key);
    }).catch(() => {
      // console.error("failed: ", { key });
    });
  }).then(() => {
    logger.debug("all logs consumed from local indexdb");
  });
};

class TrackingStatusConsumer {

  public consume() {
    const time1 = window.performance.now();
    consumeDbRows();
    const time2 = window.performance.now();
    logger.debug("logs consumed in {} ms", time2 - time1);
  }

}

export { TrackingStatusConsumer };
