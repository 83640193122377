import { UpcAvailability } from '../../interfaces/UpcAvailability';

export const notAvailableResponse = (upc: string): UpcAvailability => {
  return {
    upc: upc,
    available: { 256: false, 512: false },
    transitions: { 256: false, 512: false },
    lods: [],
    glassesType: undefined,
    getUpc: () => upc,
    isAvailable: () => false,
    isTransition: () => false,
  };
};
