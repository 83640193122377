import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../package.json';
import { InitializationOptions } from '../interfaces/InitializationTypes';
import { SupportedFeatures } from '../interfaces/SupportedFeatures';
import { AppViewSessionKeys } from './AppViewSessionKeys';

const vmRemoteLog = new RemoteLog();

const createLogger = (loggerName: string) => {
  return RemoteLogConfig.getInstance().getLoggerInfo(name, version, loggerName);
};

const sendBrowserSession = (supportedFeatures: SupportedFeatures) => {
  vmRemoteLog.sendBrowserSession({
    deviceResolution: {
      dpi: 0,
      h: window.screen.height,
      w: window.screen.width,
    },
    deviceWebcam: {
      enabled: supportedFeatures.webcamAccess,
      resolution: 0,
    },
    deviceWebgl: { version: 2 },
  });
};

const sendError = (e) => {
  vmRemoteLog.sendAppViewSession({
    statusCode: '99-error',
    statusText: e.message
  });
};

const sendAppViewSession = {
  initialize: (options: InitializationOptions) => {
    vmRemoteLog.sendAppViewSession(
      {
        statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_INIT_BRAND,
        statusText: options.brand,
      },
      {
        statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_INIT_CHANNEL,
        statusText: options.channel,
      },
      {
        statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_INIT_LOCALE,
        statusText: options.locale,
      },
    );
  },

  initializeError: (options: InitializationOptions) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_KO + AppViewSessionKeys.VM_INIT,
      statusText: options.channel,
    });
  },

  upc: (upc: string) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_UPC_AVAILABILITY,
      statusText: upc,
    });
  },

  upcError: (upc: string) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_KO + AppViewSessionKeys.VM_UPC_AVAILABILITY,
      statusText: upc,
    });
  },
  
  orientationChange: (landscape: boolean) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: 'orientation change',
      statusText: landscape ? 'landscape' : 'portrait'
    });
  },

  fitMeBetter: () => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_FITMEBETTER,
      statusText: '',
    });
  },

  fitMeBetterError: () => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_KO + AppViewSessionKeys.VM_FITMEBETTER,
      statusText: '',
    });
  },

  close: (target: string) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_OK + AppViewSessionKeys.VM_CLOSE,
      statusText: target,
    });
  },

  closeError: (target: string) => {
    vmRemoteLog.sendAppViewSession({
      statusCode: AppViewSessionKeys.VM_KO + AppViewSessionKeys.VM_CLOSE,
      statusText: target,
    });
  },
};

export {
  createLogger,
  sendBrowserSession,
  sendError,
  sendAppViewSession
};