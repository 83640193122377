export enum GuidanceCommand {
  NONE = 7,
  UNKNOWN = 8,
  MOVE_CENTER = 9,
  MOVE_CLOSER = 10,
  MOVE_FARTHER = 11,
  MOVE_UP = 12,
  MOVE_RIGHT = 13,
  MOVE_DOWN = 14,
  MOVE_LEFT = 15,
  LOOK_UP = 16,
  LOOK_RIGHT = 17,
  LOOK_DOWN = 18,
  LOOK_LEFT = 19,
  TILT_LEFT = 20,
  TILT_RIGHT = 21,
  STAY_STILL = 22
}
