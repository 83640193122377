enum Environment {
  LOCAL = "LOCAL",
  PROD = "PROD",
  TEST = "TEST",
}

const hostname = {
  LOCAL: "http://localhost:5000",
  PROD: "https://vmcore.luxottica.com",
  TEST: "https://vmcore-test.luxottica.com",
};

const getHostname = (env: Environment) => {
  return hostname[env];
};

export {
  Environment,
  getHostname,
};
