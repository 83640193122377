import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../package.json";
import { GuidanceCommand } from "../constants/GuidanceCommand";
import { NudgingType } from "../constants/NudgingType.js";
import { StandstillStatus } from "../constants/StandstillStatus";
import { TrackResult } from "../constants/TrackResult";
import { TrackingOptions } from "../PoseTracker";
import { Timer } from "../utils/Timer.js";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "GuidanceEngine");

declare interface StandstillOptions {
  timeLimit: number;
  positionVariance: number;
  axisVariance: number;
  depthRange: number[];
}

class GuidanceEngine {

  public nudgingType: NudgingType;
  public oldTrackResult: TrackResult;
  public guidanceStatus: GuidanceCommand;
  public standstillStatus: StandstillStatus;
  public renderDelay: boolean = false;
  public shouldReset: boolean = false;

  private pendingFrameCounter = 0;
  private pendingFrameLimit = 100;

  private standstillTimer: Timer;
  private standstillOptions: StandstillOptions;

  constructor(
    options: TrackingOptions
  ) {
    this.standstillTimer = new Timer();
    this.guidanceStatus = GuidanceCommand.NONE;
    this.standstillStatus = StandstillStatus.ON;
    this.updateTrackingOptions(options);
  }

  public updateTrackingOptions(options: TrackingOptions): any {
    this.nudgingType = options.nudgingType;
    this.standstillOptions = {
      timeLimit: options.standstill.timeLimit,
      positionVariance: options.standstill.positionVariance,
      axisVariance: options.standstill.axisVariance * (Math.PI / 180),
      depthRange: options.standstill.depthRange
    };
  }
  public addPose(pose: Float32Array, status: TrackResult, imageData: ImageData) {
    const oldGuidanceStatus = this.guidanceStatus;
    const oldStandstillStatus = this.standstillStatus;
    this.renderDelay = false;

    if (this.nudgingType === NudgingType.OFF) {
      this.updateDisabledGuidance(pose, status);
    } else if (this.nudgingType === NudgingType.ON) {
      this.updateGuidance(pose, status);
      this.updateDisabledStandstill(pose, status);
    } else {
      this.updateGuidance(pose, status);
      this.updateStandstill(pose, status);
    }

    if (this.oldTrackResult !== status
      || oldGuidanceStatus !== this.guidanceStatus
      || oldStandstillStatus !== this.standstillStatus) {
      logger.debug(`new pose tracker status ${
        JSON.stringify({
          wasm: TrackResult[status],
          guidance: GuidanceCommand[this.guidanceStatus],
          standstill: StandstillStatus[this.standstillStatus]
        })
        }`);
    }

    this.oldTrackResult = status;
  }

  private updateGuidance(
    pose: Float32Array,
    status: TrackResult
  ): void {

    let guidanceCommand: GuidanceCommand;

    switch (status) {
      case TrackResult.OK:
      case TrackResult.TrackerStopped:
      case TrackResult.LostWillTryRecovery:
        guidanceCommand = GuidanceCommand.NONE;
        break;
      case TrackResult.NoFaceFound:
      case TrackResult.FaceTooSmallDetected:
        guidanceCommand = GuidanceCommand.UNKNOWN;
        break;
      case TrackResult.LostWillReset:
      case TrackResult.FaceDetectedWaitForMoreSamples:
      case TrackResult.AnglesOutOfRange:
        guidanceCommand = GuidanceCommand.MOVE_CENTER;
        break;
      case TrackResult.FaceFittingTooNarrow:
        guidanceCommand = GuidanceCommand.MOVE_CLOSER;
        break;
      default:
        guidanceCommand = GuidanceCommand.NONE;
        break;
    }

    this.guidanceStatus = guidanceCommand;
  }

  private updateDisabledGuidance(
    pose: Float32Array,
    status: TrackResult
  ): void {

    let guidanceCommand: GuidanceCommand;

    switch (status) {
      case TrackResult.OK:
      case TrackResult.TrackerStopped:
      case TrackResult.LostWillTryRecovery:
        guidanceCommand = GuidanceCommand.NONE;
        break;
      case TrackResult.NoFaceFound:
      case TrackResult.FaceTooSmallDetected:
      case TrackResult.LostWillReset:
      case TrackResult.FaceDetectedWaitForMoreSamples:
      case TrackResult.AnglesOutOfRange:
      case TrackResult.FaceFittingTooNarrow:
        guidanceCommand = GuidanceCommand.UNKNOWN;
        break;
      default:
        guidanceCommand = GuidanceCommand.NONE;
        break;
    }

    this.guidanceStatus = guidanceCommand;
    this.standstillStatus = (pose) ? StandstillStatus.ON : StandstillStatus.OFF;
  }

  private updateStandstill(
    pose: Float32Array,
    status: TrackResult
  ): void {

    if (!pose && status !== TrackResult.LostWillTryRecovery) {
      this.standstillStatus = StandstillStatus.ON;
      return;
    }

    switch (this.standstillStatus) {
      case StandstillStatus.ON:
        this.standstillTimer.reset();
        if (status === TrackResult.OK) {
          this.guidanceStatus = this.updateDirection(pose);
          this.standstillStatus = StandstillStatus.PENDING;
        }
        return;

      case StandstillStatus.PENDING:
        if (status !== TrackResult.OK) {
          this.standstillStatus = StandstillStatus.ON;
        } else {
          this.guidanceStatus = this.updateDirection(pose);

          if (this.guidanceStatus !== GuidanceCommand.STAY_STILL) {
            this.standstillTimer.reset();
            this.pendingFrameCounter++;
            if (this.pendingFrameCounter > this.pendingFrameLimit) {
              this.resetPoseTracker();
            }
          } else {
            this.standstillTimer.continue();
            this.pendingFrameCounter = 0;
            if (this.standstillTimer.currentDuration() > this.standstillOptions.timeLimit) {
              this.guidanceStatus = GuidanceCommand.NONE;
              this.standstillStatus = StandstillStatus.OFF;
              this.resetPoseTracker();
              this.renderDelay = true;
            }
          }
        }
        return;

      case StandstillStatus.OFF:
        if (status !== TrackResult.OK
          && status !== TrackResult.LostWillTryRecovery) {
          this.standstillStatus = StandstillStatus.ON;
        }
        return;
    }
  }

  private updateDisabledStandstill(
    pose: Float32Array,
    status: TrackResult
  ): void {
    if (status !== TrackResult.OK) {
      this.standstillStatus = StandstillStatus.ON;
      return;
    } else {
      if (this.standstillStatus === StandstillStatus.ON) {
        this.guidanceStatus = this.updateDirection(pose);
        if (this.guidanceStatus === GuidanceCommand.STAY_STILL) {
          this.guidanceStatus = GuidanceCommand.NONE;
          this.standstillStatus = StandstillStatus.OFF;
        } else {
          this.standstillStatus = StandstillStatus.ON;
        }
      } else {
        return;
      }
    }
  }

  private resetPoseTracker() {
    this.shouldReset = true;
    this.pendingFrameCounter = 0;
  }

  private updateDirection(
    pose: Float32Array
  ): GuidanceCommand {

    if (pose[3] < -this.standstillOptions.positionVariance) {
      return GuidanceCommand.MOVE_LEFT;
    } else if (pose[3] > this.standstillOptions.positionVariance) {
      return GuidanceCommand.MOVE_RIGHT;
    }

    if (pose[4] < -this.standstillOptions.positionVariance) {
      return GuidanceCommand.MOVE_DOWN;
    } else if (pose[4] > this.standstillOptions.positionVariance) {
      return GuidanceCommand.MOVE_UP;
    }

    if (pose[5] < this.standstillOptions.depthRange[0]) {
      return GuidanceCommand.MOVE_FARTHER;
    } else if (pose[5] > this.standstillOptions.depthRange[1]) {
      return GuidanceCommand.MOVE_CLOSER;
    }

    if (Math.abs(pose[0]) < this.standstillOptions.axisVariance
      && Math.abs(pose[1]) < this.standstillOptions.axisVariance
      && Math.abs(pose[2]) < this.standstillOptions.axisVariance) {
      return GuidanceCommand.STAY_STILL;
    } else {
      const poseAngles = [pose[0], pose[1], pose[2]];
      const max = Math.max(...poseAngles);
      const min = Math.min(...poseAngles);
      const maxAbs = (Math.abs(max) > Math.abs(min)) ? max : min;
      const maxIndex = poseAngles.indexOf(maxAbs);

      switch (maxIndex) {
        case 0:
          return (maxAbs > 0) ? GuidanceCommand.LOOK_UP : GuidanceCommand.LOOK_DOWN;
        case 1:
          return (maxAbs > 0) ? GuidanceCommand.LOOK_LEFT : GuidanceCommand.LOOK_RIGHT;
        case 2:
          return (maxAbs > 0) ? GuidanceCommand.TILT_RIGHT : GuidanceCommand.TILT_LEFT;
        default:
          return;
      }
    }
  }
}

export { GuidanceEngine, StandstillOptions };
