abstract class GenericError extends Error {

    private readonly type: string;

    public readonly genericError: boolean =  true;

    constructor(type: string, message: string) {
        super(`${type}: ${message}`);
        this.type = type;
        this.message = message;
        Object.setPrototypeOf(this, new.target.prototype);
    }

    public getType() {
        return this.type;
    }

}

export { GenericError };
