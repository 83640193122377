import { BaseProduct, SearchProductApi } from '@root/types/products'

import { ConfigWithCallbacks } from '@root/types/config'
import { SimpleProductType } from '@root/types/filters'

export const convertProductType = (value: string, config: ConfigWithCallbacks) => {
  const { productTypesMap } = config
  let productType = value.toUpperCase() === 'SUNGLASSES' ? 'sunglass' : 'eyeglass'
  if (productTypesMap) {
    productType = productTypesMap[value.toLowerCase() as SimpleProductType]
  }
  return productType
}

export const convertProductApi = (product: SearchProductApi): BaseProduct => ({
  moco: product.moco,
  model: product.moco.split('__')[0],
  upc: product.upc,
  badgeShape: product.badgeShape,
  badgeColor: product.badgeColor,
  badgeMatch: product.badgeMatch,
  frameAdvisorEvidences: product.frameAdvisorEvidences,
})
