import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../package.json';
import { AuthenticationOptions } from '../interfaces/InitializeParameters';

let access_token: string;
let refresh_token: string;

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'RequestHelper');

const getAuthData = (): AuthenticationOptions => {
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
  };
};

const setAuthData = (authData: Partial<AuthenticationOptions>) => {
  if (!!authData?.accessToken && !! authData?.refreshToken) {
    access_token = authData.accessToken;
    refresh_token = authData.refreshToken;
    logger.debug('authentication data has been updated');
  }
};

export {
  getAuthData,
  setAuthData,
};
