import { PreloadedState, configureStore } from '@reduxjs/toolkit'

import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import app from '@root/store/app/slice'
import { cmsApi } from '@root/services/cms'
import { rtkQueryErrorLogger } from './errorCatchingMiddleware'
import { setupListeners } from '@reduxjs/toolkit/query'
import { sizeAdvUserApi } from '@root/services/sizeAdvUser'
import { sizeAdvisorApi } from '@root/services/sizeAdvisor'
import userSizeAdv from '@root/store/sizeAdvisor/slice'
import sizeAdvisorInfo from '@root/store/sizeInfo/slice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const reducer = {
  app,
  userSizeAdv,
  sizeAdvisorInfo,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [sizeAdvisorApi.reducerPath]: sizeAdvisorApi.reducer,
  [sizeAdvUserApi.reducerPath]: sizeAdvUserApi.reducer,
}

export const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false, // XXX: removed check because config.resultCallback is a promise so, is not serializable (AFAIK)
  }).concat([
    cmsApi.middleware,
    sizeAdvisorApi.middleware,
    sizeAdvUserApi.middleware,
    rtkQueryErrorLogger,
  ])

const storeSizeAdv = configureStore({
  reducer,
  middleware: getDefaultMiddleware => middleware(getDefaultMiddleware),
})

setupListeners(storeSizeAdv.dispatch)

export default storeSizeAdv

export type RootState = ReturnType<typeof storeSizeAdv.getState>
export type AppDispatch = typeof storeSizeAdv.dispatch

export const getStore = (preloadedState: PreloadedState<RootState>) =>
  configureStore({
    reducer,
    middleware,
    preloadedState,
  })

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
