/* 
This has been written during the mocking phase, during the integration of
Luxottica's GTLF format. Probably will be useful with third parites glasses.
*/

export const gltfDefaults = {
  nosepad_type: 'integrated',
  fitting: 'standard',
  flare: 0.025,
};
