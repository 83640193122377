import { getConfig } from '../config/Config';
import { SessionRequestData, SessionResponse, SessionReturn } from '../interfaces/Session';
import { refreshRequest } from './RefreshRequest';

const sessionFetch = (
  request: SessionRequestData
): Promise<Response> => {

  const url = getConfig(request.environment, 'videoSessionUrl');
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.accessToken}`,
    },
    body: `{ "region": "${request.region}", "source": "${request.source}"}`
  });
};

const sessionRequest = (
  request: SessionRequestData
): Promise<SessionReturn> => {

  return sessionFetch(request).then((sessionResponse) => {
    if (sessionResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return sessionFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return sessionResponse;
    }
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().catch(() => {
        throw new Error(`${response.status}: ${response.statusText}`);
      }).then((json) => {
        throw new Error(JSON.stringify(json));
      });
    }
  }).then((json: SessionResponse) => {
    return {
      videoId: json.uuid,
      uploadPath: json.uploadBasePath,
    };
  });
};

export {
  sessionRequest
};