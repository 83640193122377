import {
  Age,
  DarkCircles,
  EyeColor,
  EyeMood,
  EyebrowsThickness,
  FaceLength,
  FaceShape,
  Gender,
  HairColor,
  SkinUndertones,
  SunMood,
  UserSearchInformation,
} from '@root/types/search'
import { BaseQuery, Id, QueryResult, Upc } from '@root/types/common'
import { ConfigWithCallbacks, Region } from '@root/types/config'
import { Favorite, FavoriteItem } from '@root/types/favorite'
import { GlassType, Product } from '@root/types/products'
import { delSessionInfo, getPictureId, getUserId, setUserId } from '@root/persistance'

import { UserProfileData } from '@root/types/profile'
import { createApi, skipToken } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '@root/utils/services'
import { useConfig } from '@root/Context'
import { useNavigate } from 'react-router'
import userActions from '@root/store/user/actions'
import { useDispatch } from 'react-redux'
import { VirtualMirror } from '@luxottica/virtual-mirror'
import { FrameAdvisorCapture } from '@luxottica/frame-advisor-capture'
import { extractErrorStatus } from '@root/utils/error'

type SearchInfoKey =
  | 'age'
  | 'darkCircles'
  | 'eyeColor'
  | 'eyeMood'
  | 'eyebrowsThickness'
  | 'faceLength'
  | 'faceShape'
  | 'gender'
  | 'glassesType'
  | 'hairColor'
  | 'skinUndertones'
  | 'sunMood'
  | 'glassesType'

type SearchInfoValue =
  | Gender
  | Age
  | SkinUndertones
  | FaceShape
  | FaceLength
  | HairColor
  | EyeColor
  | EyebrowsThickness
  | EyeMood
  | SunMood
  | DarkCircles
  | GlassType

const fetchUserStatus = async (userUuid: Id, baseQuery: BaseQuery) => {
  const response = (await baseQuery({
    url: `/user/${userUuid}/status`,
    method: 'GET',
  })) as QueryResult<{ progress: 'NOT_STARTED' | 'RUNNING' | 'OK' | 'KO'; type: string }[]>

  if (response.error) {
    return response
  }

  const ko = response.data?.filter(({ progress }) => progress === 'KO')
  const error = ko.length
    ? {
        status: 500,
        data: {
          error: `getUserStatus returned ${ko.length} KO: ${ko.map(({ type }) => type).join(', ')}`,
        },
      }
    : undefined

  return error
    ? { error, data: undefined }
    : { data: response.data.every(({ progress }) => progress === 'OK'), error: undefined }
}

const pollUserStatus = async (
  userUuid: Id,
  baseQuery: BaseQuery,
  pollingStartTimestamp?: number,
): Promise<QueryResult<boolean>> => {
  const response = await fetchUserStatus(userUuid, baseQuery)
  const pollingTimedOut = !!pollingStartTimestamp && Date.now() - pollingStartTimestamp > 60000
  if (!response.error && !response.data && !pollingTimedOut) {
    return new Promise(res => {
      setTimeout(() => {
        res(pollUserStatus(userUuid, baseQuery, pollingStartTimestamp || Date.now()))
      }, 750)
    })
  }

  if (pollingTimedOut) {
    return {
      error: {
        status: 500,
        data: { error: 'getUserStatus polling timed out with no valid response' },
      },
    }
  }

  return response
}

const TAG = 'userSearchInformation'
const TAG_PROFILE = 'UserSaveProfile'
const TAG_MEDIA = 'Media'
const FAVOURITE_TAG = 'myFavourites'

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: [TAG, FAVOURITE_TAG, TAG_PROFILE, TAG_MEDIA],
  baseQuery: staggeredBaseQueryWithBailOut(
    process.env.REACT_APP_MS_URL ||
      'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtoecommerceprofilems/mock/v1',
  ),
  endpoints: builder => ({
    getUserUuid: builder.query<
      {
        uuid: Id
      },
      { channel: string; region: Region }
    >({
      queryFn: async (body, _, __, baseQuery) => {
        const userUuidResponse = (await baseQuery({
          url: '/user',
          method: 'POST',
          body,
        })) as QueryResult<{ uuid: string }>

        if (userUuidResponse.error) {
          return userUuidResponse
        }

        const userId = userUuidResponse.data.uuid
        setUserId(userId)

        return userUuidResponse
      },
    }),

    getUserInfo: builder.query<UserProfileData, Id>({
      queryFn: async (userUuid, _, __, baseQuery) => {
        const statusResponse = await pollUserStatus(userUuid, baseQuery)

        if (statusResponse.error) {
          return statusResponse
        }

        const userInfoResponse = (await baseQuery({
          url: `/user/${userUuid}`,
          method: 'GET',
        })) as QueryResult<UserProfileData>

        return userInfoResponse
      },
      providesTags: () => [TAG],
    }),

    getMyFavourites: builder.query<
      Product[],
      {
        userUuid?: Id
        config: ConfigWithCallbacks
      }
    >({
      query: ({ userUuid }) => ({
        url: `/user/${userUuid}/favourites`,
        headers: {
          'X-XSRF-TOKEN': '',
        },
      }),
      providesTags: () => [FAVOURITE_TAG],
      transformResponse: async (res: FavoriteItem[], _, args) => {
        const pictureId = getPictureId()
        const numFound = res?.length
        if (numFound > 0) {
          const products = await args.config.resultCallback({
            products: res,
            numFound,
          })
          return products.map((prd, i) => ({
            ...prd,
            imageVtoUrl: res[i] ? res[i].imageUrl || getVtoImage(pictureId, prd.upc) : '', // My face wearing glasses!
          }))
        }
        return []
      },
    }),

    addUpcToMyFavourites: builder.mutation<Favorite, { userUuid: Id; upc: Upc }>({
      query: ({ userUuid, upc }) => ({
        url: `/user/${userUuid}/favourite/${upc}`,
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': '',
        },
      }),
      invalidatesTags: () => [FAVOURITE_TAG],
    }),

    setPersistData: builder.mutation<object, { userUuid: Id }>({
      query: ({ userUuid }) => ({
        url: `/user/${userUuid}/persist`,
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': '',
        },
      }),
      invalidatesTags: () => [TAG_PROFILE],
    }),

    setPicture: builder.mutation<
      { userUuid: Id; pictureUuid: Id },
      { userUuid: Id; pictureUuid: Id }
    >({
      query: ({ userUuid, pictureUuid }) => ({
        url: `/user/${userUuid}/picture`,
        method: 'PUT',
        headers: {
          'X-XSRF-TOKEN': '',
        },
        body: {
          pictureUuid,
        },
      }),
      invalidatesTags: () => [TAG],
    }),

    setVideo: builder.mutation<{ userUuid: Id; videoUuid: Id }, { userUuid: Id; videoUuid: Id }>({
      query: ({ userUuid, videoUuid }) => ({
        url: `/user/${userUuid}/video`,
        method: 'PUT',
        headers: {
          'X-XSRF-TOKEN': '',
        },
        body: {
          videoUuid,
        },
      }),
      invalidatesTags: () => [TAG],
    }),

    removeUpcToMyFavourites: builder.mutation<Favorite, { userUuid: Id; upc: Upc }>({
      query: ({ userUuid, upc }) => ({
        url: `/user/${userUuid}/favourite/${upc}`,
        method: 'DELETE',
        headers: {
          'X-XSRF-TOKEN': '',
        },
      }),
      invalidatesTags: () => [FAVOURITE_TAG],
    }),

    setSearchInfoField: builder.mutation<
      {
        key: SearchInfoKey
        value: SearchInfoValue
      },
      {
        userUuid: Id
        value: SearchInfoValue
        key: SearchInfoKey
      }
    >({
      queryFn: async ({ userUuid, value, key }, _, __, baseQuery) => {
        const result = (await baseQuery({
          url: `/user/${userUuid}/property/${key}`,
          method: 'PUT',
          body: {
            value,
          },
        })) as QueryResult<{
          key: SearchInfoKey
          value: SearchInfoValue
        }>

        if (result.error) {
          return result
        }

        const error =
          result.data && result.data.value !== value
            ? {
                status: 500,
                data: {
                  error: `setSearchInfoField was invoked with key ${key} and value ${value}, but respondend with ${result.data.value}`,
                },
              }
            : undefined

        return error ? { error } : result
      },
      invalidatesTags: () => [TAG],
    }),

    setVideoUuid: builder.mutation<
      {
        userUuid: Id
        videoUuid: Id
      },
      {
        userUuid: Id
        videoUuid: Id
      }
    >({
      query: ({ userUuid, videoUuid }) => ({
        url: `/user/${userUuid}/video`,
        method: 'PUT',
        headers: {
          'X-XSRF-TOKEN': '',
        },
        body: {
          videoUuid,
          userUuid,
        },
      }),
      invalidatesTags: (_, error) => (error ? [] : [TAG]),
    }),

    resetUserProfile: builder.mutation<object, { userUuid: Id }>({
      query: ({ userUuid }) => ({
        url: `/user/${userUuid}/reset`,
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': '',
        },
      }),
      invalidatesTags: () => [TAG, FAVOURITE_TAG],
    }),
  }),
})

export const useGetUserInfoQuery = (uuid?: Id) => {
  const userUuid = uuid || getUserId()
  const response = userApi.useGetUserInfoQuery(userUuid || skipToken)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (response.isError && extractErrorStatus(response.error) === 401) {
    delSessionInfo()
    dispatch(userActions.resetUser())
    VirtualMirror.resetBipaState()
    FrameAdvisorCapture.resetBipaState()
    navigate('/')
  }

  return response
}

export const useGetUserUuidQuery = (skip = false, refetchOnMountOrArgChange = true) => {
  const config = useConfig() as ConfigWithCallbacks
  const res = userApi.useGetUserUuidQuery(
    { channel: config.facescanSource, region: config.facescanRegion },
    { skip: skip, refetchOnMountOrArgChange },
  )
  return res
}

export const useUuid = () => {
  const userDataRes = useGetUserInfoQuery()
  return userDataRes.data?.uuid
}

export const usePictureUuid = () => {
  const userDataRes = useGetUserInfoQuery()
  return userDataRes.data?.pictureUuid
}

export const useSetSearchInfoFieldMutation = () => {
  const userUuid = useUuid()

  const [_setSearchInfoField, mutationResult] = userApi.useSetSearchInfoFieldMutation()

  const setSearchInfoField = (key: SearchInfoKey, value: SearchInfoValue) => {
    userUuid && _setSearchInfoField({ userUuid, value, key })
  }

  const getUserInfoResult = userApi.useGetUserInfoQuery(userUuid || '', {
    skip: !userUuid || !!mutationResult.error || !mutationResult.isSuccess,
  })

  return [setSearchInfoField, mutationResult.isError ? mutationResult : getUserInfoResult] as [
    typeof setSearchInfoField,
    typeof getUserInfoResult,
  ]
}

export const useGetMyFavouritesQuery = (refetchOnMountOrArgChange = true) => {
  const userUuid = useUuid()
  const config = useConfig() as ConfigWithCallbacks

  const getMyFavouriteResults = userApi.useGetMyFavouritesQuery(
    { userUuid, config },
    { skip: !userUuid, refetchOnMountOrArgChange },
  )
  return getMyFavouriteResults
}

export const useAddUpcToMyFavouritesMutation = () => {
  const userUuid = useUuid()

  const [_addUpcToMyFavourites, result] = userApi.useAddUpcToMyFavouritesMutation()

  const addUpcToMyFavourites = (upc: Upc) => !!userUuid && _addUpcToMyFavourites({ upc, userUuid })

  return [addUpcToMyFavourites, result] as [typeof addUpcToMyFavourites, typeof result]
}

export const useSetPersistDataMutation = () => {
  const userUuid = useUuid()

  const [_setPersistData, result] = userApi.useSetPersistDataMutation()

  const setPersistData = () => !!userUuid && _setPersistData({ userUuid })

  return [setPersistData, result] as [typeof setPersistData, typeof result]
}

export const useSetPictureMutation = () => {
  const userUuid = useUuid()
  const [_setPictureData, result] = userApi.useSetPictureMutation()

  const setPictureData = (pictureUuid: Id) =>
    !!userUuid && _setPictureData({ userUuid, pictureUuid })

  return [setPictureData, result] as [typeof setPictureData, typeof result]
}

export const useSetVideoMutation = () => {
  const userUuid = useUuid()

  const [_setVideoData, result] = userApi.useSetVideoMutation()

  const setVideoData = (videoUuid: Id) => !!userUuid && _setVideoData({ userUuid, videoUuid })

  return [setVideoData, result] as [typeof setVideoData, typeof result]
}

export const useRemoveUpcToMyFavouritesMutation = () => {
  const userUuid = useUuid()

  const [_removeUpcToMyFavourites, result] = userApi.useRemoveUpcToMyFavouritesMutation()

  const removeUpcToMyFavourites = (upc: Upc) =>
    !!userUuid && _removeUpcToMyFavourites({ upc, userUuid })

  return [removeUpcToMyFavourites, result] as [typeof removeUpcToMyFavourites, typeof result]
}

export const useResetUserProfileMutation = () => {
  const userUuid = useUuid()

  const [_resetUserProfile, result] = userApi.useResetUserProfileMutation()

  const resetUserProfile = () => !!userUuid && _resetUserProfile({ userUuid })

  return [resetUserProfile, result] as [typeof resetUserProfile, typeof result]
}

export const useGetUserSearchInfoField = <T>(key: keyof UserSearchInformation): T | undefined => {
  const uuid = useUuid()
  const res = userApi.useGetUserInfoQuery(uuid || '', { skip: !uuid })
  const data = res?.data?.userSearchInformation[key] as T | undefined

  return data
}

export const useGetUserInfoField = <T>(key: 'glassesType' | 'faceBreadth'): T | undefined => {
  const uuid = useUuid()
  const res = userApi.useGetUserInfoQuery(uuid || '', { skip: !uuid })
  const data = res?.data?.userInformation[key] as T | undefined

  return data
}

export const { useSetVideoUuidMutation } = userApi

const getVtoImage = (pictureUuid: string | null | undefined, upc: string, portrait = false) => {
  if (!pictureUuid) return ''
  return `${
    process.env.REACT_APP_VTO_IMAGES_HOST || 'test-gw-api-vtoimageheadless.luxdeepblue.com'
  }/s/480${portrait ? 'f' : ''}/v/${pictureUuid}/u/${upc}/pi/7`
}

export const useGetSharedImage = (upc: string) => {
  const userUuid = useUuid()

  const getUserInfoResult = userApi.useGetUserInfoQuery(userUuid || '', {
    skip: !userUuid,
  })

  return getVtoImage(getUserInfoResult.data?.pictureUuid, upc, true)
}
