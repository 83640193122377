import { bipaAgreementRequest, BipaAgreementReturn, MicroserviceEnvironment, submitBipaFormRequest } from '@luxottica/vto-microservices';
import { BipaOverlay, BipaOverlayProps } from './BipaOverlay';
import { AgreementType } from './constants/AgreementType';
import { BipaSubmissionError } from './errors/errors';
import { CookieHelper } from './helpers/CookieHelper';
import { ParameterHelper } from './helpers/ParameterHelper';
import { BipaCallbacks } from './interfaces/BipaCallbacks';
import { BipaVersionParameters, SaveBipaEntryParameters } from './interfaces/BipaConfiguration';
import { BipaFormData } from './interfaces/BipaFormData';
import { BipaPage } from './interfaces/BipaPage';
import { PrivacyLocalization, PrivacyLocalizationKey } from './interfaces/PrivacyLocalization';
import { BipaPrivacyOptions } from './interfaces/PrivacyOptions';

const BipaManager = {
  getCookieName: (agreementType: AgreementType): string => {
    return CookieHelper.getBiometricCookieName(agreementType);
  },

  biometricCookieExists: (agreementType: AgreementType): boolean => {
    return CookieHelper.biometricCookieExists(agreementType);
  },

  getBipaVersion: (config: BipaVersionParameters): Promise<BipaAgreementReturn> => {
    return bipaAgreementRequest({
      locale: config.locale,
      environment: config.environment,
      accessToken: config.accessToken,
      refreshToken: config.refreshToken,
      onAuthUpdated: config.onAuthUpdated
    });
  },

  saveBipaEntry: (
    config: SaveBipaEntryParameters
  ): Promise<Response> => {
    const fieldErrors = ParameterHelper.validateBipaForm(config.formData);
    if (fieldErrors.length > 0) {
      return Promise.reject(new BipaSubmissionError('failed client-side bipa form validation', fieldErrors));
    }

    return submitBipaFormRequest({
      environment: config.environment,
      accessToken: config.accessToken,
      refreshToken: config.refreshToken,
      onAuthUpdated: config.onAuthUpdated,
      bipaVersion: config.bipaVersion,
      source: config.source,
      firstName: config.formData.firstName,
      lastName: config.formData.lastName,
      email: config.formData.email
    }).then((response) => {
      return response.json().then((json) => {
        return {
          response: response,
          body: json
        };
      });
    }).then((responseData) => {
      if (responseData.response.ok) {
        return responseData.response;
      } else {
        return (!!responseData.body?.title && !!responseData.body?.message) ?
          Promise.reject(new BipaSubmissionError(`${responseData.body.title}: ${responseData.body.message}`, responseData.body.fieldErrors))
            :
          Promise.reject(new BipaSubmissionError('failed to contact bipa server', undefined));
      }
    }).catch((e) => {
      if (e instanceof BipaSubmissionError) {
        throw e;
      } else {
        throw new BipaSubmissionError('failed to contact bipa server', undefined);
      }
    });
  }
};

export {
  AgreementType,
  MicroserviceEnvironment,
  BipaManager,
  BipaVersionParameters,
  SaveBipaEntryParameters,
  BipaPage,
  BipaOverlay,
  BipaOverlayProps,
  BipaFormData,
  BipaPrivacyOptions,
  PrivacyLocalization,
  PrivacyLocalizationKey,
  BipaCallbacks,
};
