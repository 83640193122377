import { AgreementType, BipaManager, BipaOverlay } from '@luxottica/bipa-manager';
import { DeviceType } from 'current-device';
import React, { useState } from 'react';
import { getAuthData } from '../../helpers/AuthHelper';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { CONFIG } from '../../helpers/ConfigHelper';
import { UploadOptions } from '../../interfaces/CaptureParameters';
import { FrameAdvisorErrorOverlay } from '../fa-capture/FrameAdvisorErrorOverlay';
import { UploadImage } from './UploadImage';
import './UploadImageContainer.scss';

type agreementStatus = 'INITIAL' | 'APPROVED' | 'REJECTED' | 'ERROR';

const UploadImageContainer = (props: {
  options: UploadOptions,
  deviceType: DeviceType
}) => {

  const [agreementStatus, setAgreementStatus] = useState<agreementStatus>(
    BipaManager.biometricCookieExists(AgreementType.BIOMETRIC) ? 'APPROVED' : 'INITIAL'
  );

  let content;

  switch (agreementStatus) {
    case 'INITIAL':
      CallbackHelper.onPrivacyDisplay();
      content = (
        <BipaOverlay
          type={AgreementType.BIOMETRIC}
          tokenVersion={CONFIG.tokenVersion}
          accessToken={getAuthData().accessToken}
          refreshToken={getAuthData().refreshToken}
          environment={CONFIG.environment}
          locale={CONFIG.locale}
          source={CONFIG.source}
          region={CONFIG.region}
          callbacks={{
            onSubmit: () => {
              // close the Privacy agreement and start the experience
              setAgreementStatus('APPROVED');
            },
            onUserReject:  () => {
              // close the Privacy agreement and BLOCK the experience
              setAgreementStatus('REJECTED');
              CallbackHelper.onPrivacyReject();
            },
            onError: (error) => {
              setAgreementStatus('ERROR');
              CallbackHelper.onPrivacyFail(error);
            },
            onAuthUpdated: CallbackHelper.onAuthUpdated
          }} privacy={{
            termsAndConditions: CONFIG.privacy.termsAndConditions,
            localizationText: CONFIG.privacy?.localizationText
          }}/>
      );
      break;
    case 'APPROVED':
      CallbackHelper.onCaptureProcess();
      content = <UploadImage options={props.options} deviceType={props.deviceType}/>;
      break;
    case 'ERROR':
      content = <FrameAdvisorErrorOverlay enabled onClickOk={() => { setAgreementStatus('INITIAL'); }} />;
      break;
    case 'REJECTED':
    default:
      content = <></>;
      break;
  }

  return (
    <div className='upload-image-container'>
      {content}
    </div>
  );
};

export {
  UploadImageContainer
};
