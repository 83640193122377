import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../package.json';
import { LocalizationDefaults } from '../constants/LocalizationDefaults';
import { LocalizationError } from '../errors/errors';
import { FrameAdvisorLocalization } from '../interfaces/Localization';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'LocalizationHelper');

let localizedFaTranslations: FrameAdvisorLocalization;

const localizationBaseUrl = 'https://d5nhc6q3o19l2.cloudfront.net/frame-advisor';
const translationType = 'MIRROR';

const fetchFrameAdvisorJson = (locale: string): Promise<FrameAdvisorLocalization> => {
  const localizationFileURL = `${localizationBaseUrl}/${locale}/capture-component_${locale}.json`;
  return fetch(localizationFileURL).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw Error(`failed to find frame advisor localization for locale: ${locale}`);
    }
  }).then((json) => {
    return json[translationType];
  });
};

const fetchFrameAdvisorLocalization = (
  backupLanguage: string,
  locale: string
): Promise<void> => {
  return new Promise((resolve) => {

    let translationFound = false;
    const backupLocale = LocalizationDefaults.localeMap[backupLanguage];
    const defaultLocale = LocalizationDefaults.locale;

    fetchFrameAdvisorJson(locale).then((translation) => {
      translationFound = true;
      localizedFaTranslations = translation;
    }).catch(() => {
      if (!translationFound) {
        logger.warn(`failed to find frame-advisor localization for locale: '${locale}', trying with backup locale '${backupLocale}'`);
      }
    }).then(() => {
      if (!translationFound) {
        return fetchFrameAdvisorJson(backupLocale);
      } else {
        throw Error('break');
      }
    }).then((backupLanguageTranslation) => {
      translationFound = true;
      localizedFaTranslations = backupLanguageTranslation;
    }).catch(() => {
      if (!translationFound) {
        logger.warn(`failed to find frame-advisor localization for locale: '${backupLocale}', trying with default locale '${defaultLocale}'`);
      }
    }).then(() => {
      if (!translationFound) {
        return fetchFrameAdvisorJson(defaultLocale);
      } else {
        throw Error('break');
      }
    }).then((defaultLanguageTranslation) => {
      translationFound = true;
      localizedFaTranslations = defaultLanguageTranslation;
    }).catch(() => {
      if (!translationFound) {
        logger.warn(`failed to find frame-advisor localization for locale: ${defaultLocale}, using local translations`);
      }
    }).then(() => {
      if (!translationFound) {
        translationFound = true;
        localizedFaTranslations = LocalizationDefaults.translations;
      }
      resolve();
    });
  });
};

const LocalizationHelper = {
  getString(page: string, key: string): string {
    try {
      return localizedFaTranslations[page][key];
    } catch (e) {
      logger.warn(`local translation not found for [${page}][${key}] using default`);
      try {
        return LocalizationDefaults.translations[page][key];
      } catch (e) {
        logger.warn(`default translation not found for [${page}][${key}]`);
        return '';
      }
    }
  },

  setLocalizationText(locale: string): Promise<void> {
    let backupLanguage;
    return new Promise((resolve, reject) => {
      try {
        backupLanguage = locale.split('-')[0];
        if (!LocalizationDefaults.localeMap[backupLanguage]) {
          backupLanguage = 'en';
        }
        resolve(backupLanguage);
      } catch (e) {
        const error = new LocalizationError(`invalid locale '${locale}'`, e);
        logger.error(error);
        reject(error);
      }
    }).then(() => {
      return fetchFrameAdvisorLocalization(backupLanguage, locale);
    });
  }
};

export {
  LocalizationHelper
};