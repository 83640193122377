import { Catalog, CatalogConfiguration } from '@luxottica/vm-glasses';
import { FeatureLicensingService } from '../app/service/FeatureLicensingService';
import { Features } from '../interfaces/FeatureLicensingTypes';
import { CallbackHelper } from './CallbackHelper';

const transitionColorMap: TransitionColors = {};
let transitionOpacity  = 0.0;
let finalOpacity: number;

let animationEnabled = false;
let animateForward = false;
let animationStartTime: number;
let animationDuration: number;

const computePartialAnimation = (
  forward: boolean,
  duration: number,
  currentOpacity: number
): number => {
  return forward ?
    Date.now() - (duration * currentOpacity)
      :
    Date.now() - (duration * (1 - currentOpacity));
};

const TransitionColorHelper = {
  downloadTransitionColors: (config: CatalogConfiguration): Promise<void> => {
    if (Object.keys(transitionColorMap).length !== 0) {
      return Promise.resolve();

    } else {
      if (FeatureLicensingService.isFeatureAuthorized(Features.VM_TRANSITIONS)) {
        return Catalog.downloadTransitionColors(config).then((colorMap) => {
          Object.keys(colorMap).map((colorName) => {
            transitionColorMap[colorName] = {
              mirror: colorMap[colorName].mirror,
              active: colorMap[colorName].active,
              clear: colorMap[colorName].clear,
            };
          });
        });

      } else {
        return Promise.resolve();
      }
    }
  },

  setTransitionOverrideColor: (color: TransitionColor) => {
    transitionColorMap.OVERRIDE = color;
  },

  reset: () => {
    animationEnabled = false;
    finalOpacity = undefined;
  },

  startAnimation: (duration: number) => {
    if (!animationEnabled) {
      animationEnabled = true;
      animationDuration = duration;
      animateForward = transitionOpacity < 0.5;
      animationStartTime = (transitionOpacity % 1 === 0) ?
        Date.now() : computePartialAnimation(animateForward, animationDuration, transitionOpacity);
      finalOpacity = undefined;
    }
  },

  getTransitionColor: (colorName: string): TransitionColor => {
    return transitionColorMap?.[colorName];
  },

  setCurrentLensOpacity: (inputOpacity: number): void => {
    TransitionColorHelper.reset();
    transitionOpacity = inputOpacity;
  },

  getCurrentLensOpacity: (): number => {
    if (finalOpacity !== undefined || !animationEnabled) {
      return transitionOpacity;

    } else {
      let animationProgress = (Date.now() - animationStartTime) / animationDuration;
      if (animationProgress >= 1.0) {
        animationProgress = 1.0;
        animationEnabled = false;
      }
      transitionOpacity = (animateForward) ? animationProgress : (1.0 - animationProgress);
      if (animationProgress === 1.0) {
        finalOpacity = transitionOpacity;
        CallbackHelper.onTransitionAnimationEnd(finalOpacity);
      }

      CallbackHelper.onTransitionAnimation(transitionOpacity);
      return transitionOpacity;
    }
  }
};

export { TransitionColorHelper };
