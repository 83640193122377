import { getConfig } from '../config/Config';
import { ProfileRequestData, ProfileResponse, ProfileReturn } from '../interfaces/Profile';
import { refreshRequest } from './RefreshRequest';

const profileFetch = (
  request: ProfileRequestData
): Promise<Response> => {
  const url = getConfig(request.environment, 'profileUrl');

  return fetch(`${url}/${request.videoId}`, {
    method: 'GET',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.accessToken}`
    }
  });
};

const profileRequest = (
  request: ProfileRequestData
): Promise<ProfileReturn> => {

  return profileFetch(request).then((profileResponse) => {
    if (profileResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return profileFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return profileResponse;
    }

  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().catch(() => {
        throw new Error(`${response.status}: ${response.statusText}`);
      }).then((json) => {
        throw new Error(JSON.stringify(json));
      });
    }

  }).then((json: ProfileResponse) => {
    const result: ProfileReturn = {};
    result['properties'] = json.mlInformations;
    result['videoId'] = json.uuid;
    return result;

  });
};

export {
  profileRequest
};