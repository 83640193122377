// tslint:disable-next-line:interface-name
declare interface NativeBridgeParameters {
  resolution: {
    width: number;
    height: number;
  };
}

abstract class AbstractNativeBridge {
  public static isNativeBridgePresent: () => boolean;
  public abstract onStartNativeCam: ((resolution: any, error: any) => void) | undefined;
  public abstract onCamFrame: ((base64: string) => void) | undefined;
  public abstract close: () => void;
  public abstract pause: () => void;
  public abstract resume: () => void;
  public abstract start: (requiredResolution: NativeBridgeParameters) => void;
}

export { AbstractNativeBridge, NativeBridgeParameters };
