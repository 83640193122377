import { FrameAdvisorLocalization } from '../interfaces/Localization';

const defaultLocale = 'en-US';
const defaultLanguage = 'en';

const localeMap = {
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  sv: 'sv-SE',
  tr: 'tr-TR',
  zh: 'zh-CN',
};

const defaultTranslations: FrameAdvisorLocalization = {
	PERMISSION_ERROR: {
		ERROR: 'You must allow access to your camera to use Frame Advisor.',
		SUBTEXT: 'Click on your browser Share or Allow button to enable your webcam.'
	},
	WEBCAM_ERROR: {
		ERROR: 'We could not detect your webcam.',
		SUBTEXT: 'Enable webcam access in your browser and refresh the page to continue.'
	},
	GENERIC_ERROR: {
		ERROR: 'An error occured',
		SUBTEXT: 'Please check your camera permissions and try to reload the page.'
	},
	MANUAL_START: {
		BUTTON: 'Start the experience',
		TEXT: 'You are probably in Low Power Mode. The experience may be degraded.'
	},
	GUIDANCE: {
		UNKNOWN: 'Is anyone there?',
		DEFAULT: 'Place your head in the oval',
		MOVE_CLOSER: 'Move closer to the oval',
		MOVE_FARTHER: 'Move farther from the screen',
		LOOK_UP: 'Look up',
		LOOK_RIGHT: 'Look to your right',
		LOOK_DOWN: 'Look down',
		LOOK_LEFT: 'Look to your left',
		TILT_LEFT: 'Tilt to your left',
		TILT_RIGHT: 'Tilt to your right',
		GLASSES: 'Please remove your glasses',
		STAY_STILL: 'Good, stay still, get ready for the picture!'
	},
	VTO_RECORD: {
		STEP_1: 'Prepare to move your head from side to side',
		STEP_2: 'Turn your head to the left.',
		STEP_3: 'Turn your head to the center.',
		STEP_4: 'Turn your head to the right.',
		STEP_5: 'Turn your head back to center.'
	},
	SERVER_PROCESSING: {
		LOADING: 'Please wait...',
		IMAGE_UPLOAD: 'Loading the image',
		IMAGE_PROCESSING: 'We\'re processing your information',
		DONE: 'Image processing finished'
	},
	CAPTURE_ERROR: {
		ERROR: 'An error occured',
		SUBTEXT: 'Please try to shoot a picture again.',
		BUTTON: 'Continue'
	},
	UPLOAD: {
		CLICK_OR_DROP: 'Click to select a file or drop your picture here',
    DROP_HERE: 'Drop your selfie here',
    TAP_TO_UPLOAD: 'Tap to upload your picture',
    ACCEPT: 'Accept',
    DECLINE: 'Decline'
	}
};

const LocalizationDefaults = {
  locale: defaultLocale,
  language: defaultLanguage,
  localeMap,
  translations: defaultTranslations,
};

export {
  LocalizationDefaults,
};
