import JSZip from 'jszip';
import { BasicGLTFAsset } from './interfaces/BasicGLTFAsset';

export const AssetDecoder = {
  getArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(blob);
    });
  },

  getZippedImage(imagePromise: JSZip.JSZipObject): Promise<HTMLImageElement> {
    return imagePromise
      .async('blob')
      .then((blob: Blob) => {
        return AssetDecoder.getImage(blob);
      });
  },

  getImage(blob: Blob): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(blob);
      const img = new Image();
      img.crossOrigin = '*';

      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };

      img.onerror = (e) => {
        reject(e);
      };

      img.src = url;
    });
  },

  getImageBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve('' + reader.result);
      };
      reader.readAsDataURL(blob);
    });
  },

  getZippedGLTF(upc: string, gltfPromise: JSZip.JSZipObject): Promise<BasicGLTFAsset> {
    return gltfPromise
      .async('blob')
      .then((blob: Blob) => {
        return AssetDecoder.getGLTFAsset(upc, blob);
      });
  },

  getGLTFAsset(upc: string, blob: Blob): Promise<BasicGLTFAsset> {
    return this.getArrayBuffer(blob)
    .then((arrayBuffer) => {
      return Promise.resolve({
        upc,
        arrayBuffer,
      });
    });
  },
};
