import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import JSZip from 'jszip';
import { name, version } from '../package.json';
import { AssetDecoder } from './AssetDecoder';
import { AssetResolution } from './interfaces/AssetResolution';
import { assetNames } from './constants/AssetNames';
import { B3dAsset } from './interfaces/B3dAsset';
import { distributorFolderName } from './helpers/AssetHelper';
import { BasicGLTFAsset } from './interfaces/BasicGLTFAsset';
import { GlassesDownloadError } from './error/GlassesDownloadError';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'GlassesExtractor');

const ALL_TEXTURES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

class GlassesExtractor {

  public unzip(
    upc: string,
    glassesZip: Blob,
    resolution: AssetResolution,
    singleTexture: number | undefined,
  ): Promise<B3dAsset> {
    const colorIndexes = (typeof singleTexture === 'number') ? [singleTexture] : ALL_TEXTURES;

    return this.unzipGlasses(
      upc,
      glassesZip,
      colorIndexes,
      resolution);
  }

  private unzipGlasses(
    upc: string,
    glassesZip: Blob,
    colorIndexes: number[],
    resolution: AssetResolution
  ): Promise<B3dAsset> {
    const time1 = performance.now();
    let time2 = 0;
    const jszip = new JSZip();
    return jszip.loadAsync(
        glassesZip
      ).then(() => {
        logger.info('glasses zip [upc={}] loaded in {} ms', upc, performance.now() - time1);
        time2 = performance.now();
        return this.extractFilesFromZipArchive(jszip, upc, colorIndexes);
      }).then((glassesExtracted: Array<ArrayBuffer | BasicGLTFAsset | HTMLImageElement | undefined>) => {
        logger.info('glasses zip [upc={}] opened in {} ms', upc, performance.now() - time2);
        const glasses = this.createGlassesDataFromExtractedArchive(upc, glassesExtracted, resolution, colorIndexes);
        return glasses;
      });
  }

  private extractFilesFromZipArchive(jszip: JSZip, zipUpc: string, colorIndexes: number[]): Promise<Array<BasicGLTFAsset | HTMLImageElement | ArrayBuffer | undefined>> {
    const promises: Promise<BasicGLTFAsset | HTMLImageElement | ArrayBuffer | undefined>[] = [];
    const folderName: string = distributorFolderName(zipUpc);
    promises.push(this.extractB3D(jszip, folderName));
    promises.push(
      this.extractMult(jszip, folderName),
      this.extractShadow(jszip, folderName),
      this.extractLensMap(jszip, folderName),
      this.extractBoundingBoxes(jszip, folderName),
      this.extractLandmarks(jszip, folderName),
    );

    this.extractColors(jszip, folderName, colorIndexes).forEach((promise) => {
      promises.push(promise);
    });
    return Promise.all(promises);
  }

  private extractColors(jszip: JSZip, folderName: string, colorIndexes: number[]) {
    const promises: Array<Promise<HTMLImageElement>> = [];
    colorIndexes.map((i) => {
      const time1 = performance.now();
      const zipFolder = jszip.folder(folderName);
      if (!zipFolder) {
        throw new GlassesDownloadError(`${folderName} folder does not exists`);
      }
      const file = zipFolder.file(assetNames.color(i));
      if (!file) {
        throw new GlassesDownloadError(`${assetNames.color(i)} file does not exists`);
      }
      const colorPromise = AssetDecoder.getZippedImage(file);
      colorPromise.then((color) => {
        const time2 = performance.now();
        const ttime = time2 - time1;
        logger.debug('texture {} extracted in {} ms from upc {}: (length={})', i, ttime, folderName, color.src.length);
      });
      promises.push(colorPromise);
    });
    return promises;
  }

  private extractMult(jszip: JSZip, folderName: string) {
    const zipFolder = jszip.folder(folderName);
    if (!zipFolder) {
      throw new GlassesDownloadError(`${folderName} folder does not exists`);
    }
    const file = zipFolder.file(assetNames.transparency);
    if (!file) {
      throw new GlassesDownloadError(`${assetNames.transparency} file does not exists`);
    }
    const multPromise = AssetDecoder.getZippedImage(file);
    const time1 = performance.now();
    multPromise.then((mult) => {
      const time2 = performance.now();
      const ttime = time2 - time1;
      logger.debug('mult extracted in {} ms from upc {}: (length={})', ttime, folderName, mult.src.length);
    });
    return multPromise;
  }

  private extractShadow(jszip: JSZip, folderName: string) {
    const zipFolder = jszip.folder(folderName);
    if (!zipFolder) {
      throw new GlassesDownloadError(`${folderName} folder does not exists`);
    }
    const file = zipFolder.file(assetNames.shadow);
    if (!file) {
      throw new GlassesDownloadError(`${assetNames.shadow} file does not exists`);
    }
    const shadowPromise = AssetDecoder.getZippedImage(file);
    const time1 = performance.now();
    shadowPromise.then((shadow) => {
      const time2 = performance.now();
      const ttime = time2 - time1;
      logger.debug('shadow extracted in {} ms from upc {}: (length={})', ttime, folderName, shadow.src.length);
    });
    return shadowPromise;
  }

  // lensmaps, boundig boxes, and landmarks are optional and are not always present in zip file
  private extractLensMap(jszip: JSZip, folderName: string) {
    const zipFolder = jszip.folder(folderName);
    if (!zipFolder) {
      throw new GlassesDownloadError(`${folderName} folder does not exists`);
    }
    const lensmapFile = zipFolder.file(assetNames.lensMap);
    if (!!lensmapFile) {
      const lensmapPromise = AssetDecoder.getZippedImage(lensmapFile);
      const time1 = performance.now();
      lensmapPromise.then((lensmap: any) => {
        const time2 = performance.now();
        const ttime = time2 - time1;
        logger.debug('lens map extracted in {} ms from upc {}: (length={})', ttime, folderName, lensmap.byteLength);
      });
      return lensmapPromise;
    }
    return Promise.resolve(undefined);
  }

  // lensmaps, boundig boxes, and landmarks are optional and are not always present in zip file
  private extractBoundingBoxes(jszip: JSZip, folderName: string) {
    const zipFolder = jszip.folder(folderName);
    if (!zipFolder) {
      throw new GlassesDownloadError(`${folderName} folder does not exists`);
    }
    const lensBoundsFile = zipFolder.file(assetNames.lensBounds);
    if (!!lensBoundsFile) {
      const lensBoundsPromise = AssetDecoder.getZippedGLTF(folderName, lensBoundsFile);
      const time1 = performance.now();
      lensBoundsPromise.then((bounds: any) => {
        const time2 = performance.now();
        const ttime = time2 - time1;
        logger.debug('lens bounds extracted in {} ms from upc {}: (length={})', ttime, folderName, bounds.byteLength);
      });
      return lensBoundsPromise;
    }
    return Promise.resolve(undefined);
  }

  // lensmaps, boundig boxes, and landmarks are optional and are not always present in zip file
  private extractLandmarks(jszip: JSZip, folderName: string) {
    const landmarksFile = jszip.folder(folderName)!.file(assetNames.glassesLandmarks);
    if (!!landmarksFile) {
      const landmarksPromise = AssetDecoder.getZippedGLTF(folderName, landmarksFile);
      const time1 = performance.now();
      landmarksPromise.then((landmarks: any) => {
        const time2 = performance.now();
        const ttime = time2 - time1;
        logger.debug('landmarks extracted in {} ms from upc {}: (length={})', ttime, folderName, landmarks.byteLength);
      });
      return landmarksPromise;
    }
    return Promise.resolve(undefined);
  }

  private extractB3D(jszip: JSZip, folderName: string) {
    const b3dPromise = jszip.folder(folderName)!.file(assetNames.b3d)!.async('arraybuffer');
    const time1 = performance.now();
    b3dPromise.then((b3d: any) => {
      const time2 = performance.now();
      const ttime = time2 - time1;
      logger.debug('model extracted in {} ms from upc {}: (length={})', ttime, folderName, b3d.byteLength);
    });
    return b3dPromise;
  }

  private createGlassesDataFromExtractedArchive(
    upc: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    glassesExtracted: any[],
    resolution: AssetResolution,
    textureIndexes: number[]
  ): B3dAsset {
    logger.info('creating glasses from {} items...', glassesExtracted.length);
    const glasses: B3dAsset = {
      objModel: glassesExtracted[0],
      transparency: glassesExtracted[1],
      shadow: glassesExtracted[2],
      lensMap: glassesExtracted[3],
      lensBoundingBox: glassesExtracted[4],
      glassesLandmarks: glassesExtracted[5],
      textures: glassesExtracted.slice(6, 6 + textureIndexes.length),
      upc,
      resolution,
    };
    return glasses;
  }

}

export { GlassesExtractor };
