class Timer {

  private startTime: number;

  public start = (): void => {
    this.startTime = Date.now();
  }

  public continue = (): void => {
    if (!this.startTime) {
      this.startTime = Date.now();
    }
  }

  public reset = (): void => {
    this.startTime = undefined;
  }

  public on = (): boolean => {
    return this.startTime !== undefined;
  }

  public currentDuration = (): number => {
    return Date.now() - this.startTime;
  }
}

export { Timer };
