import { Catalog, CatalogConfiguration, GlassesEnvironment, UpcAvailability } from '@luxottica/vm-glasses';
import { MicroserviceEnvironment } from '@luxottica/vto-microservices';
import { FeatureLicensingService } from './app/service/FeatureLicensingService';
import { Features } from './interfaces/FeatureLicensingTypes';

class Catalogue {

  private static config: CatalogConfiguration;

  private static featureLicensingInitialization;

  private constructor(env: GlassesEnvironment) {
    Catalogue.config = { env };
  }
  public static build(
    {catalogueEnvironment, featureLicensingEnv, key}:
    {
      catalogueEnvironment?: GlassesEnvironment,
      featureLicensingEnv?: MicroserviceEnvironment
      key: string
    }): Catalogue {

    this.featureLicensingInitialization = FeatureLicensingService.initialize(key, !!featureLicensingEnv ? featureLicensingEnv : 'PRODUCTION');

    return new Catalogue(!!catalogueEnvironment ? catalogueEnvironment : 'PROD' as GlassesEnvironment);
  }

  public isUpcSupported(...upcs: string[]): { [upc: string]: Promise<UpcAvailability>; } {
    const result: { [upc: string]: Promise<UpcAvailability>; } = {};
    upcs.forEach(upc => {
      result[upc] = Catalog.getUpcAvailability(Catalogue.config, upc);
    });

    return result;
  }

  public downloadTransitionColorCatalogue(): Promise<{
    [name: string]: TransitionColor;
  }> {
    return Catalogue.featureLicensingInitialization
    .then(() => {
      if (FeatureLicensingService.isFeatureAuthorized(Features.VM_TRANSITIONS)) {
        return Catalog.downloadTransitionColors(Catalogue.config);
      } else {
        return Promise.resolve({});
      }
    });
  }
}

export { Catalogue, UpcAvailability };
