import { WebCamAbstractError } from './WebCamAbstractError';

class WebCamAccessRejectedError extends WebCamAbstractError {
  public static type = 'WebCamAccessRejectedError';

  constructor(error?: Error) {
    super(WebCamAccessRejectedError.type, 'webcam access was rejected', error);
    Object.setPrototypeOf(this, WebCamAccessRejectedError.prototype);
  }
}

export { WebCamAccessRejectedError };
