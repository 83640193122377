import { getConfig } from '../config/Config';
import { LoginRequestData, LoginResponse, LoginReturn } from '../interfaces/Login';

const loginRequest = (
  request: LoginRequestData
): Promise<LoginReturn> => {

  if (!!request.accessToken && !!request.refreshToken) {
    return Promise.resolve({
      accessToken: request.accessToken,
      refreshToken: request.refreshToken
    });

  } else {
    const loginType = (request.version === 1) ? 'loginVmUrl' : 'loginFaUrl';
    const url = getConfig(request.environment, loginType);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rememberMe: true })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().catch(() => {
          throw new Error(`${response.status}: ${response.statusText}`);
        }).then((json) => {
          throw new Error(JSON.stringify(json));
        });
      }
    }).then((json: LoginResponse) => {
      const authData = {
        accessToken: json.access_token,
        refreshToken: json.refresh_token
      };
      request.onAuthUpdated?.(authData);
      return authData;
    });
  }
};

export {
  loginRequest
};