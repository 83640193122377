import { name, version } from "../../../../../package.json";
import { DBAppViewSessionFactory } from "../../../../adapter/db/factory/DBAppViewSessionFactory";
import { AppViewSessionAdapter } from "../../../../adapter/rest/AppViewSessionAdapter";
import { IRemoteAppViewSession } from "../../../model/IRemoteAppViewSession";
import { RemoteLogConfig } from "../../../RemoteLogConfig";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "AppViewSessionConsumer");

const dbInstance = () => {
  return DBAppViewSessionFactory.getInstance();
};

const messageAdapter = () => {
  return new AppViewSessionAdapter();
};

const consumeDbRows = () => {
  const dbase = dbInstance().getDB();

  dbase.iterate((
    value: IRemoteAppViewSession,
    key: string
  ) => {
    // if (value === null) {
    //   console.error("undefined: ", { key });
    // }
    messageAdapter().log({
      statusCode: value.statusCode,
      statusText: value.statusText
    }).then(() => {
      logger.debug("consumed stored log message: {}", JSON.stringify(value));
      // console.info("consumed: ", {key, value});
      return dbase.removeItem(key);
    }).catch(() => {
      // console.error("failed: ", { key });
    });
  }).then(() => {
    logger.debug("all logs consumed from local indexdb");
  });
};

class AppViewSessionConsumer {
  public consume() {
    const time1 = window.performance.now();
    consumeDbRows();
    const time2 = window.performance.now();
    logger.debug("logs consumed in {} ms", time2 - time1);
  }
}

export { AppViewSessionConsumer };
