import { Features } from '../interfaces/FeatureLicensingTypes';

const VM_SKIP_AKAMAI_LICENSING_FLAG = 'vm.akamai.licensing.skip';
(window as any).VMAkamaiLicensingFlag = VM_SKIP_AKAMAI_LICENSING_FLAG;

const config = {
  STAGING: {
    get baseUrl() {
      if (overrideAkamai()) {
        return 'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtomoduleprotection/public/v1/';
      } else {
        return 'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtomoduleprotection/public/v1/';
      }
    },
    getAllFeaturesPath: 'get-functionalities/',
    getKeyAuthorizationPath: 'key-authorized/',
  },
  ACCEPTANCE: {
    get baseUrl() {
      // tslint:disable-next-line: no-console
      console.warn('backoffice for feature licensing development env will be changed soon');
      if (overrideAkamai()) {
        return 'https://acceptance-gateway-vtoprofile.luxdeepblue.com/services/vtomoduleprotection/public/v1/';
      } else {
        return 'https://vmcore-test.luxottica.com/services/vtomoduleprotection/public/v1/';
      }
    },
    getAllFeaturesPath: 'get-functionalities/',
    getKeyAuthorizationPath: 'key-authorized/',
  },
  PRODUCTION: {
    get baseUrl() {
      if (overrideAkamai()) {
        return 'https://gateway-vtoprofile.luxdeepblue.com/services/vtomoduleprotection/public/v1/';
      } else {
        return 'https://vmcore.luxottica.com/services/vtomoduleprotection/public/v1/';
      }
    },
    getAllFeaturesPath: 'get-functionalities/',
    getKeyAuthorizationPath: 'key-authorized/',
  }
};

const defaultFeatures: Map<Features, boolean> = new Map([]);

const overrideAkamai = (): boolean => {
  if (!!window && !!window.localStorage) {
    return window.localStorage.getItem(VM_SKIP_AKAMAI_LICENSING_FLAG) === 'true';
  } else {
    return false;
  }
};

const getFeatureConfig = (environment: MicroserviceEnvironment) => {
  return config[environment];
};

export {
  getFeatureConfig,
  defaultFeatures,
};
