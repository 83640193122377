import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../../package.json';
import { NativeBridgeParameters } from './AbstractNativeBridge.js';
import { INativeBridgeMedia } from './INativeBridgeMedia';
import { NativeBridge } from './NativeBridge';
import { NativeStream } from './NativeStream';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'NativeBridgeMedia');

class NativeBridgeMedia implements INativeBridgeMedia {
  private remoteLog = new RemoteLog();

  public getUserMedia(constraints: MediaStreamConstraints): Promise<NativeStream> {
    logger.info('opening Native Camera with {}', JSON.stringify(constraints));

    const requiredResolution: any = this.getRequiredResolution(constraints);

    return new Promise<NativeStream> ((resolve, reject) => {
      window.NativeBridge = new NativeBridge();
      window.NativeBridge.start(requiredResolution);
      window.NativeBridge.onStartNativeCam = (resolution: any, error: any) => {
        if (error) {
          const message = 'Error starting Native Camera -> ' + JSON.stringify(error);
          logger.error(message);
          this.remoteLog.sendAppViewSession({
            statusCode: 'webcam-stream-error',
            statusText: message,
          });
          reject(error);
        } else {
          logger.info('Started Native Camera');
          this.remoteLog.sendAppViewSession({
            statusCode: 'webcam-stream',
            statusText: 'Started Native Camera',
          });
          resolve(new NativeStream(resolution));
        }
        window.NativeBridge.onStartNativeCam = undefined;
      };
    });
  }

  private getRequiredResolution(constraints: MediaStreamConstraints): NativeBridgeParameters | null {
    // tslint:disable-next-line:no-unnecessary-initializer
    let requiredResolution: NativeBridgeParameters | null = null;

    if (constraints &&
      constraints.video &&
      typeof (constraints.video) !== 'boolean' &&
      constraints.video.width &&
      constraints.video.height) {
      const width = (typeof (constraints.video.width) === 'number') ?
        constraints.video!.width :
        constraints.video.width.ideal;

      const height = (typeof (constraints.video.height) === 'number') ?
        constraints.video!.height :
        constraints.video.height.ideal;

      requiredResolution = {
        resolution: {
          height: height!,
          width: width!,
        },
      };
    }
    return requiredResolution;
  }
}

export { NativeBridgeMedia };
