import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'

import { showError } from '@root/utils/error'

// import { showErrorToast } from '@root/utils/toasts'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const error = action.payload
    const origin = `${action.type.split('/')[0]} - ${action.meta.arg.endpointName}`
    showError({ error, origin })
  }
  return next(action)
}
