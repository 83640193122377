import { EventBus } from '@luxottica/vm-eventbus';
import { Events } from '../../constants/Events';
import { VmWebCam } from '../mirror/VmWebCam';
import { vmIsInitialized } from '../VmCore';

const pause = (): void => {
  if (!vmIsInitialized()) {
    return;
  } else {
    VmWebCam.getInstance().pause();
    EventBus.publish(Events.PAUSE, {});
  }
};

export {
  pause
};
