// called when the component begin to send informations to retreive machine learning results
export const onFACaptureLoading = 'onFACaptureLoading';

// called when the mlresults are available and the component finished its job
export const onFACaptureComplete = 'onFACaptureComplete';

export const onCaptureError = 'onCaptureError';

export const onDisplayPrivacyPage = 'onDisplayPrivacyPage';

export const onPrivacyDisplay = 'onPrivacyDisplay';

export const onCaptureProcess = 'onFACaptureProcess';

export const DOMDefaultTarget = () => document.querySelector('body');
