import { InitializeOptions } from '../interfaces/InitializeParameters';

const DefaultInitializeOptions: InitializeOptions = {
  locale: '',
  region: 'US',
  source: 'WEB',
  tokenVersion: 2,
  environment: 'PRODUCTION'
};

export { DefaultInitializeOptions };
