import { RootState } from '../'

export const selectAccessToken = (state: RootState) => state.user.accessToken

export const selectRefreshToken = (state: RootState) => state.user.refreshToken

export const selectVideoId = (state: RootState) => state.user.videoId

export const selectPictureId = (state: RootState) => state.user.pictureId

export const selectUserId = (state: RootState) => state.user.userId
