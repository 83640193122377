import uuid from 'uuid';
import { INativeStream } from './INativeStream';
import { NativeStreamTrack } from './NativeStreamTrack';

class NativeStream implements INativeStream {
  public readonly id: string;
  private tracks: NativeStreamTrack[] = [];

  constructor(resolution?: any | undefined) {
    this.id = uuid();
    this.tracks.push(new NativeStreamTrack(resolution));
  }

  public getTracks(): any[] {
    return this.tracks;
  }

  public getVideoTracks(): any[] {
    return this.tracks;
  }
}

export { NativeStream };
