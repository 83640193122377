import { BaseQuery, Id, QueryResult } from '@root/types/common'

import { createApi } from '@reduxjs/toolkit/query/react'
import { getPictureId } from '@root/sizeAdvPersistance'
import { staggeredBaseQueryWithBailOut } from '@root/utils/servicesSizeAdv'

const fetchStatus = async (videoId: Id, baseQuery: BaseQuery) => {
  const response = (await baseQuery({
    url: `/${
      process.env.REACT_APP_SA_POLLING_VERSION_API || 'v1'
    }/pupillary-distance/status/${videoId}`,
    method: 'GET',
  })) as QueryResult<{ progress: 'NOT_STARTED' | 'RUNNING' | 'OK' | 'KO'; type: string }[]>

  if (response.error) {
    return response
  }

  const ko = response.data?.filter(({ progress }) => progress === 'KO')
  const error = ko.length
    ? {
        status: 500,
        data: {
          error: `getStatus returned ${ko.length} KO: ${ko.map(({ type }) => type).join(', ')}`,
        },
      }
    : undefined

  return error
    ? { error, data: undefined }
    : { data: response.data.every(({ progress }) => progress === 'OK'), error: undefined }
}

const pollStatus = async (
  videoId: Id,
  baseQuery: BaseQuery,
  pollingStartTimestamp?: number,
): Promise<QueryResult<boolean>> => {
  const response = await fetchStatus(videoId, baseQuery)
  const pollingTimedOut = !!pollingStartTimestamp && Date.now() - pollingStartTimestamp > 60000
  if (!response.error && !response.data && !pollingTimedOut) {
    return new Promise(res => {
      setTimeout(() => {
        res(pollStatus(videoId, baseQuery, pollingStartTimestamp || Date.now()))
      }, 750)
    })
  }

  return pollingTimedOut
    ? {
        error: {
          status: 500,
          data: { error: 'getUserStatus polling timed out with no valid response' },
        },
      }
    : response
}

const TAG = 'sizeAdvPupillaryDistance'

type PupillaryDistanceResult = {
  pd: number
  suggestedSize: 'TIGHT' | 'NORMAL' | 'LOOSE'
  faceBreadth: number
}
export const sizeAdvUserApi = createApi({
  reducerPath: 'sizeAdvUserApi',
  tagTypes: [TAG],
  baseQuery: staggeredBaseQueryWithBailOut(
    process.env.REACT_APP_SA_MS_URL ||
      'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtomedicalinfoms/public',
  ),
  endpoints: builder => ({
    getUserPupillaryDistance: builder.query<PupillaryDistanceResult, Id>({
      queryFn: async (videoId, _, __, baseQuery) => {
        const statusResponse = await pollStatus(videoId, baseQuery)

        if (statusResponse.error) {
          return statusResponse
        }

        const pupillaryDistanceResponse = (await baseQuery({
          url: `/${
            process.env.REACT_APP_SA_PUPILLARY_VERSION_API || 'v2'
          }/pupillary-distance/${videoId}`,
          method: 'GET',
        })) as QueryResult<PupillaryDistanceResult>

        return pupillaryDistanceResponse
      },
      providesTags: () => [TAG],
    }),
  }),
})

export const useGetUserPupillaryDistanceQuery = (uuid?: Id) => {
  const videoUuid = uuid || getPictureId() || ''
  const response = sizeAdvUserApi.useGetUserPupillaryDistanceQuery(videoUuid, {
    skip: videoUuid === '',
  })

  return {
    ...response,
    data: response.data,
  }
}

export const useFaceBreadth = () => {
  const dataRes = useGetUserPupillaryDistanceQuery()
  return dataRes.data?.faceBreadth
}
