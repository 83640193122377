import { ILogsConfiguration } from "./LogsConfiguration.js";

class LogsConfigurationRetriever {

  private configurationUrl = "https://luxdeepblue.github.io/vm-remotelog-configuration/configuration.json";
  private currentHostname = window.location.hostname;

  public getConfiguration(): Promise<ILogsConfiguration> {
    return new Promise(
      (resolve) => {
        fetch(this.configurationUrl,
          {
            headers: { "Accept": "application/json", "Accept-Encoding": "gzip" },
            method: "GET",
          },
        ).then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            resolve(this.defaultConfiguration());
          }
        },
        ).then((json) => {
          if (json[this.currentHostname]) {
            const config: ILogsConfiguration = {
              appviewSessionIsActive: json[this.currentHostname].appviewSessionIsActive,
              browserSessionIsActive: json[this.currentHostname].browserSessionIsActive,
              clientLogIsActive: json[this.currentHostname].clientLogIsActive,
              serverLogIsActive: json[this.currentHostname].serverLogIsActive,
              trackingStatusIsActive: json[this.currentHostname].trackingStatusIsActive,
            };
            resolve(config);
          } else {
            resolve(this.defaultConfiguration());
          }
        },
        ).catch((e) => {
          resolve(this.defaultConfiguration());
        });
      },
    );
  }

  private defaultConfiguration(): ILogsConfiguration {
    return {
      appviewSessionIsActive: true,
      browserSessionIsActive: true,
      clientLogIsActive: true,
      serverLogIsActive: false,
      trackingStatusIsActive: true,
    };
  }

}

export { LogsConfigurationRetriever };
