import * as t from 'io-ts'

import { optional } from '@root/utils/types'

interface PopulatedArray {
  readonly PopulatedArray: unique symbol // use `unique symbol` here to ensure uniqueness across modules / packages
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const populatedArrayCheck = <T>(arr: T[]): arr is t.Branded<any[], PopulatedArray> => arr.length > 0
export interface CMSRequest {
  locale: string
  country: string
  area: string
  channelKey: string
  app?: 'sizeadvisor' | 'frameadvisor'
}

export type OnBoardingRequest = CMSRequest

export type PlaylistRequest = CMSRequest

export type BulletPointsRequest = CMSRequest

export const LinkDescriptorElementCodec = t.type({
  id: t.string,
  type: t.string,
  validFrom: t.null,
  validTo: t.null,
  MenuLabel: t.string,
  TileLabel: t.string,
})
export type LinkDescriptorElement = t.TypeOf<typeof LinkDescriptorElementCodec>

export const SubjectTaxonomyCodec = t.type({
  id: t.string,
  name: t.string,
  externalReference: t.string,
})
export type SubjectTaxonomy = t.TypeOf<typeof SubjectTaxonomyCodec>

export const LinkCodec = t.type({
  id: t.string,
  name: optional(t.string),
  type: t.string,
  LinkDescriptor: optional(t.array(LinkDescriptorElementCodec)),
  PageType: optional(t.array(SubjectTaxonomyCodec)),
})
export type Link = t.TypeOf<typeof LinkCodec>

export const ContentTypeCodec = t.union([
  t.literal('image/jpeg'),
  t.literal('image/png'),
  t.literal('video/mp4'),
])
export type ContentType = t.TypeOf<typeof ContentTypeCodec>

export const CropsSizes = t.type({
  width: t.number,
})

export const CropsCodec = t.type({
  name: t.string,
  sizes: t.array(CropsSizes),
})

export const DataCodec = t.type({
  uri: t.string,
  contentType: ContentTypeCodec,
})
export type Data = t.TypeOf<typeof DataCodec>

export const ImageCodec = t.type({
  id: t.string,
  name: t.string,
  title: t.string,
  type: t.string,
  cropUrlTemplate: t.string,
  crops: t.array(CropsCodec),
  data: DataCodec,
})
export type Image = t.TypeOf<typeof ImageCodec>

export const VideoCodec = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  data: DataCodec,
  dataURL: optional(t.string),
})
export type Video = t.TypeOf<typeof VideoCodec>

export const CarouselItemCodec = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  Title: t.string,
  BannerDescription: t.string,
  Image: t.array(ImageCodec),
  VideoMp4: t.array(VideoCodec),
  Link: t.array(LinkCodec),
  subjectTaxonomy: t.array(SubjectTaxonomyCodec),
})
export type CarouselItem = t.TypeOf<typeof CarouselItemCodec>

export const OnBoardingItemCodec = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  title: t.string,
  items: t.brand(t.array(CarouselItemCodec), populatedArrayCheck, 'PopulatedArray'),
  MenuLabel: optional(t.string),
  TileLabel: optional(t.string),
})
export type OnBoardingItem = t.TypeOf<typeof OnBoardingItemCodec>

type OnBoardingLink = string

export const getResultCMSCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    content: getContentCodec(contendDecoder),
  })
export interface Placement<T> {
  viewtype: string
  name: string
  items: T[]
}
export interface Row<T> {
  placements: Placement<T>[]
}

export interface Grid<T> {
  pageLayout: string
  rows: Row<T>[]
}

export const PictureCodec = t.type({})
export type Picture = t.TypeOf<typeof PictureCodec>

export interface Result<T> {
  id: string
  name: string
  type: string
  title: string
  segment: string
  teaserTitle: string
  teaserText: string | null
  grid: Grid<T>
  pictures: Picture[]
  subjectTaxonomy: SubjectTaxonomy[]
  creationDate: string
  link: Link
}
export interface Content<T> {
  numFound: number
  result: Result<T>[]
}
export interface ResultCMS<T> {
  content: Content<T>
}

export const getContentCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    numFound: t.number,
    result: t.array(getResultCodec(contendDecoder)),
  })

export const getResultCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    id: t.string,
    name: t.string,
    type: t.string,
    title: t.string,
    segment: t.string,
    teaserTitle: t.string,
    teaserText: t.union([t.string, t.null]),
    grid: getGridCodec(contendDecoder),
    pictures: t.array(PictureCodec),
    subjectTaxonomy: t.array(SubjectTaxonomyCodec),
    creationDate: t.string,
    link: LinkCodec,
  })

export const getGridCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    pageLayout: t.string,
    rows: t.brand(t.array(getRowCodec(contendDecoder)), populatedArrayCheck, 'PopulatedArray'),
  })

export const getRowCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    placements: t.brand(
      t.array(getPlacementCodec(contendDecoder)),
      populatedArrayCheck,
      'PopulatedArray',
    ),
  })

export const getPlacementCodec = <T extends t.Props>(contendDecoder: t.TypeC<T>) =>
  t.type({
    viewtype: t.string,
    name: t.string,
    items: t.array(contendDecoder),
  })

export const PlaylistItemCodec = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  Title: t.string,
  Description: t.string,
  Image: t.array(ImageCodec),
  Video: t.array(VideoCodec),
  ElevationMOCO: t.array(SubjectTaxonomyCodec),
  ExclusionMOCO: t.array(SubjectTaxonomyCodec),
  Tags: t.array(SubjectTaxonomyCodec),
  ProductType: t.array(SubjectTaxonomyCodec),
  AnalyticsTag: t.array(SubjectTaxonomyCodec),
  DeboostTags: t.array(SubjectTaxonomyCodec),
})
export type PlaylistItem = t.TypeOf<typeof PlaylistItemCodec>

export interface OnBoardingResult {
  title?: string
  image?: string
  description?: string
  links?: OnBoardingLink[]
  video?: string
}

export type PlaylistResult = {
  id: string
  pageTitle: string
  pageDescription: string
  title: string
  description: string
  croopUrl?: string
  imageName?: string
  imageSizes?: number
  imageUri?: string
  videoUri?: string
  productType?: string
  deboostTags: SubjectTaxonomy[]
  elevate: SubjectTaxonomy[]
  exclude: SubjectTaxonomy[]
  tags: SubjectTaxonomy[]
}

type BulletPointsContentItemRef = {
  id: string
  name: string
  externalReference: string
}

export type BulletPointsContentItem = {
  id: string
  name: string
  type: string
  creationDate: string
  title: string
  segment: string
  link: {
    id: string
    type: string
  }
  PointDescription: string
  Key: BulletPointsContentItemRef[]
  subjectTaxonomy: BulletPointsContentItemRef[]
  validFrom?: string
  validTo?: string
}

export type BulletPointsContent = {
  numFound: number
  result: BulletPointsContentItem[]
}

export type BulletPointsResult = {
  content: BulletPointsContent
}
