enum PrivacyLocalizationKey {
  PRIVACY_AGREEMENT_TITLE = 'PRIVACY_AGREEMENT_TITLE',
  PRIVACY_AGREEMENT_TEXT = 'PRIVACY_AGREEMENT_TEXT',
  PRIVACY_FIRST_AGREEMENT = 'PRIVACY_FIRST_AGREEMENT',
  PRIVACY_IMPLICIT_AGREEMENT = 'PRIVACY_IMPLICIT_AGREEMENT',
  PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS = 'PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS',
  PRIVACY_IMPLICIT_TERMS_PRIVACY_CONJUNCTION = 'PRIVACY_IMPLICIT_TERMS_PRIVACY_CONJUNCTION',
  PRIVACY_IMPLICIT_PRIVACY_POLICY = 'PRIVACY_IMPLICIT_PRIVACY_POLICY',
  PRIVACY_BUTTON_SUBMIT = 'PRIVACY_BUTTON_SUBMIT',
  PRIVACY_BUTTON_CANCEL = 'PRIVACY_BUTTON_CANCEL',
  BIPA_CITIZEN_DECLARATION = 'BIPA_CITIZEN_DECLARATION',
  BIPA_CITIZEN_QUESTION = 'BIPA_CITIZEN_QUESTION',
  BIPA_FORM_TITLE = 'BIPA_FORM_TITLE',
  BIPA_FORM_SUBTITLE = 'BIPA_FORM_SUBTITLE',
  BIPA_FORM_NAME = 'BIPA_FORM_NAME',
  BIPA_FORM_SURNAME = 'BIPA_FORM_SURNAME',
  BIPA_FORM_EMAIL = 'BIPA_FORM_EMAIL',
  BIPA_FORM_ERROR = 'BIPA_FORM_ERROR',
  BIPA_FORM_ERROR_MAIL = 'BIPA_FORM_ERROR_MAIL',
  BIPA_FORM_MANDATORY_FIELD = 'BIPA_FORM_MANDATORY_FIELD',
  BIPA_IMPLICIT_AGREEMENT = 'BIPA_IMPLICIT_AGREEMENT',
  BIPA_IMPLICIT_TERMS_AND_CONDITIONS = 'BIPA_IMPLICIT_TERMS_AND_CONDITIONS',
  BIPA_IMPLICIT_TERMS_PRIVACY_CONJUNCTION = 'BIPA_IMPLICIT_TERMS_PRIVACY_CONJUNCTION',
  BIPA_IMPLICIT_PRIVACY_POLICY = 'BIPA_IMPLICIT_PRIVACY_POLICY',
  BIPA_FORM_IMPLICIT_AGREEMENT = 'BIPA_FORM_IMPLICIT_AGREEMENT',
  BIPA_FORM_BIPA_AGREEMENT = 'BIPA_FORM_BIPA_AGREEMENT',
  BIPA_FORM_BUTTON_SUBMIT = 'BIPA_FORM_BUTTON_SUBMIT',
  BIPA_FORM_BUTTON_CANCEL = 'BIPA_FORM_BUTTON_CANCEL',
  BIPA_NO = 'BIPA_NO',
  BIPA_SERVER_ERROR = 'BIPA_SERVER_ERROR',
  BIPA_YES = 'BIPA_YES',
}

type PrivacyLocalization = {
  [key in PrivacyLocalizationKey]?: string;
}

export {
  PrivacyLocalizationKey,
  PrivacyLocalization
};
