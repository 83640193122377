import { CameraFacingMode } from './CameraFacingMode';

const defaultContraints = {
  audio: false,
  video: {
    facingMode: 'user',
    height: { ideal: 720, max: 720 },
    width: { ideal: 1280, max: 1280 },
  },
};

const cameraBlackList = [
  'Snap Camera',
];

class WebCamConstraints {

  public static getConstraints(
    w: number,
    h: number,
    cameraFacingMode: CameraFacingMode,
  ): Promise<MediaStreamConstraints> {
    return WebCamConstraints.getDeviceIdConstraints().then((deviceIds) => {
      return {
        ...defaultContraints,
        audio: false,
        video: {
          deviceId: deviceIds,
          facingMode: cameraFacingMode,
          height: { ...defaultContraints.video.height, ideal: h },
          width: { ...defaultContraints.video.width, ideal: w },
        },
      };
    });
  }

  // Filter out blacklisted cameras, for example we don't use want to use Snap Camera
  // The method leverage on navigator.mediaDevices and
  // there are two possible reasons for navigator.mediaDevices to not available:
  // 1 - the browser does not support webcams
  // 2 - the browser got a NativeBridge available
  // both points are handled by errors and NativeBridge so should be safe to return undefined in such cases
  private static getDeviceIdConstraints(): Promise<string[] | undefined> {
    return navigator.mediaDevices && navigator.mediaDevices.enumerateDevices().then((devices) => {
      return devices.filter((device) => {
        return device.kind === 'videoinput' && !cameraBlackList.includes(device.label);
      }).map((device) => device.deviceId);
    }) || Promise.resolve(undefined);
  }
}

export { WebCamConstraints, defaultContraints };
