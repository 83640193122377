import { TransitionColorHelper } from '../../helpers/TransitionColorHelper';
import { createLogger } from '../../remotelog/VmLogger';

const logger = createLogger('SetTransitionOverrideColor');

const setTransitionOverrideColor = (transitionColor: TransitionColor): void => {
  logger.debug('setting new override color', transitionColor);
  TransitionColorHelper.setTransitionOverrideColor(transitionColor);
};

export {
  setTransitionOverrideColor
};
