import { name, version } from "../../../../../package.json";
import { DBRemoteLogFactory } from "../../../../adapter/db/factory/DBRemoteLogFactory";
import { RemoteLogAdapter } from "../../../../adapter/rest/RemoteLogAdapter";
import { LogMessage } from "../../../model/LogMessage";
import { RemoteLogConfig } from "../../../RemoteLogConfig";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "RemoteLogConsumer");

const dbInstance = () => {
  return DBRemoteLogFactory.getInstance();
};

const messageAdapter = () => {
  return new RemoteLogAdapter();
};

const consumeDbRows = () => {
  const dbase = dbInstance().getDB();

  dbase.iterate((
    value: LogMessage,
    key: string
  ) => {
    if (value === null) {
      // console.error("undefined: ", { key });
    }
    messageAdapter().log(
      new LogMessage(value.level, value.logName, value.message)
    ).then(() => {
      logger.debug("consumed stored log message: {}", JSON.stringify(value));
      // console.info("consumed: ", {key, value});
      return dbase.removeItem(key);
    }).catch(() => {
      // console.error("failed: ", { key });
    });
  }).then(() => {
    logger.debug("all logs consumed from local indexdb");
  });
};

class RemoteLogConsumer {

  public consume() {
    const time1 = window.performance.now();
    consumeDbRows();
    const time2 = window.performance.now();
    logger.debug("logs consumed in {} ms", time2 - time1);
  }

}

export { RemoteLogConsumer };
