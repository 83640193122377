import { configure, getLogger, LogAppender, LogLevel } from "@log4js2/core";
import { v4 as uuid } from "uuid";
import { LogsConfig } from "../config/LogsConfig";
import { RemoteLogAppender } from "./appender/RemoteLogAppender";

const VM_BROWSERSESSION_UUID = "vm.browsersessionuuid.20200120";
const VM_LOGLEVEL_OVERRIDE = "vm.loglevel.override";

const defaultLevel = LogLevel.DEBUG;
const defaultVirtualConsole = false;
const defaultLayout = "%d [%p] %c - %m";

class RemoteLogConfig {

  public static getInstance() {
    if (!RemoteLogConfig.instance) {
      RemoteLogConfig.instance = new RemoteLogConfig();
    }
    return RemoteLogConfig.instance;
  }

  private static instance: RemoteLogConfig;

  private storage = window.localStorage;
  private configured = false;

  private browserUUID: string = "";

  public static get storageIdentifier() {
    return VM_BROWSERSESSION_UUID;
  }

  private constructor() {
    this.createSessionId();
    this.configureLevel(defaultLevel);
    (window as any).VMAvailableLogLevels = LogLevel;
    (window as any).VMLogLevelsOverrideKey = VM_LOGLEVEL_OVERRIDE;
  }

  public getLogger(moduleName: string, moduleVersion: string, loggerName: string) {
    return getLogger([moduleName, moduleVersion, loggerName].join("."));
  }

  public getLoggerInfo(moduleName: string, moduleVersion: string, loggerName: string) {
    return getLogger([moduleName, moduleVersion, loggerName].join("."));
  }

  public get currentUuid() {
    return this.browserUUID;
  }

  private configureLevel(logLevel: LogLevel) {
    const logOverride = this.storage.getItem(VM_LOGLEVEL_OVERRIDE);
    if (logOverride !== null) {
      const logLevelOverride = logOverride as unknown as LogLevel;
      // tslint:disable-next-line: no-console
      console.warn("overriding loglevel with localstorage one ->", logLevelOverride);
      this.configureLog4js2(["Console", RemoteLogAppender], logLevelOverride);
    } else {
      try {
        const isActive = LogsConfig.getInstance().isClientLogActive();
        this.configureLog4js2(["Console", RemoteLogAppender], LogLevel.INFO);
        isActive.then((value) => {
          this.configureLog4js2(["Console", RemoteLogAppender], value ? LogLevel.INFO : LogLevel.ERROR);
          this.configured = true;
        }).catch((e) => {
          // tslint:disable-next-line: no-console
          console.error(e);
        });
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.error(e);
        this.configureLog4js2(["Console", RemoteLogAppender], defaultLevel);
      }
    }
  }

  private configureLog4js2(logAppenders: Array<string | typeof LogAppender>, logLevel: LogLevel) {
    if (!this.configured) {
      configure({
        appenders: logAppenders,
        layout: defaultLayout,
        level: logLevel,
        virtualConsole: defaultVirtualConsole,
      });
      const appenders = logAppenders.map((appender) => this.logAppenderName(appender));
      // tslint:disable-next-line: no-console
      console.info(`appenders       : ${appenders}
layout          : ${defaultLayout}
level           : ${logLevel}
virtual console : ${defaultVirtualConsole}`);
    }
  }

  private logAppenderName(appender: string | typeof LogAppender): string {
    let name = "n.a";
    switch (typeof appender) {
      case "string":
        name = "Console";
        break;
      case "function":
        name = "RemoteLogAppender";
        break;
      default:
        name = "n.a.[" + typeof appender + "]";
        break;
    }
    return name;
  }

  private createSessionId() {
    let currentUuid = this.storage.getItem(VM_BROWSERSESSION_UUID);
    if (!currentUuid) {
      const newUuid = uuid();
      if (newUuid) {
        this.storage.setItem(VM_BROWSERSESSION_UUID, newUuid);
        currentUuid = newUuid;
      }
    }
    this.browserUUID = currentUuid || "cantcreateuuid";
  }

}

export { RemoteLogConfig };
