import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { MicroserviceEnvironment } from '@luxottica/vto-microservices';
import { name, version } from '../../../package.json';
import { DefaultInitializationOptions } from '../../constants/DefaultInitializationOptions';
import { FeatureInitializationError, FeatureServerError, MissingAuthKeyError } from '../../errors/errors';
import { defaultFeatures, getFeatureConfig } from '../../helpers/FeatureConfigHelper';
import { Features } from '../../interfaces/FeatureLicensingTypes';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'FeatureLicensingService');

let isInitialized = false;
let currentLicenseKey: string;
let currentEnvironment: MicroserviceEnvironment = DefaultInitializationOptions.environment;
let featureSupportCache: Map<Features, boolean>;

const featureListUrl = (): string => {
  if (currentLicenseKey === undefined) {
    throw new MissingAuthKeyError();
  }

  const config = getFeatureConfig(currentEnvironment);
  logger.debug('Getting feature list for key {} using config {}', currentLicenseKey, JSON.stringify(config));
  return `${config.baseUrl}${config.getAllFeaturesPath}${currentLicenseKey}`;
};

const getFeatureMap = (): Promise<Map<Features, boolean>> => {
  return new Promise((resolve, reject) => {
    try {
      const url = featureListUrl();
      resolve(url);
    } catch (e) {
      reject(e);
    }

  }).then((url: string) => {
    return fetch(url);

  }).then((res) => {
    return (res.ok) ? res.json() : Promise.reject(new FeatureServerError(`${res.status} - ${res.statusText}`));

  }).then((json: string[]) => {
    const featureMap = new Map<Features, boolean>();
    Object.values(Features).forEach((feature) => {
      featureMap.set(feature, json.includes(feature.toString()));
    });
    logger.debug('client feature list has been imported {}', [...featureMap.entries()]);
    return featureMap;

  }).catch((e) => {
    const error = (e instanceof MissingAuthKeyError || e instanceof FeatureServerError) ? e : new FeatureServerError(e.message);
    logger.error(error);
    throw error;
  });
};

const reset = () => {
  isInitialized = false;
  return getFeatureMap().then((featureMap) => {
    featureSupportCache = featureMap;
    isInitialized = true;
  }).catch((e: Error) => {
    featureSupportCache = defaultFeatures;
    throw e;
  });
};

const FeatureLicensingService = {
  initialize: (
    licenseKey: string,
    environment: MicroserviceEnvironment
  ): Promise<void> => {

    let shouldReset = false;

    if (!isInitialized) {
      shouldReset = true;
    }

    if (!!licenseKey && licenseKey !== currentLicenseKey) {
      currentLicenseKey = licenseKey;
      shouldReset = true;
    }
    if (!!environment && environment !== currentEnvironment) {
      currentEnvironment = environment;
      shouldReset = true;
    }

    return (shouldReset) ? reset() : Promise.resolve();
  },

  isInitialized: (): boolean => { return isInitialized; },

  isFeatureAuthorized(...features: Features[]): boolean {
    if (!!features && !!featureSupportCache) {
      return features.every(feature => !!featureSupportCache.get(feature));
    } else {
      throw new FeatureInitializationError();
    }
  }
};

export {
  FeatureLicensingService
};
