import { VtoCaptureOptions } from '../interfaces/CaptureParameters';
import { DefaultTrackingOptions } from './DefaultTrackingOptions';

const DefaultCaptureDuration = 10000;

const DefaultVtoCaptureOptions: VtoCaptureOptions = {
  tracking: DefaultTrackingOptions,
  showLoading: true,
  enableMachineLearning: false,
  enableVto: true
};

export { DefaultCaptureDuration, DefaultVtoCaptureOptions };
