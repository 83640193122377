import * as t from 'io-ts'

export const GlassFilterKeysDecoder = t.union([
  t.literal('productType'),
  t.literal('productType'),
  t.literal('gender'),
  t.literal('mood'),
])

export type GlassFilterKeys = t.TypeOf<typeof GlassFilterKeysDecoder>

export interface GlassFilterValues {
  [key: string]: string[]
}

export const GlassFilterConfigDecoder = t.array(GlassFilterKeysDecoder)
export type GlassFilterConfig = t.TypeOf<typeof GlassFilterConfigDecoder>

export const ProductTypeDecoder = t.union([
  t.literal('sunglasses'),
  t.literal('eyeglasses'),
  t.literal('SUNGLASSES'),
  t.literal('EYEGLASSES'),
])
export const DefaultProductTypeDecoder = ProductTypeDecoder

export const ProductTypeMapDecoder = t.union([t.literal('sunglasses'), t.literal('eyeglasses')])

export type ProductType = t.TypeOf<typeof ProductTypeDecoder>
export type SimpleProductType = t.TypeOf<typeof ProductTypeMapDecoder>

export const FaceFilterKeysDecoder = t.union([
  t.literal('age'),
  t.literal('face_shape'),
  t.literal('face_length'),
  t.literal('hair_color'),
  t.literal('eye_color'),
])
export type FaceFilterKeys = t.TypeOf<typeof FaceFilterKeysDecoder>

// export interface FaceFilterValues {
// 	[key: string]: string[];
// }

export const FaceFilterConfigDecoder = t.array(FaceFilterKeysDecoder)
export type FaceFilterConfig = t.TypeOf<typeof FaceFilterConfigDecoder>

export const measurementUnitsDecoder = t.union([t.literal('mm'), t.literal('inch')])

export type MeasurementUnits = t.TypeOf<typeof measurementUnitsDecoder>

export const defaultTryOnPageDecoder = t.union([
  t.literal('/try-on-live'),
  t.literal('/picture-mode'),
  t.literal('/video-mode'),
])

export type TryOnPage = t.TypeOf<typeof defaultTryOnPageDecoder>
