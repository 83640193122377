import uuid from 'uuid';
import { IMockStream } from './IMockStream';
import { MockStreamTrack } from './MockStreamTrack';

class MockStream implements IMockStream {
  public readonly id: string;
  private tracks: MockStreamTrack[] = [];

  constructor(resolution?: any | undefined) {
    this.id = uuid();
    this.tracks.push(new MockStreamTrack());
  }
  public getTracks(): any[] {
    return this.tracks;
  }
  public getVideoTracks(): any[] {
    return this.tracks;
  }
}

export { MockStream };
