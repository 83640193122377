import { name, version } from "../../../package.json";
import { IRemoteTrackingStatus } from "../../domain/model/IRemoteTrackingStatus";
import { RemoteLogConfig } from "../../domain/RemoteLogConfig";
import { VmAnalyticsApiConfig } from "../../domain/service/config/VmAnalyticsApiConfig";
import { sendXhrRequest } from "../../helpers/RequestHelper";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "TrackingStatusAdapter");

class TrackingStatusAdapter implements LogAdapter<IRemoteTrackingStatus> {

  private config = new VmAnalyticsApiConfig();

  public log(message: IRemoteTrackingStatus): Promise<boolean> {
    const sessionId = RemoteLogConfig.getInstance().currentUuid;
    if (sessionId) {
      let jsonBody;
      if (message.fps) {
        jsonBody = JSON.stringify({
          browserSessionUuid: sessionId,
          date: new Date().toISOString(),
          fps: message.fps,
        });
      } else {
        jsonBody = JSON.stringify({
          browserSessionUuid: sessionId,
          date: new Date().toISOString(),
          trackingCode: message.trackingCode,
          trackingText: message.trackingText,
        });
      }
      const requestUrl = this.config.trackingStatusApiEndpoint;

      return sendXhrRequest(
        "POST",
        requestUrl,
        jsonBody,
        "",
        [],
        {
          "Accept": "application/json",
          "Content-type": "application/json",
          // "Referrer-Policy": "unsafe-url"
        },
      ).catch((e) => {
        return Promise.reject(e);
      });

    } else {
      console.warn("sessionId not available");
      return Promise.reject("sessionId not available");
    }
  }
}

export { TrackingStatusAdapter };
