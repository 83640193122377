declare global {
  // tslint:disable-next-line:interface-name
  interface Element {
    setAttribute(name: string, value: null): void;
  }
}

class WebCamVideoStream {
  private video: HTMLVideoElement;

  public get currentTime(): number {
    return this.video.currentTime;
  }

  public get videoWidth(): number {
    return this.video.videoWidth;
  }

  public get videoHeight(): number {
    return this.video.videoHeight;
  }

  public get defaultMuted(): boolean {
    return this.video.defaultMuted;
  }

  public set defaultMuted(value: boolean) {
    this.video.defaultMuted = value;
  }

  public get width(): number {
    return this.video.width;
  }

  public set width(value: number) {
    this.video.width = value;
  }

  public get height(): number {
    return this.video.height;
  }

  public set height(value: number) {
    this.video.height = value;
  }

  constructor(options: {
                target: string,
                id?: string,
                className?: string,
              },
              mediaStream: MediaStream) {

    const targetElement = document.getElementById(options.target);
    if (!targetElement) {
      throw new Error('Impossible to start video on undefined element -> ' + options.target);
    }
    this.video = document.createElement('video');
    this.video.id = options.id || '';
    this.video.className = options.className || '';
    this.video.muted = true;
    this.video.setAttribute('playsinline', null);
    this.video.srcObject = mediaStream;

    targetElement.appendChild(this.video);
  }

  // tslint:disable-next-line:max-line-length
  public addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions | undefined) {
    this.video.addEventListener(type, listener, options);
  }

  // tslint:disable-next-line:max-line-length
  public removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions | undefined) {
    this.video.removeEventListener(type, listener, options);
  }

  public play(): Promise<void> {
    return this.video.play();
  }

  public pause(): void {
    this.video.pause();
  }

  public get image() {
    return this.video;
  }
}

export { WebCamVideoStream };
