import { CatalogConfiguration } from '../../interfaces/CatalogConfiguration';
import { UpcAvailability } from '../../interfaces/UpcAvailability';

  // inside the QA tool every asset is considered fully available
export const qaToolAvailability = (upc: string, config: CatalogConfiguration): UpcAvailability => {
  return {
    upc: upc,
    available: { 256: true, 512: true },
    transitions: { 256: true, 512: true },
    lods: [0, 1, 2],
    getUpc: () => upc,
    // we need somehow to split between b3d and gltf files somehow
    glassesType: config.env.includes('GLTF') ? 'GLTF' : 'B3D',
    isAvailable: () => true,
    isTransition: () => true,
  };
};
