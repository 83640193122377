import { WasmEnvironment } from '@luxottica/vm-webassembly';
import { InitializationOptions } from '../interfaces/InitializationTypes';

export const DefaultInitializationOptions: InitializationOptions = {
  key: undefined,
  analyticsConfig: {},
  brand: undefined,
  channel: undefined,
  storeId: undefined,
  locale: 'en-US',
  environment: 'PRODUCTION',
  trackerEnvironment: WasmEnvironment.PROD,
  sessionTimeout: false
};
