import { BipaManager } from '@luxottica/bipa-manager';
import { FeatureUnavailableError, NotInitializedError } from '../../errors/errors';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { RequestHelper } from '../../helpers/RequestHelper';
import { Features } from '../../interfaces/FeatureLicensingTypes';
import { FeatureLicensingService } from '../service/FeatureLicensingService';
import { vmIsInitialized, VmStore } from '../VmCore';

interface GetBipaVersionResponse {
  version: number;
  description: string;
  text: string;
}

const getBipaVersion = (): Promise<GetBipaVersionResponse> => {
  if (!vmIsInitialized()) {
    return Promise.reject(new NotInitializedError());
  }

  if (!FeatureLicensingService.isFeatureAuthorized(Features.VM_GET_BIPA_VERSION)) {
    return Promise.reject(new FeatureUnavailableError(Features.VM_GET_BIPA_VERSION));
  }

  return RequestHelper.login(
    VmStore.getState().app.environment
  ).then(() => {
    return BipaManager.getBipaVersion({
      environment: VmStore.getState().app.environment,
      locale: VmStore.getState().user.locale,
      accessToken: VmStore.getState().app.auth.accessToken,
      refreshToken: VmStore.getState().app.auth.refreshToken,
      onAuthUpdated: CallbackHelper.onAuthUpdated
    });
  });
};

export {
  getBipaVersion,
  GetBipaVersionResponse
};
