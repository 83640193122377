import { PoseTracker } from '@luxottica/vm-posetracker';
import { FeatureUnavailableError } from '../../errors/errors';
import { AnalyticsHelper, setSessionTimeout } from '../../helpers/AnalyticsHelper';
import { setCallbacks, setPrivacyCallbacks } from '../../helpers/CallbackHelper';
import { InitializationHelper } from '../../helpers/InitializationHelper';
import { ParameterHelper } from '../../helpers/ParameterHelper';
import { Features } from '../../interfaces/FeatureLicensingTypes';
import { InitializationParams, InitializationReturns } from '../../interfaces/InitializationTypes';
import { AppActions } from '../../reducers/appReducer';
import { UserActions } from '../../reducers/userReducer';
import { createLogger, sendAppViewSession, sendError } from '../../remotelog/VmLogger';
import { FeatureLicensingService } from '../service/FeatureLicensingService';
import { configureVmStore, VmStore } from '../VmCore';

const logger = createLogger('Initialize');

let errorListenerAdded = false;

const initialize = (params: InitializationParams): Promise<InitializationReturns> => {

  if (!errorListenerAdded) {
    window.addEventListener('error', (event) => { sendError(event.error); });
    errorListenerAdded = true;
  }

  return FeatureLicensingService.initialize(
    params.options.key,
    params.options.environment
  ).then(() => {

    const validatedParams = ParameterHelper.mergeInitializationParams(params);
    if (validatedParams.privacy.bipaActive && !FeatureLicensingService.isFeatureAuthorized(Features.VM_BIPA)) {
      throw new FeatureUnavailableError(Features.VM_BIPA);
    }

    const options = validatedParams.options;

    AnalyticsHelper.initialize(
      options.environment,
      options.analyticsConfig, {
        brand: options.brand,
        channel: options.channel,
        locale: options.locale,
        storeId: options.storeId,
        storeRegion: options.storeRegion,
        storeCompany: options.storeCompany,
        globalStoreId: options.globalStoreId,
      }
    );
    setSessionTimeout(options.sessionTimeout);

    logger.debug('initializing virtual mirror [channel={},brand={},locale={},storeId={}]',
      options.channel, options.brand, options.locale, options.storeId);

    return Promise.all([
      InitializationHelper.initialize(validatedParams.options, validatedParams.privacy),
      Promise.resolve(options)
    ]);

  }).then((initReturn) => {
    if (VmStore === undefined) {
      configureVmStore();
    }

    setCallbacks(params.options.callbacks);
    setPrivacyCallbacks(params.privacyCallbacks);

    const userStore = initReturn[0].userStore;
    const supportedFeatures = initReturn[0].supportedFeatures;
    const options = initReturn[1];

    // warm up the posetracker
    PoseTracker.warmUp(options.trackerEnvironment);

    if (options.environment !== VmStore.getState().app.environment) {
      VmStore.dispatch(AppActions.resetAuth());
    }

    VmStore.dispatch(AppActions.setAuth({ accessToken: params.auth?.accessToken, refreshToken: params.auth?.accessToken }));
    VmStore.dispatch(AppActions.setEnvironment(options.environment));
    VmStore.dispatch(AppActions.setPrivacyOptions(params.privacy));
    VmStore.dispatch(AppActions.setLoadingStatus({ type: 'INITIALIZATION', status: true }));
    VmStore.dispatch(UserActions.setUser(userStore));

    logger.info('virtual mirror initialized with options {}', JSON.stringify(options));
    AnalyticsHelper.onInitializeSuccess();
    sendAppViewSession.initialize(options);
    return {
      userId: undefined,
      locale: userStore.locale,
      supportedFeatures: supportedFeatures
    };

  }).catch((e) => {
    logger.error('virtual mirror initialization failed with options {} [{}]', JSON.stringify(params.options), JSON.stringify(e));
    AnalyticsHelper.onInitializeError();
    sendAppViewSession.initializeError(params.options);
    throw e;
  });
};

export {
  initialize
};
