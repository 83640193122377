import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../../package.json';
import { RecordingError } from '../errors/errors';

let mediaRecorder: MediaRecorder;
let chunks: Blob[] = [];

const defaultMimeType = 'video/webm;codecs=vp9';
const iosMimeType = 'video/mp4';
const ffMimeType = 'video/webm';
let currentFileType: 'mp4' | 'webm';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'RecordingHelper');

const RecordingHelper = {
  initialize: (
    mediaStream: MediaStream,
    onComplete: (videoData: Blob[]) => void
  ) => {
    RecordingHelper.reset();

    let mimeType;
    if (MediaRecorder.isTypeSupported(defaultMimeType)) {
      mimeType = defaultMimeType;
      currentFileType = 'webm';
    } else if (MediaRecorder.isTypeSupported(iosMimeType)) {
      mimeType = iosMimeType;
      currentFileType = 'mp4';
    } else if (MediaRecorder.isTypeSupported(ffMimeType)) {
      mimeType = ffMimeType;
      currentFileType = 'webm';
    } else {
      throw new RecordingError('recording video mime type unsupported');
    }

    mediaRecorder = new MediaRecorder(mediaStream, { mimeType: mimeType });

    mediaRecorder.onstart = () => { 
      logger.debug('media recorder started');
     };

    mediaRecorder.ondataavailable = (blobEvent: BlobEvent) => {
      logger.debug('media recorder data available: size={} mimeType={}', blobEvent.data.size, mediaRecorder.mimeType);
      chunks.push(blobEvent.data);
      onComplete(chunks);
    };
  },

  startRecording: () => {
    if (!!mediaRecorder && mediaRecorder.state !== 'recording') {
      mediaRecorder.start(100000000000);
    }
  },

  stopRecording: () => {
    if (!!mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }
  },

  getFileType: (): 'webm' | 'mp4' => {
    return (!!currentFileType) ? currentFileType : undefined;
  },

  reset: () => {
    mediaRecorder = undefined;
    chunks = [];
  }
};

export {
  RecordingHelper
};
