import { RemoteLogAppender } from "./domain/appender/RemoteLogAppender";
import { IRemoteAppViewSession } from "./domain/model/IRemoteAppViewSession";
import { IRemoteBrowserSession } from "./domain/model/IRemoteBrowserSession";
import { IRemoteTrackingStatus } from "./domain/model/IRemoteTrackingStatus";
import { RemoteLogConfig } from "./domain/RemoteLogConfig";
import { JobsStarter } from "./domain/service/job/JobsStarter";
import { AppViewSessionService } from "./domain/service/log/producer/AppViewSessionService";
import { BrowserSessionService } from "./domain/service/log/producer/BrowserSessionService";
import { TrackingStatusService } from "./domain/service/log/producer/TrackingStatusService";

class RemoteLog {

  private browserSessionService = new BrowserSessionService();
  private appViewSessionService = new AppViewSessionService();
  private trackingStatusService = new TrackingStatusService();

  public constructor() {
    JobsStarter.getInstance();
  }

  public sendBrowserSession(browserSession: IRemoteBrowserSession) {
    this.browserSessionService.log(browserSession);
  }

  public sendAppViewSession(...appViewSession: IRemoteAppViewSession[]) {
    appViewSession.forEach((item) => {
      this.appViewSessionService.log(item);
    });
  }

  public sendTracking(...trackingStatus: IRemoteTrackingStatus[]) {
    trackingStatus.forEach((item) => {
      this.trackingStatusService.log(item);
    });
  }

  public sendFps(...trackingStatus: IRemoteTrackingStatus[]) {
    trackingStatus.forEach((item) => {
      this.trackingStatusService.log(item);
    });
  }

}

export { RemoteLog, RemoteLogAppender, RemoteLogConfig };
