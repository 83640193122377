declare global {
  // tslint:disable-next-line:interface-name
  interface Window { webkit: any; }
}

class WebRtcDetection {

  public static getInstance() {
    if (!WebRtcDetection.instance) {
      WebRtcDetection.instance = new WebRtcDetection();
    }
    return WebRtcDetection.instance;
  }

  private static instance: WebRtcDetection;

  public isUserMediaSupported(): boolean {
    return this.isMediaDeviceAvailable() || this.isNativeBridgePresent();
  }

  public isVideoInputDeviceAvailable() {
    let videoInputDeviceAvailability: Promise<boolean>;
    try {
      if (this.isMediaDeviceAvailable()) {
        videoInputDeviceAvailability = navigator.mediaDevices.enumerateDevices()
          .then((devices) => devices.some((device) => device.kind === "videoinput"));
      } else if (this.isNativeBridgePresent()) {
        videoInputDeviceAvailability = Promise.resolve(true);
      } else {
        videoInputDeviceAvailability = Promise.resolve(false);
      }
    } catch (e) {
      videoInputDeviceAvailability = Promise.resolve(false);
    }
    return videoInputDeviceAvailability;
  }

  private isMediaDeviceAvailable(): boolean {
    return (typeof navigator.mediaDevices === "object") && (typeof navigator.mediaDevices.getUserMedia === "function");
  }

  private isNativeBridgePresent(): boolean {
    return (
      // TODO do we need to create a vm-nativebridge module in order to centralise this check?
      typeof window.webkit === "object" &&
      typeof(window.webkit.messageHandlers) === "object" &&
      typeof(window.webkit.messageHandlers.startNativeCam) === "object"
    );
  }
}

export { WebRtcDetection };
