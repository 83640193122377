import { BipaManager } from '@luxottica/bipa-manager';
import { FeatureUnavailableError, NotInitializedError } from '../../errors/errors';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { RequestHelper } from '../../helpers/RequestHelper';
import { Features } from '../../interfaces/FeatureLicensingTypes';
import { FeatureLicensingService } from '../service/FeatureLicensingService';
import { vmIsInitialized, VmStore } from '../VmCore';

interface SaveBipaEntryParams {
  firstName: string;
  lastName: string;
  email: string;
  bipaVersion: number;
}

const saveBipaEntry = (
  params: SaveBipaEntryParams
): Promise<Response> => {
  if (!vmIsInitialized()) {
    return Promise.reject(new NotInitializedError());
  }

  if (!FeatureLicensingService.isFeatureAuthorized(Features.VM_SAVE_BIPA_ENTRY)) {
    return Promise.reject(new FeatureUnavailableError(Features.VM_SAVE_BIPA_ENTRY));
  }

  return RequestHelper.login(
    VmStore.getState().app.environment
  ).then(() => {
    return BipaManager.saveBipaEntry({
      environment: VmStore.getState().app.environment,
      source: VmStore.getState().app.privacy.source,
      accessToken: VmStore.getState().app.auth.accessToken,
      refreshToken: VmStore.getState().app.auth.refreshToken,
      bipaVersion: params.bipaVersion,
      formData: {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email
      },
      onAuthUpdated: CallbackHelper.onAuthUpdated
    });
  });
};

export {
  saveBipaEntry,
  SaveBipaEntryParams
};
