import React from 'react';
import { LocalizationHelper } from '../helpers/LocalizationHelper';
import { PrivacyLocalizationKey } from '../interfaces/PrivacyLocalization';
import './YesNoCheckBox.scss';

interface YesNoCheckBoxProps {
  tabIndex: number;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const YesNoCheckBox = (props: YesNoCheckBoxProps) => {

  return (
    <div className='yes-no-checkbox'>
      <span className={`no${props.checked ? '' : ' selected'}`}>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_NO)}</span>
      <label className='switch'>
        <input type='checkbox' tabIndex={props.tabIndex} checked={props.checked}
          onChange={(event) => { props.onChange(event.target.checked); }}/>
        <span className='slider round'/>
      </label>
      <span className={`yes${props.checked ? ' selected' : ''}`}>{LocalizationHelper.getText(PrivacyLocalizationKey.BIPA_YES)}</span>
    </div>
  );
};

export {
  YesNoCheckBox
};
