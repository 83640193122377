const defaultLocale = 'en-US';
const defaultLanguage = 'en';

const localeMap = {
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  sv: 'sv-SE',
  tr: 'tr-TR',
  zh: 'zh-CN',
};

declare interface MirrorTranslation {
  GENERIC_ERROR: {
    ERROR: string,
    BUTTON: string,
    SUBTEXT: string
  };
  PERMISSION_ERROR: {
    ERROR: string,
    SUBTEXT: string,
  };
  WEBCAM_ERROR: {
    ERROR: string,
    SUBTEXT: string,
  };
  MANUAL_START: {
    BUTTON: string,
    TEXT: string,
  };
  GUIDANCE: {
    UNKNOWN: string,
    DEFAULT: string,
    MOVE_CLOSER: string,
    MOVE_FARTHER: string,
    STAY_STILL: string,
    LOOK_UP: string,
    LOOK_RIGHT: string,
    LOOK_DOWN: string,
    LOOK_LEFT: string,
    TILT_LEFT: string,
    TILT_RIGHT: string,
    GLASSES?: string,
  };
  UI: {
    FIT_ME_BETTER?: string,
    USE_TWO_FINGERS: string,
    TAP_TO_FREEZE: string,
    DESKTOP_TAP_TO_FREEZE: string
  };
}

declare interface Translation {
  MIRROR: MirrorTranslation;
}

const defaultTranslations: Translation = {
	MIRROR: {
    GENERIC_ERROR: {
      BUTTON: 'Continue',
      ERROR: 'An error occured',
      SUBTEXT: 'Please check your camera permissions and try to reload the page.'
    },
    PERMISSION_ERROR: {
      ERROR: 'You must allow access to your camera to use Virtual Mirror.',
      SUBTEXT: 'Click on your browser Share or Allow button to enable your webcam.'
    },
    WEBCAM_ERROR: {
      ERROR: 'We could not detect your webcam.',
      SUBTEXT: 'Enable webcam access in your browser and refresh the page to continue.'
    },
    MANUAL_START: {
      BUTTON: 'Start the experience',
      TEXT: 'You are probably in Low Power Mode. The experience may be degraded.'
    },
    GUIDANCE: {
      DEFAULT: 'Place your head in the middle of the screen',
      GLASSES: 'Please remove your glasses',
      LOOK_DOWN: 'Look down',
      LOOK_LEFT: 'Look to your left',
      LOOK_RIGHT: 'Look to your right',
      LOOK_UP: 'Look up',
      MOVE_CLOSER: 'Move closer to the oval',
      MOVE_FARTHER: 'Move farther from the screen',
      STAY_STILL: 'Good, stay still while fitting glasses',
      TILT_LEFT: 'Tilt to your left',
      TILT_RIGHT: 'Tilt to your right',
      UNKNOWN: 'Is anyone there?'
    },
    UI: {
      DESKTOP_TAP_TO_FREEZE: 'Hold to freeze the screen or drag the pointer along the nose to adjust the frames',
      FIT_ME_BETTER: 'Fit me better',
      TAP_TO_FREEZE: 'Tap and hold to freeze the screen',
      USE_TWO_FINGERS: 'Use two fingers to adjust on the nose'
    },
	}
};

const LocalizationDefaults = {
  locale: defaultLocale,
  language: defaultLanguage,
  localeMap,
  translations: defaultTranslations,
};

export {
  LocalizationDefaults,
  Translation,
  MirrorTranslation,
};
