import { CatalogConfiguration } from '../../interfaces/CatalogConfiguration';
import { GlassesType, UpcAvailability } from '../../interfaces/UpcAvailability';

const getGlassesType = (lods: number[], config: CatalogConfiguration, isAvailable: boolean): GlassesType | undefined  => {
  // TODO: remove this. To support MOCK env is necessary to return GLTF as glasses type in case the upc is not supported
  return (lods.length > 0 || config.env === 'MOCK' && !isAvailable) ? 'GLTF' : 'B3D';
};

export const standardResponse = (catalogResponse: CatalogResponse, upc: string, config: CatalogConfiguration): UpcAvailability => {

  const lods = (!!catalogResponse?.lods) ? catalogResponse.lods : [];
  
  const isAvailable = catalogResponse.resolutions.includes('PX_256') || catalogResponse.resolutions.includes('PX_512') || lods.length > 0;

  const isTransition = catalogResponse.transitionsResolutions.includes('PX_256')
    || catalogResponse.transitionsResolutions.includes('PX_512');
  
  const glassesType = getGlassesType(lods, config, isAvailable);

  return {
    upc: upc,
    available: {
      '256': catalogResponse.resolutions.includes('PX_256'),
      '512': catalogResponse.resolutions.includes('PX_512')
    },
    transitions: {
      '256': catalogResponse.transitionsResolutions.includes('PX_256'),
      '512': catalogResponse.transitionsResolutions.includes('PX_512'),
    },
    lods,
    glassesType,
    getUpc: () => upc,
    isAvailable: () => isAvailable,
    isTransition: () => isTransition,
  };
};
