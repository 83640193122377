import React from 'react';
import ReactDOM from 'react-dom';
import { UploadImageContainer } from './UploadImageContainer';
import device, { DeviceType } from 'current-device';
import { ROOT_STYLE_NAME } from '../../constants/Styling';
import { UploadOptions } from '../../interfaces/CaptureParameters';

let currentContainer;

const loadUploadImage = (
  container: HTMLElement,
  options: UploadOptions,
  onMount: () => void
) => {

  currentContainer = container;
  currentContainer.classList.add(ROOT_STYLE_NAME);
  const deviceType: DeviceType = (device.mobile()) ? 'mobile' : 'desktop';

  ReactDOM.render(
    <UploadImageContainer options={options} deviceType={deviceType}/>,
    container,
    onMount
  );
};

const closeUploadImageComponent = () => {
  if (!!currentContainer) {
    ReactDOM.unmountComponentAtNode(currentContainer);
  }
};

export {
  loadUploadImage,
  closeUploadImageComponent
};
