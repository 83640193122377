import * as t from 'io-ts'

export type Maybe<T> = T | null | undefined

export const optional = <A>(
  codec: t.Type<A>,
  name = `${codec.name} | undefined`,
): t.Type<A | undefined, A | undefined, unknown> => {
  return new t.Type(
    name,
    (u: unknown): u is A | undefined => u === undefined || codec.is(u),
    (u, c) => (u === undefined ? t.success(u) : codec.validate(u, c)),
    a => (a === undefined ? a : codec.encode(a)),
  )
}

export const isNotUndefined = <T>(x: T | undefined): x is T => x !== undefined
