import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PlaylistResult } from '@root/types/cms'
import { getPlaylist } from '@root/persistance'

const savedPlaylist = getPlaylist()
const initialState: PlaylistResult = savedPlaylist || {
  id: '',
  pageTitle: '',
  pageDescription: '',
  title: '',
  description: '',
  imageUri: '',
  videoUri: '',
  productType: '',
  deboostTags: [],
  elevate: [],
  exclude: [],
  tags: [],
}

export const slice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setPlaylist: (_, { payload }: PayloadAction<PlaylistResult>) => {
      return payload
    },
    resetPlaylist: () => {
      return initialState
    },
  },
})

export const { setPlaylist, resetPlaylist } = slice.actions

export default slice.reducer
