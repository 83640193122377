import { EventBus } from "@luxottica/vm-eventbus";
import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../../package.json";
import { INudgingEventProducer } from "../domain/INudgingEventProducer";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "NudgingEventProducer");

class NudgingEventProducer implements INudgingEventProducer {

    constructor() {
        logger.debug("nudging event producer initialized");
    }

    public newNudgingState(stateCode: string, stateMess: string): Promise<void> {
        if (stateCode) {
            logger.debug("new nudging state: {} ({})", stateCode, stateMess);
            EventBus.publish("poseTrackerStatus", {
                code: stateCode,
                text: stateMess,
            });
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
}

export { NudgingEventProducer };
