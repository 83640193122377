import { name, version } from "../../../../package.json";
import { RemoteLogConfig } from "../../RemoteLogConfig";
import { AppViewSessionConsumer } from "../log/consumer/AppViewSessionConsumer";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "AppViewSessionConsumerJob");

class AppViewSessionConsumerJob {

  public static getInstance() {
    if (!AppViewSessionConsumerJob.instance) {
      AppViewSessionConsumerJob.instance = new AppViewSessionConsumerJob();
    }
    return AppViewSessionConsumerJob.instance;
  }

  private static instance: AppViewSessionConsumerJob;
  private started: boolean = false;
  private intervalFunction: any;

  private constructor() {
    logger.info("remote log consumer job created");
  }

  public start(): void {
    if (this.started === true) {
      logger.debug("already started");
    } else {
      logger.info("start to consume logs");
      this.started = true;
      this.intervalFunction = window.setInterval(new AppViewSessionConsumer().consume, 3000);
    }
  }

  public stop(): void {
    if (this.intervalFunction) {
      window.clearInterval(this.intervalFunction);
    }
  }

}

export { AppViewSessionConsumerJob };
