import { name, version } from "../../../../package.json";
import { RemoteLogConfig } from "../../RemoteLogConfig";
import { BrowserSessionConsumer } from "../log/consumer/BrowserSessionConsumer";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "BrowserSessionConsumerJob");

class BrowserSessionConsumerJob {

  public static getInstance() {
    if (!BrowserSessionConsumerJob.instance) {
      BrowserSessionConsumerJob.instance = new BrowserSessionConsumerJob();
    }
    return BrowserSessionConsumerJob.instance;
  }

  private static instance: BrowserSessionConsumerJob;
  private started: boolean = false;
  private intervalFunction: any;

  private constructor() {
    logger.info("remote log consumer job created");
  }

  public start(): void {
    if (this.started === true) {
      logger.debug("already started");
    } else {
      logger.info("start to consume logs");
      this.started = true;
      this.intervalFunction = window.setInterval(new BrowserSessionConsumer().consume, 3000);
    }
  }

  public stop(): void {
    if (this.intervalFunction) {
      window.clearInterval(this.intervalFunction);
    }
  }

}

export { BrowserSessionConsumerJob };
