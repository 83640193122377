import { UAParser } from "ua-parser-js";

class UserAgentParser {

    public static getInstance() {
        if (!UserAgentParser.instance) {
            UserAgentParser.instance = new UserAgentParser();
        }
        return UserAgentParser.instance;
    }

    private static instance: UserAgentParser;
    private userAgentData: IUAParser.IResult;

    private constructor() {
        this.userAgentData = new UAParser().getResult();
    }

    public getUserAgentData(): IUAParser.IResult {
        return this.userAgentData;
    }

}

export { UserAgentParser };
