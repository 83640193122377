import { AnimationError } from '../../errors/errors';
import { TransitionColorHelper } from '../../helpers/TransitionColorHelper';
import { vmIsInitialized, VmStore } from '../VmCore';

const animateOpacity = (duration = 1000): void => {
  if (!vmIsInitialized()) {
    return;
  }

  if (VmStore?.getState().mirror.mirrorStatus === 'TRACKING') {

    if (!(VmStore.getState().mirror.glasses as MirrorB3dAsset).transitionAvailable) {
      const error = new AnimationError('animateOpacity cannot be triggered when transition is not available for this asset');
      throw error;
    }

    if (duration === undefined || typeof duration !== 'number' || duration <= 0) {
      const error = new AnimationError('animateOpacity cannot be triggered when duration value is invalid');
      throw error;
    }

    TransitionColorHelper.startAnimation(duration);
  } else {
    const error = new AnimationError('animateOpacity cannot be triggered when mirror is not rendering');
    throw error;
  }
};

export {
  animateOpacity
};
