const isDistributorAsset = (upc: string): boolean => {
  return upc.startsWith('TKN~');
};

const isQADistributorAsset = (upc: string): boolean => {
  // sample QA distributor UPC: 0OO9290CP-FRAME_SR005
  const dash = upc.split('-').length > 1;
  const underscore = upc.split('_').length > 1;

  return dash && underscore;
};

const distributorBasename = (upc: string): string => {
  // sample distributor UPC: 0OO9290CP-FRAME_SR005
  return upc.split('-')[0];
};

  // distributor can use tokens with a / (slash) in the name
  // the / is escaped by VM clients, all the distributor APIs works with the escaped character %2F
  // eg: TKN~0RB4165CP~2RB4165061_601%2F71AB~2AJ1212111_710%2F13AA~NULL~2LSRB4165_1770..AB~NULL~RBCP..55
  // inside the asset zip this slash is replaced with a dash -
const distributorFolderName = (upc: string): string => {
  return upc.replace(/(%2F)/gm, '-');
};

export { isDistributorAsset, isQADistributorAsset, distributorBasename,
  distributorFolderName
};
