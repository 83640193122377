class MicroserviceError extends Error {
  public type: string;

  constructor(type: string, message: string, error?: Error) {
    super(message);
    this.type = type;
    this.name = type;
    this.message = message;
    this.stack = error?.stack;

    Object.setPrototypeOf(this, MicroserviceError.prototype);
  }
}

class EnvironmentError extends MicroserviceError {
  public static type = 'EnvironmentError';

  constructor(readonly env: string) {
    super(EnvironmentError.type, `microservice environment ${env} is not valid`);
    Object.setPrototypeOf(this, EnvironmentError.prototype);
  }
}

class InvalidInputError extends MicroserviceError {
  public static type = 'InputError';

  constructor(message: string, error?: Error) {
    super(InvalidInputError.type, message, error);
    Object.setPrototypeOf(this, InvalidInputError.prototype);
  }
}

class LocaleError extends MicroserviceError {
  public static type = 'LocaleError';

  constructor(readonly locale: string) {
    super(LocaleError.type, `bipa agreement text cannot be found for locale ${locale}`);
    Object.setPrototypeOf(this, LocaleError.prototype);
  }
}

class RequestError extends MicroserviceError {
  public static type = 'RequestError';

  constructor(message: string, error?: Error) {
    super(RequestError.type, message, error);
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

class TimeoutError extends MicroserviceError {
  public static type = 'TimeoutError';

  constructor(duration: number) {
    super(TimeoutError.type, `request has timed out after ${duration}ms`);
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export {
  InvalidInputError,
  EnvironmentError,
  LocaleError,
  RequestError,
  TimeoutError
};
