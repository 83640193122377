export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_AUTH = 'SET_AUTH';
export const RESET_AUTH = 'RESET_AUTH';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_PRIVACY_OPTIONS = 'SET_PRIVACY_OPTIONS';

export const SET_USER = 'SET_USER';

export const SET_VIDEO_STATUS = 'SET_VIDEO_STATUS';

export const SET_MIRROR_OPTIONS = 'SET_MIRROR_OPTIONS';
export const SET_MIRROR_STATUS = 'SET_MIRROR_STATUS';
export const SET_MIRROR_GLASSES = 'SET_MIRROR_GLASSES';
export const SET_MIRROR_GLASSES_DATA = 'SET_MIRROR_GLASSES_DATA';
export const SET_MIRROR_GLASSES_STATUS = 'SET_MIRROR_GLASSES_STATUS';
export const SET_GLTF_MIRROR_GLASSES_STATUS = 'SET_GLTF_MIRROR_GLASSES_STATUS';
export const SET_MESH = 'SET_MESH';
export const SET_MESH_DATA = 'SET_MESH_DATA';
export const SET_MESH_STATUS = 'SET_MESH_STATUS';
