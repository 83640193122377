import { MockVideoStream } from '../mockWebcam/MockVideoStream';
import { NativeStream } from '../nativeWebcam/NativeStream';
import { NativeVideoStream } from '../nativeWebcam/NativeVideoStream';
import { WebCamVideoStream } from './WebCamVideoStream';

class WebCamStream {
  private readonly mockOptionName =  'vm.mock.video';
  private privateStreamObject: WebCamVideoStream | NativeVideoStream | MockVideoStream;

  constructor( 
    options: {
      target: string,
      id?: string,
      className?: string,
    },
    stream: MediaStream | NativeStream
  ) {
    if (this.isMockStream()) {
      this.privateStreamObject = new MockVideoStream(options);
    } else if (this.isMediaStream(stream)) {
      this.privateStreamObject = new WebCamVideoStream(options, stream as MediaStream);
    } else {
      this.privateStreamObject = new NativeVideoStream(options, stream as NativeStream);
    }
  }

  public get streamObject(): WebCamVideoStream | NativeVideoStream | MockVideoStream {
    return this.privateStreamObject;
  }

  public close() {
    if (this.privateStreamObject.constructor.name === WebCamVideoStream.name) {
      this.privateStreamObject.pause();
    } else {
      (this.privateStreamObject as NativeVideoStream).close();
    }
  }

  public isMockStream(): boolean {
    return localStorage.getItem(this.mockOptionName) === 'true';
  }

  public isNativeStream(): boolean {
    return this.privateStreamObject.constructor.name === NativeStream.name;
  }

  private isMediaStream(stream: MediaStream | NativeStream): boolean {
    return stream.constructor.name === MediaStream.name;
  }
}

export { WebCamStream };
