import { toDataURL } from './ImageLoader';
import { MockStream } from './MockStream';

class MockVideoStream {

  private videoSrc: string;

  private imageElement: HTMLImageElement;
  private privateCurrentTime = 0;
  private mediaStream: MockStream;

  private playingListener: any[] = [];

  public get currentTime(): number {
    this.privateCurrentTime++;
    return this.privateCurrentTime;
  }

  public get videoWidth(): number {
    return this.imageElement?.width;
  }

  public get videoHeight(): number {
    return this.imageElement?.height;
  }

  public get defaultMuted(): boolean {
    // tslint:disable-next-line:no-console
    console.debug('This is a mock context, defaultMuted will always return true');
    return true;
  }

  public set defaultMuted(value: boolean) {
    // tslint:disable-next-line:no-console
    console.debug('This is a mock context, is not possible to set mute a video stream');
  }

  public get width(): number {
    return this.imageElement.width;
  }

  public set width(value: number) {
    // tslint:disable-next-line:no-console
    console.debug('This is a mock context, not possible to set image width');
  }

  public get height(): number {
    return this.imageElement.height;
  }

  public set height(value: number) {
    // tslint:disable-next-line:no-console
    console.debug('This is a mock context, not possible to set image height');
  }

  constructor(
    options: {
      target: string,
      id?: string,
      className?: string,
    }
  ) {

    const videoSrc = localStorage.getItem('vm.mock.videoSrc');
    if (!!videoSrc) {
      this.videoSrc = videoSrc;
    } else {
      throw new Error('no video src defined');
    }

    this.imageElement = new Image();
    this.mediaStream = new MockStream();
  }

  // tslint:disable-next-line:max-line-length
  public addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject | any,
    options?: boolean | AddEventListenerOptions | undefined
  ) {
    if (type === 'playing') {
      this.playingListener.push(listener);
    } else {
      listener();
    }
  }

  // tslint:disable-next-line:max-line-length
  public removeEventListener(
    type: string, listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions | undefined
  ) {
    if (type === 'playing') {
      this.playingListener = [];
    }
  }

  public play(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.waitForFrame().then(() => {
        for (const listener of this.playingListener) {
          listener();
        }
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  public pause(): void {
    console.debug('This is a mock context, not possible to pause');

  }

  public close(): void {
    console.debug('This is a mock context, not possible to close');
  }

  public get image() {
    return this.imageElement;
  }

  private waitForFrame(): Promise<void> {
    return toDataURL(this.videoSrc).then((imgData) => {
      try {
        this.imageElement.setAttribute('src', imgData as string);
      } catch (error) {
        throw error;
      }
    });
  }
}

export { MockVideoStream };
