import { Action, createAction, handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: UserStoreState = {
  locale: 'en-US',
  region: undefined,
  source: undefined,
  brand: undefined,
  storeId: undefined,
  channel: undefined,
};

export const UserActions = {
  setUser: createAction<UserStoreState>(Actions.SET_USER),
};

export const UserReducer = handleActions<UserStoreState, any>({

  [Actions.SET_USER]: (state, action: Action<UserStoreState>) => {
    return {
      ...state,
      ...action.payload
    };
  },

}, initialState);
