import { AnyAction, Store } from 'redux';
import { configureStore, RootState } from '../Store';

let VmStore: Store<RootState, AnyAction>;

const vmIsInitialized = (): boolean => {
  return VmStore !== undefined && VmStore.getState().app.loading.INITIALIZATION === true;
};

const configureVmStore = () => {
  VmStore = configureStore();
};

export {
  VmStore,
  vmIsInitialized,
  configureVmStore
};
