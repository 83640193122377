enum AppViewSessionKeys {
  WEBCAM_OK = 'vm-webcam-ok',
  WEBCAM_KO = 'vm-webcam-ko',
  WEBCAM_KO_REASON_MESS = 'vm-webcam-ko_reason_mess',
  WEBCAM_SIZE_W = '_webcam_size_w',
  WEBCAM_SIZE_H = '_webcam_size_h',
  WEBCAM_FRAMERATE = '_webcam_framerate',
  WEBCAM_OPENED = '_webcam_opened',
  WEBCAM_CLOSED = '_webcam_closed',
  WEBCAM_OPENING = '_webcam_opening',
  WEBCAM_OPENING_TIME = '_webcam_opening_time_s',
  WEBCAM_POWERED_TIME = '_webcam_powered_time_s',
}

export { AppViewSessionKeys };
