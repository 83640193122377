import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import mitt from "mitt";
import { name, version } from "../package.json";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "EventBus");

class EventBus {

    public static publish(evtName: string, evtData: any) {
        logger.debug("publishing event '{}': {}", evtName, JSON.stringify(evtData));
        EventBus.emitter.emit(evtName, evtData);
        logger.debug("published  event '{}': {}", evtName, JSON.stringify(evtData));
    }

    public static register(evtName: string, evtHandler: any) {
        logger.debug("registering event '{}': {}", evtName, JSON.stringify(evtHandler));
        EventBus.emitter.on(evtName, evtHandler);
        logger.debug("registered  event '{}': {}", evtName, JSON.stringify(evtHandler));
    }

    private static emitter: mitt.Emitter = mitt();

    constructor() {
        logger.info("event bus initialized: {}", EventBus.emitter.constructor.name);
    }

}

export { EventBus };
