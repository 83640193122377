import { MicroserviceEnvironment } from '@luxottica/vto-microservices';
import { version } from '../../package.json';
import { FeatureLicensingService } from '../app/service/FeatureLicensingService';
import { FeatureInitializationError, VtoError } from '../errors/errors';
import { Features } from '../interfaces/FeatureLicensingTypes';
import { AdobeConfig } from '../interfaces/InitializationTypes';
import { createLogger } from '../remotelog/VmLogger';

let adobeConfig: AdobeConfig;
let environment: 'DEVELOPMENT' | 'PRODUCTION';

const logger = createLogger('AdobeHelper');

const generateAdobeData = (
  eventParams: AdobeEventParameters,
  errorParams?: AdobeErrorParameters
): AdobeData => {
  const adobeData = new Map<string, string>();
  adobeData.set('AQB', '1');
  adobeData.set('ndh', '1');
  adobeData.set('ns', 'luxottica');
  adobeData.set('g', window.location.href);
  adobeData.set('r', document.referrer);
  adobeData.set('ch', 'VirtualMirror');
  adobeData.set('server', window.location.hostname);
  adobeData.set('c1', adobeConfig?.channel);
  adobeData.set('c2', adobeConfig?.storeId);
  adobeData.set('c3', adobeConfig?.brand);
  adobeData.set('c4', adobeConfig?.locale);
  adobeData.set('c5', eventParams.upc);
  adobeData.set('c6', adobeConfig?.storeRegion);
  adobeData.set('c7', adobeConfig?.storeCompany);
  adobeData.set('c8', adobeConfig?.globalStoreId);
  adobeData.set('c9', version);

  let lensSim;
  if (!!eventParams.lensName && FeatureLicensingService.isFeatureAuthorized(Features.VM_TRANSITIONS)) {
    lensSim = 'transition';
  } else if (!!eventParams.upc) {
    lensSim = 'standard';
  } else {
    lensSim = 'undefined';
  }
  adobeData.set('c11', lensSim);

  if (errorParams) {
    adobeData.set('v1', errorParams.errorSource);
    adobeData.set('v2', errorParams.errorCode);
    adobeData.set('v3', errorParams.errorDetails);
    adobeData.set('v5', eventParams.upc);
  }

  adobeData.set('events', eventParams.eventCode);
  adobeData.set('pageName', 'VirtualMirror');

  adobeData.set('AQE', '1');
  return adobeData;
};

const sendAdobeRequest = (data: AdobeData) => {
  const adobeHost = (environment === 'PRODUCTION')
    ? 'https://luxottica.d2.sc.omtrdc.net/b/ss/lux-virtualmirror-prod/1?'
    : 'https://luxottica.d2.sc.omtrdc.net/b/ss/lux-virtualmirror-dev/1?';

  const query = Array.from(data.keys())
    .filter((key) => data.get(key) !== undefined)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data.get(key)))
    .join('&');

  const adobeQuery = `${adobeHost}${query}`;

  logger.debug('sending adobe analytics event: {}', JSON.stringify({
    abobeHost: adobeHost,
    entries: [...data.entries()]
  }));

  return fetch(adobeQuery, {
    method: 'POST'
  }).catch((e) => {
    logger.warn('failed to post analytics event {}', JSON.stringify(e));
  });
};

const AdobeHelper = {
  initialize: (config: AdobeConfig, msEnvironment: MicroserviceEnvironment) => {
    adobeConfig = config;
    environment = (msEnvironment === 'PRODUCTION') ? 'PRODUCTION' : 'DEVELOPMENT';
  },
  sendEvent: (eventParams: AdobeEventParameters) => {
    if (FeatureLicensingService.isFeatureAuthorized(Features.VM_ANALYTICS)) {
      try {
        const adobeData = generateAdobeData(eventParams);
        sendAdobeRequest(adobeData);
      } catch (e) {
        logger.error(e);
      }
    }
  },
  sendError: (vtoError: VtoError, upc?: string) => {
    if (!FeatureLicensingService.isInitialized()) {
      throw new FeatureInitializationError();
    }
    if (FeatureLicensingService.isFeatureAuthorized(Features.VM_ANALYTICS)) {
      try {
        if ([
          'InitializationError',
          'InvalidParametersError',
          'UpcSupportedError',
          'UpcMissingError',
          'WebcamUnavailableError',
          'WebcamRejectedError',
          'MountComponentError',
          'UnmountComponentError'
        ].includes(vtoError?.type)) {
          const adobeErrorData = generateAdobeData({
              eventCode: 'event8',
              upc: upc
            }, vtoError.adobeErrorParameters);
          sendAdobeRequest(adobeErrorData);
        }
      } catch (e) {
        logger.error(e);
      }
    }
  }
};

export { AdobeHelper };
