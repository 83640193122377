import { PrivacyOptions } from '../interfaces/InitializationTypes';

export const DefaultPrivacyOptions: PrivacyOptions = {
  bipaActive: false,
  region: undefined,
  source: undefined,
  privacyPolicy: undefined,
  termsAndConditions: undefined,
  bipaPrefill: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
  },
  localizationText: undefined,
};
