import { NativeStream } from './NativeStream';

class NativeVideoStream {
  private imageElement: any;
  private privateCurrentTime = 0;
  private mediaStream: NativeStream;

  private playingListener: any[] = [];

  public get currentTime(): number {
    return this.privateCurrentTime;
  }

  public get videoWidth(): number {
    return this.imageElement.width;
  }

  public get videoHeight(): number {
    return this.imageElement.height;
  }

  public get defaultMuted(): boolean {
    // tslint:disable-next-line:no-console
    console.debug('This is a native context, defaultMuted will always return true');
    return true;
  }

  public set defaultMuted(value: boolean) {
    // tslint:disable-next-line:no-console
    console.debug('This is a native context, is not possible to set mute a video stream');
  }

  public get width(): number {
    return this.imageElement.width;
  }

  public set width(value: number) {
    // tslint:disable-next-line:no-console
    console.debug('This is a native context, not possible to set image width');
  }

  public get height(): number {
    return this.imageElement.height;
  }

  public set height(value: number) {
    // tslint:disable-next-line:no-console
    console.debug('This is a native context, not possible to set image height');
  }

  constructor(options: {
                target: string,
                id?: string,
                className?: string,
              },
              mediaStream: NativeStream) {

    this.mediaStream = mediaStream;
    this.imageElement = new Image();

    this.imageElement.width = this.mediaStream.getVideoTracks()[0].getSettings().width;
    this.imageElement.height = this.mediaStream.getVideoTracks()[0].getSettings().height;

    window.NativeBridge.onCamFrame = (base64: string) => {
      this.imageElement.src = base64;
    };
  }

  // tslint:disable-next-line:max-line-length
  public addEventListener(type: string, listener: EventListenerOrEventListenerObject | any, options?: boolean | AddEventListenerOptions | undefined) {
    if (type === 'playing') {
      this.playingListener.push(listener);
    } else {
      listener();
    }
  }

  // tslint:disable-next-line:max-line-length
  public removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions | undefined) {
    if (type === 'playing') {
      this.playingListener = [];
    }
    // mock
  }

  public play(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window.NativeBridge.resume();
      this.waitForFrame()
      .then(() => {
        this.startTimeCounter();
        for (const listener of this.playingListener) {
          listener();
        }
        resolve();
      })
      .catch(() => {
        reject();
      });
    });
  }

  public pause(): void {
    window.NativeBridge.pause();
  }

  public close(): void {
    window.NativeBridge.close();
  }

  public get image() {
    return this.imageElement;
  }

  private waitForFrame(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.imageElement.onload = () => {
        resolve();
      };
      this.imageElement.onerror = () => {
        reject();
      };
    });
  }

  private startTimeCounter() {
    this.imageElement.onload = () => { this.privateCurrentTime++; };
  }
}

export { NativeVideoStream };
