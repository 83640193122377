import { DeviceDetection } from '@luxottica/vm-devicedetection';
import { WebCam, WebCamStream } from '@luxottica/vm-webcam';
import { createLogger } from '../../remotelog/VmLogger';

const logger = createLogger('VmWebCam');

export class VmWebCam {
  private webCam: WebCam;
  private webCamStream: WebCamStream;
  private static instance: VmWebCam;

  constructor() {
    this.webCam = new WebCam();
  }

  public static getInstance() {
    if (!VmWebCam.instance) {
      VmWebCam.instance = new VmWebCam();
    }
    return VmWebCam.instance;
  }

  public tryToOpenWebCam(w, h) {
    return this.webCam.tryToOpenWebCam(w, h, 'user');
  }

  public initStreamObject(options, stream) {
    this.webCamStream = this.createStreamObject(options, stream);
  }

  public closeCurrentMediaStream() {
    this.webCam.closeCurrentMediaStream();
  }

  public pause() {
    const deviceDetection = new DeviceDetection();
    if (!this.webCamStream.isNativeStream() && deviceDetection.isAppleiOS13()) {
      logger.warn('Can\'t pause Webcam on iOS 13 device due a bug that would freeze video stream on resume playing');
      return;
    }
    this.webCamStream.streamObject.pause();
  }

  public play() {
    return this.webCamStream.streamObject.play();
  }

  public get stream() {
    return this.webCamStream;
  }

  private createStreamObject(options, stream) {
    return this.webCam.createStreamObject(options, stream);
  }
}
