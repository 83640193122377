import {
  EyeColor,
  EyeMood,
  FaceLength,
  FaceShape,
  Gender,
  HairColor,
  SkinUndertones,
  SunMood,
  TempleThickness,
} from '@root/types/search'
import { Id, UserTokens } from '@root/types/common'

import { GlassType } from '@root/types/products'
import { PlaylistResult } from '@root/types/cms'
import { UserProfileData } from './types/profile'

const TOKENS_KEY = 'fa-tokens'

export const setTokens = (tokens: UserTokens) => {
  window.localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens))
}

export const getTokens = (): UserTokens => {
  const tokens = window.localStorage.getItem(TOKENS_KEY)
  return tokens ? JSON.parse(tokens) : { accessToken: '', refreshToken: '' }
}

export const delTokens = () => {
  window.localStorage.removeItem(TOKENS_KEY)
}

const VIDEO_ID_KEY = 'fa-video-id'

export const setVideoId = (videoUuid: Id) => {
  window.localStorage.setItem(VIDEO_ID_KEY, videoUuid)
}

export const getVideoId = () => {
  return window.localStorage.getItem(VIDEO_ID_KEY)
}

export const delVideoId = () => {
  window.localStorage.removeItem(VIDEO_ID_KEY)
}

const PICTURE_ID_KEY = 'fa-picture-id'

export const setPictureId = (videoUuid: Id) => {
  window.localStorage.setItem(PICTURE_ID_KEY, videoUuid)
}

export const getPictureId = () => {
  return window.localStorage.getItem(PICTURE_ID_KEY)
}

export const delPictureId = () => {
  window.localStorage.removeItem(PICTURE_ID_KEY)
}
const FACEBREADTH_KEY = 'fa-face-breadth'
type FaceBreadth = UserProfileData['userInformation']['faceBreadth']

export const setFaceBreadth = (faceBreadth: FaceBreadth) => {
  window.localStorage.setItem(FACEBREADTH_KEY, JSON.stringify(faceBreadth))
}

export const getFaceBreadth = () => {
  const item = window.localStorage.getItem(FACEBREADTH_KEY)
  const faceBreadth = item && JSON.parse(item)
  return isNaN(faceBreadth) ? undefined : (faceBreadth as FaceBreadth)
}

export const delFaceBreadth = () => {
  window.localStorage.removeItem(FACEBREADTH_KEY)
}

const USER_ID_KEY = 'fa-user-id'

export const setUserId = (userUuid: Id) => {
  window.localStorage.setItem(USER_ID_KEY, userUuid)
}

export const getUserId = () => {
  return window.localStorage.getItem(USER_ID_KEY)
}

export const delUserId = () => {
  window.localStorage.removeItem(USER_ID_KEY)
}

const EYE_MOOD_KEY = 'fa-eye_mood'

export const setEyeMoodKey = (eyeMood: EyeMood) => {
  window.localStorage.setItem(EYE_MOOD_KEY, eyeMood)
}

export const getEyeMoodKey = () => {
  return window.localStorage.getItem(EYE_MOOD_KEY) as EyeMood | null
}

export const delEyeMoodKey = () => {
  window.localStorage.removeItem(EYE_MOOD_KEY)
}

const SUN_MOOD_KEY = 'fa-sun-mood'

export const setSunMoodKey = (sunMood: SunMood) => {
  window.localStorage.setItem(SUN_MOOD_KEY, sunMood)
}

export const getSunMoodKey = () => {
  return window.localStorage.getItem(SUN_MOOD_KEY) as SunMood | null
}

export const delSunMoodKey = () => {
  window.localStorage.removeItem(SUN_MOOD_KEY)
}

const FACE_SHAPE_KEY = 'fa-face-shape'

export const setFaceShapeKey = (faceShape: FaceShape) => {
  window.localStorage.setItem(FACE_SHAPE_KEY, faceShape)
}

export const getFaceShapeKey = () => {
  return window.localStorage.getItem(FACE_SHAPE_KEY) as FaceShape | null
}

export const delFaceShapeKey = () => {
  window.localStorage.removeItem(FACE_SHAPE_KEY)
}

const SKIN_TONE_KEY = 'fa-skin-tone'

export const setSkinToneKey = (skinTone: SkinUndertones) => {
  window.localStorage.setItem(SKIN_TONE_KEY, skinTone)
}

export const getSkinToneKey = () => {
  return window.localStorage.getItem(SKIN_TONE_KEY) as SkinUndertones | null
}

export const delSkinToneKey = () => {
  window.localStorage.removeItem(SKIN_TONE_KEY)
}

const HAIR_COLOR_KEY = 'fa-hair-color'

export const setHairColorKey = (hairColor: HairColor) => {
  window.localStorage.setItem(HAIR_COLOR_KEY, hairColor)
}

export const getHairColorKey = () => {
  return window.localStorage.getItem(HAIR_COLOR_KEY) as HairColor | null
}

export const delHairColorKey = () => {
  window.localStorage.removeItem(HAIR_COLOR_KEY)
}

const EYE_COLOR_KEY = 'fa-eye-color'

export const setEyeColorKey = (eyeColor: EyeColor) => {
  window.localStorage.setItem(EYE_COLOR_KEY, eyeColor)
}

export const getEyeColorKey = () => {
  return window.localStorage.getItem(EYE_COLOR_KEY) as EyeColor | null
}

export const delEyeColorKey = () => {
  window.localStorage.removeItem(EYE_COLOR_KEY)
}

const FACE_LENGTH_KEY = 'fa-face-length'

export const setFaceLengthValue = (faceLength: FaceLength) => {
  window.localStorage.setItem(FACE_LENGTH_KEY, faceLength)
}

export const getFaceLengthValue = () => {
  return window.localStorage.getItem(FACE_LENGTH_KEY) as FaceLength | null
}

export const delFaceLengthValue = () => {
  window.localStorage.removeItem(FACE_LENGTH_KEY)
}

const TEMPLE_THIKNESS_KEY = 'fa-temple-thickness'

export const setTempleThicknessValue = (templeThickness: TempleThickness) => {
  window.localStorage.setItem(TEMPLE_THIKNESS_KEY, templeThickness)
}

export const getTempleThicknessValue = () => {
  return window.localStorage.getItem(TEMPLE_THIKNESS_KEY) as TempleThickness | null
}

export const delTempleThicknessValue = () => {
  window.localStorage.removeItem(TEMPLE_THIKNESS_KEY)
}

const GENDER_KEY = 'fa-gender'

export const setGenderKey = (gender: Gender) => {
  window.localStorage.setItem(GENDER_KEY, gender)
}

export const getGenderKey = () => {
  return window.localStorage.getItem(GENDER_KEY) as Gender | null
}

export const delGenderKey = () => {
  window.localStorage.removeItem(GENDER_KEY)
}

const GLASS_TYPE_KEY = 'fa-glass-type'

export const setGlassTypeKey = (glassType: GlassType) => {
  window.localStorage.setItem(GLASS_TYPE_KEY, glassType)
}

export const getGlassTypeKey = () => {
  return window.localStorage.getItem(GLASS_TYPE_KEY) as GlassType | null
}

const PLAYLIST_ID_KEY = 'fa-playlist'

export const setPlaylist = (playlist: PlaylistResult) => {
  window.localStorage.setItem(PLAYLIST_ID_KEY, JSON.stringify(playlist))
}

export const getPlaylist = () => {
  const data = window.localStorage.getItem(PLAYLIST_ID_KEY)
  if (data) {
    return JSON.parse(data)
  }
  return null
}

export const delPlaylist = () => {
  window.localStorage.removeItem(PLAYLIST_ID_KEY)
}

export const delGlassTypeKey = () => {
  window.localStorage.removeItem(GLASS_TYPE_KEY)
}

export const delSessionInfoWithoutToken = () => {
  delVideoId()
  delPictureId()
  delUserId()
  delFaceBreadth()
  delPlaylist()
  delEyeMoodKey()
  delSunMoodKey()
  delFaceShapeKey()
  delSkinToneKey()
  delHairColorKey()
  delEyeColorKey()
  delFaceLengthValue()
  delGenderKey()
  delGlassTypeKey()
}

export const delSessionInfo = () => {
  delTokens()
  delSessionInfoWithoutToken()
}

const ANALYTIC_SKIN_TONE_KEY = 'fa-analytic-skin-tone'

export const setAnalyticSkinToneKey = (skinTone: SkinUndertones) => {
  window.localStorage.setItem(ANALYTIC_SKIN_TONE_KEY, skinTone)
}

export const getAnalyticSkinToneKey = () => {
  return window.localStorage.getItem(ANALYTIC_SKIN_TONE_KEY) as SkinUndertones | null
}
