import localforage from "localforage";

const dbName = "db_appviewsession";

class DBAppViewSessionFactory {

  public static getInstance() {
    if (!DBAppViewSessionFactory.instance) {
      DBAppViewSessionFactory.instance = new DBAppViewSessionFactory();
    }
    return DBAppViewSessionFactory.instance;
  }

  private static instance: DBAppViewSessionFactory;
  private db: LocalForage;

  private constructor() {
    this.db = localforage.createInstance({ name: dbName });
  }

  public getDB(): LocalForage {
    return this.db;
  }

  public getLogsTableName() {
    return "logs";
  }

}

export { DBAppViewSessionFactory };
