import { RemoteLogConfig } from '@luxottica/vm-remotelog';
import { DeviceType } from 'current-device';
import React, { Component, createRef, RefObject } from 'react';
import { name, version } from '../../../package.json';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { LocalizationHelper } from '../../helpers/LocalizationHelper';
import { RequestHelper } from '../../helpers/RequestHelper';
import { UploadOptions } from '../../interfaces/CaptureParameters';
import { FrameAdvisorErrorOverlay } from '../fa-capture/FrameAdvisorErrorOverlay';
import { LoadingSpinner } from '../LoadingSpinner';

import './UploadImage.scss';
import { UploadStatus } from './UploadStatus';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'UploadImage');

interface UploadImageProps {
  options: UploadOptions;
  deviceType: DeviceType;
}

interface UploadImageState {
  uploadStatus: UploadStatus;
  dragOver: boolean;
}

class UploadImage extends Component<
  UploadImageProps,
  UploadImageState
> {

  private mounted: boolean;
  private imageInput: RefObject<HTMLInputElement>;
  private currentFile: File;
  private currentImage = new Image();

  constructor(props: UploadImageProps) {
    super(props);
    this.imageInput = createRef();
    this.state = {
      uploadStatus: UploadStatus.INITIAL,
      dragOver: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    document.body.addEventListener('mouseenter', this.mouseEnterHandler);
    document.body.addEventListener('dragover', this.dragOverHandler);
    document.body.addEventListener('dragenter', this.dragEnterHandler);
    document.body.addEventListener('dragleave', this.dragLeaveHandler);
    document.body.addEventListener('drop', this.windowDropHandler);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.body.removeEventListener('mouseenter', this.mouseEnterHandler);
    document.body.removeEventListener('dragover', this.dragOverHandler);
    document.body.removeEventListener('dragenter', this.dragEnterHandler);
    document.body.removeEventListener('dragleave', this.dragLeaveHandler);
    document.body.removeEventListener('drop', this.windowDropHandler);
  }

  imageInputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (!!this.imageInput.current?.files?.[0]) {
      this.generateImagePreview(this.imageInput.current?.files?.[0]);
    }
    e.currentTarget.value = '';
  }

  dropHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ dragOver: false });

    let file: File;
    if (e.dataTransfer.items && e.dataTransfer.items?.[0].kind === 'file') {
      file = e.dataTransfer.items[0].getAsFile();
    } else if (e.dataTransfer?.files.length > 0) {
      file = e.dataTransfer.files[0];
    }

    if (!!file) {
      this.generateImagePreview(file);
    }
  };

  generateImagePreview = (file: File) => {
    this.currentFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(this.currentFile);
		reader.onload = (e) => {
      this.currentImage.src = e.target.result.toString();
      this.currentImage.onload = () => {

        if (!!CallbackHelper.onImageConfirm) {
          this.setState({ uploadStatus: UploadStatus.ACCEPT_THIRD_PARTY });
          CallbackHelper.onImageConfirm(this.currentImage.src).then((imageAccepted) => {
            if (imageAccepted) {
              this.sendUploadImageRequest(this.currentFile);
            } else {
              this.setState({ uploadStatus: UploadStatus.INITIAL });
            }
          });

        } else {
          this.setState({ uploadStatus: UploadStatus.ACCEPT });
        }
      };
    };
  }

  windowDropHandler = (e) => {
    e.preventDefault();
    this.setState({ dragOver: false });
  };

  mouseEnterHandler = () => {
    if (this.state.dragOver === true) {
      this.setState({ dragOver: false });
    }
  };

  dragOverHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  dragEnterHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ dragOver: true });
  };

  dragLeaveHandler = (e) => {
    e.preventDefault();
  };

  onAcceptFile = () => {
    this.sendUploadImageRequest(this.currentFile);
  }

  onDeclineFile = () => {
    this.setState({
      uploadStatus: UploadStatus.INITIAL
    });
  }

  sendUploadImageRequest = (file: File): void => {
    if (!!file) {
      logger.debug(`file received: ${file.name}`);
      logger.debug(file);

      this.setState({ uploadStatus: UploadStatus.UPLOADING });

      RequestHelper.startSession().then(() => {
        RequestHelper.uploadImage(file).then(() => {
          if (this.mounted) {
            this.setState({ uploadStatus: UploadStatus.PROCESSING });
            return RequestHelper.processImage(
              this.props.options.enableMachineLearning,
              this.props.options.enableVto,
              () => { return !this.mounted; });
          }
        }).then((result) => {
          if (this.mounted) {
            logger.debug('upload image complete');
            this.resetComponent();
            CallbackHelper.onAdvisorResult({
              ...(this.props.options.enableMachineLearning && { properties: result.properties }),
              videoId: result.videoId
            });
          }
        }).catch((error) => {
          if (this.mounted) {
            this.setState({ uploadStatus: UploadStatus.ERROR });
            CallbackHelper.onCaptureError(error);
          }
        });
      });
    } else {
      logger.warn('invalid file, aborting upload process');
    }
  }

  resetComponent = () => {
    this.currentFile = undefined;
    this.currentImage = new Image();
    this.setState({ uploadStatus: UploadStatus.INITIAL });
  }

  render() {
    let displayText = '';
    if (this.props.deviceType === 'mobile') {
      displayText = LocalizationHelper.getString('UPLOAD', 'TAP_TO_UPLOAD');
    } else {
      displayText = (this.state.dragOver) ? LocalizationHelper.getString('UPLOAD', 'DROP_HERE') : LocalizationHelper.getString('UPLOAD', 'CLICK_OR_DROP');
    }

    const showLoading = (this.state.uploadStatus === UploadStatus.UPLOADING || this.state.uploadStatus === UploadStatus.PROCESSING);
    const showImgPreview = showLoading || this.state.uploadStatus === UploadStatus.ACCEPT ||  this.state.uploadStatus === UploadStatus.ERROR;

    return (
      <div className={`upload-image ${this.props.deviceType}`}>
        {this.state.uploadStatus === UploadStatus.INITIAL &&
          <label id='upload-image-drop-zone' className={(this.state.dragOver ? 'drag-over' : undefined)} onDrop={this.dropHandler} htmlFor='upload-image-file'>
            <p className='upload-image-drop-zone-text'>{displayText}</p>
            <input type='file' accept='image/*' id='upload-image-file' name='upload-image-file' ref={this.imageInput} onChange={this.imageInputHandler}/>
          </label>
        }

        {showImgPreview &&
          <div className='upload-image-display'>
            <div className='upload-image-display-image-container'>
              <img src={this.currentImage.src}/>
            </div>
          </div>
        }

        {(this.state.uploadStatus === UploadStatus.ACCEPT) &&
          <div className='upload-image-display-controls'>
            <button className='upload-image-display-accept' onClick={this.onAcceptFile}>{LocalizationHelper.getString('UPLOAD', 'ACCEPT')}</button>
            <button className='upload-image-display-decline' onClick={this.onDeclineFile}>{LocalizationHelper.getString('UPLOAD', 'DECLINE')}</button>
          </div>
        }

        <div className={`upload-image-loading${showLoading ? ' active' : ''}`}>
          <LoadingSpinner width={300} height={300} background={true}/>
          <div className='upload-image-loading-text'>
            {(this.state.uploadStatus === UploadStatus.UPLOADING) ?
              LocalizationHelper.getString('SERVER_PROCESSING', 'IMAGE_UPLOAD') : LocalizationHelper.getString('SERVER_PROCESSING', 'IMAGE_PROCESSING')}
          </div>
        </div>

        <FrameAdvisorErrorOverlay
          enabled={this.state.uploadStatus === 'ERROR'}
          onClickOk={this.resetComponent}/>
      </div>
    );
  }
}

export {
  UploadImage,
};
