import { GlScreenshot } from '@luxottica/vm-renderer-threejs';
import PubSub from 'pubsub-js';
import { Events } from '../../constants/Events';
import { FeatureUnavailableError } from '../../errors/errors';
import { AnalyticsHelper } from '../../helpers/AnalyticsHelper';
import { Features } from '../../interfaces/FeatureLicensingTypes';
import { Screenshot } from '../../interfaces/Screenshot';
import { createLogger } from '../../remotelog/VmLogger';
import { FeatureLicensingService } from '../service/FeatureLicensingService';
import { VmStore } from '../VmCore';

const logger = createLogger('GetScreenshot');

const getScreenshot = (type = 'image/jpeg', quality = 0.9): Promise<Screenshot> => {
  if (!FeatureLicensingService.isFeatureAuthorized(Features.VM_SCREENSHOT)) {
    return Promise.reject(new FeatureUnavailableError(Features.VM_SCREENSHOT));
  }
  
  let subscriber: (topicName: string, functionF: (mess: string, data: Screenshot) => void) => void;
  const promise = new Promise<Screenshot>((resolve) => {
    subscriber = PubSub.subscribe(Events.TOPIC_SCREENSHOT_RES, (mess: string, data: GlScreenshot) => {
      AnalyticsHelper.onScreenshot(
        VmStore.getState().mirror.glasses.upc,
        VmStore.getState().mirror.mirrorOptions.transitionColor
      );
      logger.debug('message reveived from topic {}: total img bytes {}', mess, data.imgURI.length);
      resolve({ imgURI: data.imgURI, width: data.w, height: data.h });
    });
    logger.debug('subscription for {}: {}', Events.TOPIC_SCREENSHOT_RES, subscriber);
  });
  promise.then(() => {
    if (subscriber) {
      PubSub.unsubscribe(subscriber);
      logger.debug('unsubscribed for {}: {}', Events.TOPIC_SCREENSHOT_RES, subscriber);
    }
  });
  const isPublished = PubSub.publish(Events.TOPIC_SCREENSHOT_REQ, {type, quality});
  logger.debug('published screenshot request on {}: {}', Events.TOPIC_SCREENSHOT_REQ, isPublished);
  return promise;
};

export {
  getScreenshot
};
