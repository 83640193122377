import { MicroserviceEnvironment } from '../constants/MicroserviceEnvironment';
import { ApiConfig } from '../interfaces/ApiConfig';

const envHost: Record<MicroserviceEnvironment, string> = {
  STAGING: 'https://test-gateway-vtoprofile.luxdeepblue.com',
  ACCEPTANCE: 'https://acceptance-gateway-vtoprofile.luxdeepblue.com',
  PRODUCTION: 'https://gateway-vtoprofile.luxdeepblue.com'
};

const envMap: Record<MicroserviceEnvironment, ApiConfig> = {
  STAGING: {
    loginFaUrl: `${envHost.STAGING}/auth/fradv/token`,
    loginVmUrl: `${envHost.STAGING}/auth/vm/token`,
    refreshUrl: `${envHost.STAGING}/auth/refresh`,
    bipaTextUrl: `${envHost.STAGING}/services/vtobipams/public/v2/bipa/version`,
    bipaSubmitFormUrl: `${envHost.STAGING}/services/vtobipams/public/v1/bipa-consent`,
    videoSessionUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v3/video`,
    enrichUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v1/ml-enrich`,
    vtoUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v1/basel-mesh`,
    additionalInfoUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v1/additional-info`,
    poseExtractionUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v1/poses-extraction`,
    statusUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v2/status`,
    pdStatusUrl: `${envHost.STAGING}/services/vtomedicalinfoms/public/v1/pupillary-distance/status`,
    profileUrl: `${envHost.STAGING}/services/vtovideoprofilems/public/v3/get-video-profile`,
  },
  ACCEPTANCE: {
    loginFaUrl: `${envHost.ACCEPTANCE}/auth/fradv/token`,
    loginVmUrl: `${envHost.ACCEPTANCE}/auth/vm/token`,
    refreshUrl: `${envHost.ACCEPTANCE}/auth/refresh`,
    bipaTextUrl: `${envHost.ACCEPTANCE}/services/vtobipams/public/v2/bipa/version`,
    bipaSubmitFormUrl: `${envHost.ACCEPTANCE}/services/vtobipams/public/v1/bipa-consent`,
    videoSessionUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v3/video`,
    enrichUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v1/ml-enrich`,
    vtoUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v1/basel-mesh`,
    additionalInfoUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v1/additional-info`,
    poseExtractionUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v1/poses-extraction`,
    statusUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v2/status`,
    pdStatusUrl: `${envHost.ACCEPTANCE}/services/vtomedicalinfoms/public/v1/pupillary-distance/status`,
    profileUrl: `${envHost.ACCEPTANCE}/services/vtovideoprofilems/public/v3/get-video-profile`,
  },
  PRODUCTION: {
    loginFaUrl: `${envHost.PRODUCTION}/auth/fradv/token`,
    loginVmUrl: `${envHost.PRODUCTION}/auth/vm/token`,
    refreshUrl: `${envHost.PRODUCTION}/auth/refresh`,
    bipaTextUrl: `${envHost.PRODUCTION}/services/vtobipams/public/v2/bipa/version`,
    bipaSubmitFormUrl: `${envHost.PRODUCTION}/services/vtobipams/public/v1/bipa-consent`,
    videoSessionUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v3/video`,
    enrichUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v1/ml-enrich`,
    vtoUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v1/basel-mesh`,
    additionalInfoUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v1/additional-info`,
    poseExtractionUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v1/poses-extraction`,
    statusUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v2/status`,
    pdStatusUrl: `${envHost.PRODUCTION}/services/vtomedicalinfoms/public/v1/pupillary-distance/status`,
    profileUrl: `${envHost.PRODUCTION}/services/vtovideoprofilems/public/v3/get-video-profile`,
  }
};

const getConfig = (env: MicroserviceEnvironment, param: keyof ApiConfig): string => {
  return envMap[env][param];
};

export {
  getConfig
};
