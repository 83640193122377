import { getConfig } from '../config/Config';
import { LocaleError } from '../error/errors';
import { parseJsonResponse } from '../helpers/RequestHelper';
import { BipaAgreementRequestData, BipaAgreementResponse, BipaAgreementReturn, BipaTextResponse, SubmitBipaFormRequestData } from '../interfaces/Bipa';
import { refreshRequest } from './RefreshRequest';

const bipaAgreementFetch = (
  request: BipaAgreementRequestData
): Promise<Response> => {
  const bipaAgreementUrl = getConfig(request.environment, 'bipaTextUrl');

  return fetch(bipaAgreementUrl, {
    method: 'GET',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.accessToken}`,
    },
  });
};

const bipaAgreementRequest = (
  request: BipaAgreementRequestData
): Promise<BipaAgreementReturn> => {

  let bipaVersion: number;
  let bipaDescription: string;

  return bipaAgreementFetch(request).then((bipaAgreementResponse) => {
    if (bipaAgreementResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return bipaAgreementFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return bipaAgreementResponse;
    }

  }).then((response) => {
    return parseJsonResponse(response);

  }).then((json: BipaTextResponse) => {
    bipaVersion = json.version;
    bipaDescription = json.description;

    const bipaUrls = JSON.parse(json.jsonUrl);
    if (!!bipaUrls[request.locale]) {
      return fetch(bipaUrls[request.locale]);
    } else {
      throw new LocaleError(request.locale);
    }

  }).then((response) => {
    return parseJsonResponse(response);

  }).then((bipaAgreementResponse: BipaAgreementResponse) => {
    return {
      version: bipaVersion,
      description: bipaDescription,
      text: bipaAgreementResponse.BIPA_AGREEMENT,
    };
  });
};

const submitBipaFormFetch = (
  request: SubmitBipaFormRequestData
): Promise<Response> => {
  const bipaSubmitUrl = getConfig(request.environment, 'bipaSubmitFormUrl');

  return fetch(bipaSubmitUrl, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.accessToken}`,
    },
    body: `{ "bipaVersion": ${request.bipaVersion}, "channel": "${request.source}", "firstName": "${request.firstName}", "lastName": "${request.lastName}", "email": "${request.email}" }`
  });
};

const submitBipaFormRequest = (
  request: SubmitBipaFormRequestData
): Promise<Response> => {

  return submitBipaFormFetch(request).then((submitBipaResponse) => {
    if (submitBipaResponse.status === 401) {
      return refreshRequest(request).then((refreshResponse) => {
        return submitBipaFormFetch({
          ...request,
          accessToken: refreshResponse.accessToken,
          refreshToken: refreshResponse.refreshToken
        });
      });
    } else {
      return submitBipaResponse;
    }
  });
};

export {
  bipaAgreementRequest,
  submitBipaFormRequest
};
