import * as React from 'react';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  width: number;
  height: number;
  background: boolean;
  text?: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {

  const size = Math.min(props.width, props.height);

  return (
    <div className={'loading-bg'}>
      <div className='loading-spinner-container' style={{ height: size, width: size }}>
        <div className='loading-spinner'/>
      </div>
    </div>
  );
};