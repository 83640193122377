import { name, version } from "../../../../package.json";
import { RemoteLogConfig } from "../../RemoteLogConfig";
import { RemoteLogConsumer } from "../log/consumer/RemoteLogConsumer";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "RemoteLogConsumerJob");

class RemoteLogConsumerJob {

  public static getInstance() {
    if (!RemoteLogConsumerJob.instance) {
      RemoteLogConsumerJob.instance = new RemoteLogConsumerJob();
    }
    return RemoteLogConsumerJob.instance;
  }

  private static instance: RemoteLogConsumerJob;
  private started: boolean = false;
  private intervalFunction: any;

  private constructor() {
    logger.info("remote log consumer job created");
  }

  public start(): void {
    if (this.started === true) {
      logger.debug("already started");
    } else {
      logger.info("start to consume logs");
      this.started = true;
      this.intervalFunction = window.setInterval(new RemoteLogConsumer().consume, 5000);
    }
  }

  public stop(): void {
    if (this.intervalFunction) {
      window.clearInterval(this.intervalFunction);
    }
  }

}

export { RemoteLogConsumerJob };
