
class WebGlDetection {

  public static getInstance() {
    if (!WebGlDetection.instance) {
      WebGlDetection.instance = new WebGlDetection();
    }
    return WebGlDetection.instance;
  }

  private static instance: WebGlDetection;

  private static tmpCanvasElementId = "canvas-for-webgl-check";
  private static webglCtxAttributes: WebGLContextAttributes = { depth: true, premultipliedAlpha: false };

  public isWebGlSupported(): boolean {
    let supported = false;
    try {
      const canvas = document.createElement("canvas");
      canvas.setAttribute("id", WebGlDetection.tmpCanvasElementId);
      if (canvas) {
        const webglContext = this.getWebglContext(canvas, ["webgl2", "webgl", "experimental-webgl"]);
        if (webglContext) {
          supported = true;
        }
      }
    } catch (e) {
      supported = false;
    } finally {
      if (document) {
        const elementToRemove = document.getElementById(WebGlDetection.tmpCanvasElementId);
        if (elementToRemove) {
          elementToRemove.outerHTML = "";
        }
      }
    }
    return supported;
  }

  private getWebglContext(canvas: HTMLCanvasElement, webglContext: string[]): WebGLRenderingContext {
    let webglRenderingContext: any;
    for (const contextName of webglContext) {
      webglRenderingContext = canvas.getContext(contextName, WebGlDetection.webglCtxAttributes);
      if (webglRenderingContext) {
        break;
      }
    }
    return webglRenderingContext;
  }

}

export { WebGlDetection };
