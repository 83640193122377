enum GlassesEnvironment {
  PROD = 'PROD',
  PROD_LEGACY = 'PROD_LEGACY',
  TEST = 'TEST',
  TEST_LEGACY = 'TEST_LEGACY',
  QA_GLTF_PROD = 'QA_GLTF_PROD',
  QA_GLTF_TEST = 'QA_GLTF_TEST',
  QA_B3D_PROD = 'QA_B3D_PROD',
  QA_B3D_TEST = 'QA_B3D_TEST',
  MOCK = 'MOCK',
  // environments for distributor developments
  DISTRIBUTOR_VALIDATION_STAGING = 'DISTRIBUTOR_VALIDATION_STAGING',
  DISTRIBUTOR_APPROVATION_STAGING = 'DISTRIBUTOR_APPROVATION_STAGING',
  DISTRIBUTOR_VALIDATION_PRODUCTION = 'DISTRIBUTOR_VALIDATION_PRODUCTION',
  DISTRIBUTOR_APPROVATION_PRODUCTION = 'DISTRIBUTOR_APPROVATION_PRODUCTION',
}

export {
  GlassesEnvironment
};
