import { Catalog, CatalogConfiguration, GlassesEnvironment, UpcAvailability } from '@luxottica/vm-glasses';
import { DefaultAssetEnvironmentOptions } from '../../constants/DefaultEnvironmentOptions';
import { DefaultTrackingOptions } from '../../constants/DefaultTrackingOptions';
import { MountComponentError, NotInitializedError, UpcMissingError } from '../../errors/errors';
import { AdobeHelper } from '../../helpers/AdobeHelper';
import { AnalyticsHelper } from '../../helpers/AnalyticsHelper';
import { ParameterHelper } from '../../helpers/ParameterHelper';
import { TransitionColorHelper } from '../../helpers/TransitionColorHelper';
import { MirrorInternalOptions } from '../../interfaces/MirrorOptions';
import { RenderMirrorParams } from '../../interfaces/RenderingTypes';
import { MirrorActions } from '../../reducers/mirrorReducer';
import { createLogger, sendAppViewSession } from '../../remotelog/VmLogger';
import { vmIsInitialized, VmStore } from '../VmCore';
import { fitMeBetter } from './FitMeBetter';

const logger = createLogger('RenderMirror');

const getUpcAvailability = (
  glassesConfig: CatalogConfiguration,
  upc: string
): Promise<UpcAvailability> => {
  return Catalog.getUpcAvailability(glassesConfig, upc);
};

const isDistributorEnv = (glassesEnvironment: GlassesEnvironment): boolean => {
  return glassesEnvironment === GlassesEnvironment.DISTRIBUTOR_APPROVATION_PRODUCTION ||
    glassesEnvironment === GlassesEnvironment.DISTRIBUTOR_APPROVATION_STAGING ||
    glassesEnvironment === GlassesEnvironment.DISTRIBUTOR_VALIDATION_PRODUCTION ||
    glassesEnvironment === GlassesEnvironment.DISTRIBUTOR_VALIDATION_STAGING;
};

const renderMirror = (
  params: RenderMirrorParams
): Promise<void> => {
  if (!vmIsInitialized()) {
    return Promise.reject(new NotInitializedError());
  }

  const { target, upc, options } = params;

  AnalyticsHelper.onSessionStart();
  const glassesConfig: CatalogConfiguration = {
    // IMPORTANT
    // This toUpperCase has been added to quick go in production on Jun 27th 2022 on Oakley configurator
    // The configutator wrongly return lowercase environments and now is too late to release a client side fix
    env: (!!options?.environment?.glasses) ? options.environment.glasses.toUpperCase() as GlassesEnvironment : DefaultAssetEnvironmentOptions.glasses
  };

  // TODO: should transition colors be downloaded on every render?
  return Promise.all([
    getUpcAvailability(glassesConfig, upc),
    TransitionColorHelper.downloadTransitionColors(glassesConfig)
  ]).then((result) => {
    const upcAvailability: UpcAvailability = result[0];
    // TODO: improve glasses type management
    const glassesType: GlassesType = upcAvailability.glassesType; // (options.glassesType) ? options.glassesType : 'B3D';

    // TODO: leave for mock env
    if (glassesConfig.env === GlassesEnvironment.MOCK) {
      logger.debug('rendering virtual mirror for upc {} on target {}', upc, target);
      sendAppViewSession.upc(upc);
      const mergedOptions = ParameterHelper.mergeMirrorOptions(target, glassesType, options);
      return renderMirrorPromise(target, upcAvailability, mergedOptions);

    } else {
      if (glassesType === 'B3D' && (upcAvailability.available[256] || upcAvailability.available[512] || isDistributorEnv(glassesConfig.env))
      || glassesType === 'GLTF' && (!!upcAvailability.lods.includes(1) || !!upcAvailability.lods.includes(2))) {
        logger.debug('rendering virtual mirror for upc {} on target {}', upc, target);
        sendAppViewSession.upc(upc);
        const mergedOptions = ParameterHelper.mergeMirrorOptions(target, glassesType, options);
        return renderMirrorPromise(target, upcAvailability, mergedOptions);
  
      } else {
        logger.warn('rendering virtual mirror not possible: upc {} isn\'t supported', upc);
        sendAppViewSession.upcError(upc);
        const upcMissingError = new UpcMissingError(upc);
        AdobeHelper.sendError(upcMissingError, upc);
        throw upcMissingError;
      }
    }
  });
};

const renderMirrorPromise = (
  target: string,
  upcAvailability: UpcAvailability,
  mirrorOptions: Required<MirrorInternalOptions>
): Promise<void> => {

  return new Promise((resolve, reject) => {
    import(/* webpackChunkName: "MirrorAsyncRoot" */ '../mirror/MirrorAsyncRoot')
    .then(({ MirrorAsyncRoot: MirrorAsyncRoot }) => {
      VmStore.dispatch(MirrorActions.setMirrorOptions(mirrorOptions));
  
      logger.debug('vm default tracking options: {}', JSON.stringify(DefaultTrackingOptions));
      logger.debug('vm mirror options: {}', JSON.stringify(mirrorOptions));
      logger.debug('mounting render mirror component on div {}', target);
  
      try {
        const container = document.getElementById(target);
        if (container) {
          MirrorAsyncRoot.mountMirror(
            container,
            VmStore,
            upcAvailability,
            resolve,
            reject,
            mirrorOptions);
          addHandlerForOrientationChange();
        } else {
          const mountComponentError = new MountComponentError(target);
          AdobeHelper.sendError(mountComponentError);
          reject(mountComponentError);
        }
      } catch (e) {
        const mountComponentError = new MountComponentError(target);
        AdobeHelper.sendError(mountComponentError);
        reject(mountComponentError);
      }
    }).catch((e) => {
      logger.error(e);
      const mountComponentError = new MountComponentError(target);
      AdobeHelper.sendError(mountComponentError);
      reject(mountComponentError);
    });
  });
};

const addHandlerForOrientationChange = () => {
  if (window) {
    window.addEventListener('orientationchange', () => {
      const landscape = (window.orientation === 90 as any || window.orientation === -90 as any);
      logger.debug('orientation change detected [new value is {} - landscape: {}]', window.orientation, landscape);
      sendAppViewSession.orientationChange(landscape);
      window.setTimeout(() => fitMeBetter(), 150);
    });
  }
};

export {
  renderMirror,
};
