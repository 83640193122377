import { name, version } from "../../../../package.json";
import { RemoteLogConfig } from "../../RemoteLogConfig";
import { TrackingStatusConsumer } from "../log/consumer/TrackingStatusConsumer";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "TrackingStatusConsumerJob");

class TrackingStatusConsumerJob {

  public static getInstance() {
    if (!TrackingStatusConsumerJob.instance) {
      TrackingStatusConsumerJob.instance = new TrackingStatusConsumerJob();
    }
    return TrackingStatusConsumerJob.instance;
  }

  private static instance: TrackingStatusConsumerJob;
  private started: boolean = false;
  private intervalFunction: any;

  private constructor() {
    logger.info("remote log consumer job created");
  }

  public start(): void {
    if (this.started === true) {
      logger.debug("already started");
    } else {
      logger.info("start to consume logs");
      this.started = true;
      this.intervalFunction = window.setInterval(new TrackingStatusConsumer().consume, 3000);
    }
  }

  public stop(): void {
    if (this.intervalFunction) {
      window.clearInterval(this.intervalFunction);
    }
  }

}

export { TrackingStatusConsumerJob };
