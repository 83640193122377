import { v4 as uuid } from "uuid";
import { DBTrackingStatusFactory } from "../../../../adapter/db/factory/DBTrackingStatusFactory";
import { LogsConfig } from "../../../../config/LogsConfig";
import { IRemoteTrackingStatus } from "../../../model/IRemoteTrackingStatus";

class TrackingStatusService {

  public log(message: IRemoteTrackingStatus): void {
    const timestamp = this.now();
    LogsConfig.getInstance().isTrackingStatusActive().then((isActive) => {
      if (isActive) {
        this.queueMessage(message, timestamp);
      }
    });
  }

  private queueMessage(message: IRemoteTrackingStatus, timestamp: string) {
    this.dbInstance().getDB().setItem(uuid(), {
      date: timestamp,
      fps: message.fps,
      trackingCode: message.trackingCode,
      trackingText: message.trackingText,
    });
  }

  private dbInstance() {
    return DBTrackingStatusFactory.getInstance();
  }

  private now() {
    return new Date().toISOString();
  }

}

export { TrackingStatusService };
