import { AgreementType, BipaManager, BipaOverlay, BipaPage } from '@luxottica/bipa-manager';
import React, { lazy, Suspense, useState } from 'react';
import { getAuthData } from '../../helpers/AuthHelper';
import { CallbackHelper } from '../../helpers/CallbackHelper';
import { CONFIG } from '../../helpers/ConfigHelper';
import { VtoCaptureOptions } from '../../interfaces/CaptureParameters';
import { LoadingSpinner } from '../LoadingSpinner';
import './VtoCaptureContainer.scss';
import { VtoCaptureErrorOverlay } from './VtoCaptureErrorOverlay';


type agreementStatus = 'INITIAL' | 'APPROVED' | 'REJECTED' | 'ERROR';

const VtoCaptureContainer = (props: {
  options: VtoCaptureOptions
}) => {

  // begin to download the capture chunk while the user is checking the privacy
  // in case the privacy is already accepted the user will see a loading spinner
  const lazyVtoCapturePromise = import(/* webpackChunkName: "VtoCaptureVideo" */ './VtoCaptureVideo')
    .then(({ VtoCaptureVideo }) => ({ default: VtoCaptureVideo }));

  // REACT.lazy wrapper used to display a spinner while loading
  const LazyVtoCaptureVideo = lazy(() => lazyVtoCapturePromise);

  const [agreementStatus, setAgreementStatus] = useState<agreementStatus>(
    BipaManager.biometricCookieExists(AgreementType.BIOMETRIC) ? 'APPROVED' : 'INITIAL'
  );

  let content;

  switch (agreementStatus) {
    case 'INITIAL':
      CallbackHelper.onPrivacyDisplay();
      content = (
        <BipaOverlay
          type={AgreementType.BIOMETRIC}
          tokenVersion={CONFIG.tokenVersion}
          accessToken={getAuthData().accessToken}
          refreshToken={getAuthData().refreshToken}
          environment={CONFIG.environment}
          locale={CONFIG.locale}
          source={CONFIG.source}
          region={CONFIG.region}
          callbacks={{
            onDisplayPage: (page: BipaPage) => {
              CallbackHelper.onDisplayPrivacyPage(page);
            },
            onSubmit: () => {
              // close the Privacy agreement and start the experience
              setAgreementStatus('APPROVED');
            },
            onUserReject: () => {
              // close the Privacy agreement and BLOCK the experience
              setAgreementStatus('REJECTED');
              CallbackHelper.onPrivacyReject();
            },
            onError: (error) => {
              setAgreementStatus('ERROR');
              CallbackHelper.onPrivacyFail(error);
            },
            onAuthUpdated: CallbackHelper.onAuthUpdated
          }} privacy={{
            termsAndConditions: CONFIG.privacy.termsAndConditions,
            localizationText: CONFIG.privacy?.localizationText
          }}/>
      );
      break;
    case 'APPROVED':
      CallbackHelper.onCaptureProcess();
      content = (
        <Suspense fallback={<LoadingSpinner background={true} width={300} height={300}/>}>
          <LazyVtoCaptureVideo options={props.options}/>
        </Suspense>
      );
      break;
    case 'ERROR':
      content = <VtoCaptureErrorOverlay enabled onClickOk={() => { setAgreementStatus('INITIAL'); }} />;
      break;
    case 'REJECTED':
    default:
      content = <></>;
      break;
  }

  return (
    <div className='vto-capture-container'>
      {content}
    </div>
  );
};

export {
  VtoCaptureContainer
};
