import { Action, createAction, handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { PrivacyOptions } from '../interfaces/InitializationTypes';

const initialState: AppStoreState = {
  environment: 'PRODUCTION',
  loading: {
    WEBCAM_ACCESS: false,
    INITIALIZATION: false,
    POSETRACKER: false,
    RENDERING: false,
    GLASSES: false,
    MESH: false
  },
  privacy: { },
  auth: {
    accessToken: undefined,
    refreshToken: undefined
  }
};

export const AppActions = {
  setEnvironment: createAction<MicroserviceEnvironment>(Actions.SET_ENVIRONMENT),
  setAuth: createAction<AuthenticationOptions>(Actions.SET_AUTH),
  resetAuth: createAction(Actions.RESET_AUTH),
  setPrivacyOptions: createAction<PrivacyOptions>(Actions.SET_PRIVACY_OPTIONS),
  setLoadingStatus: createAction<{type: AppLoadingType, status: boolean}>(Actions.SET_LOADING_STATUS),
};

export const AppReducer = handleActions<AppStoreState, any>({

  [Actions.SET_ENVIRONMENT]: (state, action: Action<MicroserviceEnvironment>): AppStoreState => {
    return (!!action.payload) ? {
      ...state,
      environment: action.payload
    } : {
      ...state
    };
  },

  [Actions.SET_AUTH]: (state, action: Action<AuthenticationOptions>): AppStoreState => {
    if (!!action.payload.accessToken && !!action.payload.refreshToken) {
      return {
        ...state,
        auth: {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
        }
      };
    } else {
      return {
        ...state
      };
    }
  },

  [Actions.RESET_AUTH]: (state): AppStoreState => {
    return {
      ...state,
      auth: {
        accessToken: undefined,
        refreshToken: undefined,
      }
    };
  },

  [Actions.SET_PRIVACY_OPTIONS]: (state, action: Action<PrivacyOptions>): AppStoreState => {
    return {
      ...state,
      privacy: {
        ...action.payload
      }
    };
  },

  [Actions.SET_LOADING_STATUS]: (state, action: Action<{type: AppLoadingType, status: boolean}>): AppStoreState => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [action.payload.type]: action.payload.status
      }
    };
  },

}, initialState);
