import { Palette } from '@root/types/common'
import { createApi } from '@reduxjs/toolkit/query/react'
import { selectAccessToken } from '@root/store/user/selectors'
import { staggeredBaseQueryWithBailOut } from '@root/utils/services'
import { useSelector } from 'react-redux'

export const colorPaletteApi = createApi({
  reducerPath: 'colorPaletteApi',
  baseQuery: staggeredBaseQueryWithBailOut(
    process.env.REACT_APP_COLOR_PALETTE_URL ||
      'https://test-gateway-vtoprofile.luxdeepblue.com/services/vtovideoprofilems/public/v1',
  ),
  endpoints: builder => ({
    getColorPalette: builder.query<Palette[], { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: '/color-palette-keys',
        headers: {
          'X-XSRF-TOKEN': '',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
})

export const useGetColorPaletteQuery = () => {
  const accessToken = useSelector(selectAccessToken)

  return colorPaletteApi.useGetColorPaletteQuery({ accessToken })
}
