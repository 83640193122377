import { toDataURL } from './ImageLoader';
import { IMockBridgeMedia } from './IMockBridgeMedia';
import { MockStream } from './MockStream';

class MockBridgeMedia implements IMockBridgeMedia {

  public getUserMedia(constraints: MediaStreamConstraints): Promise<MockStream> {
    // const requiredResolution: any = this.getRequiredResolution(constraints);

    return new Promise<MockStream> ((resolve, reject) => {
      const videoSrc = localStorage.getItem('vm.mock.videoSrc');
      if (videoSrc === null) {
        reject(new Error('video src undefined'));
      } else {
        return toDataURL(
          videoSrc
        ).then((dataUrl) => {
          console.log('RESULT OK');
          resolve(new MockStream());
        }).catch((e) => {
          console.error(e);
        });
      }
    });
  }
}

export { MockBridgeMedia };
