import { AgreementType, BipaManager, BipaPage, PrivacyLocalization } from '@luxottica/bipa-manager';
import { GuidanceCommand, PoseTracker, WasmEnvironment } from '@luxottica/vm-posetracker';
import { RemoteLog, RemoteLogConfig } from '@luxottica/vm-remotelog';
import { name, version } from '../package.json';
import { closeFrameAdvisorComponent, loadFrameAdvisor } from './components/fa-capture/FrameAdvisorLoader';
import { uploadImageData } from './components/upload-image/UploadImageData';
import { closeUploadImageComponent, loadUploadImage } from './components/upload-image/UploadImageLoader';
import { closeVtoCaptureComponent, loadVtoCapture } from './components/vto-capture/VtoCaptureLoader';
import { FrameAdvisorError, InitializationError, MountError, NotInitializedError } from './errors/errors';
import { setAuthData } from './helpers/AuthHelper';
import { setCallbacks } from './helpers/CallbackHelper';
import { setConfig } from './helpers/ConfigHelper';
import { LocalizationHelper } from './helpers/LocalizationHelper';
import { setDefaultCaptureOptions, setDefaultInitializeOptions, setDefaultUploadImageOptions, setDefaultVtoCaptureOptions, validatePrivacyOptions } from './helpers/ParameterHelper';
import { RequestHelper } from './helpers/RequestHelper';
import { SupportedFeaturesHelper } from './helpers/SupportedFeaturesHelper';
import { CaptureParameters, FrameAdvisorProperty, FrameAdvisorResult, UploadImageDataParameters, UploadImageParameters, VtoCaptureParameters } from './interfaces/CaptureParameters';
import { AuthenticationOptions, FrameAdvisorCallbacks, FrameAdvisorRegion, InitializeParameters, PrivacyOptions } from './interfaces/InitializeParameters';
import { InitializeResult } from './interfaces/InitializeResult';

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, 'FrameAdvisorCapture');
const remoteLog: RemoteLog = new RemoteLog();
let initialized = false;

const onError = (e) => {
  remoteLog.sendAppViewSession({
    statusCode: '99-error',
    statusText: e.message
  });
};

const FrameAdvisorCapture = {

  version(): string {
    return 'v' + version;
  },

  initialize(
    initParams: InitializeParameters
  ): Promise<InitializeResult> {

    window.addEventListener('error', onError);
    logger.debug('initializing');
    initialized = false;

    // in this world promise is a valid alternative to try/catch block
    return Promise.resolve().then(() => {
      const validatedParams = setDefaultInitializeOptions(initParams.options);
      const validatedPrivacyOptions = validatePrivacyOptions(initParams.privacy);

      // set a CONFIG variable to be shared with capture() method
      setConfig(
        validatedParams.environment,
        validatedParams.region,
        validatedParams.source,
        validatedParams.locale,
        validatedPrivacyOptions, // validate privacy URLs
        validatedParams.tokenVersion
      );
      setCallbacks(initParams.callbacks);
      setAuthData(initParams.auth as AuthenticationOptions);

      return Promise.all([
        SupportedFeaturesHelper.getSupportedFeatures().then((features) => {
          return {
            locale: validatedParams.locale,
            isSupported: features.webcamAvailable && features.webcamAccess && features.wasmSupport,
            supportedFeatures: features
          };
        }),
        RequestHelper.login(validatedParams.tokenVersion)
      ]);
    }).then((responseData) => {
      // this downloads all the heavy stuff for the tracker engine
      PoseTracker.warmUp(WasmEnvironment.PROD);
      return LocalizationHelper.setLocalizationText(responseData[0].locale).then(() => {
        initialized = true;
        return {
          locale: responseData[0].locale,
          isSupported: responseData[0].isSupported,
          supportedFeatures: responseData[0].supportedFeatures,
        };
      });
    }).catch((e) => {
      if (e instanceof FrameAdvisorError) {
        throw e;
      } else {
        const error = new InitializationError(initParams, undefined, e);
        logger.error(error);
        throw error;
      }
    });
  },

  resetBipaState(): void {
    document.cookie = `${BipaManager.getCookieName(AgreementType.BIOMETRIC)}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  },

  capture(
    captureParams: CaptureParameters
  ): Promise<void> {
    if (!initialized) {
      return Promise.reject(new NotInitializedError());
    }

    const validatedOptions = setDefaultCaptureOptions(captureParams.options);
    return RequestHelper.startSession().then(() => {
      return new Promise((resolve, reject) => {
        const container = document.getElementById(captureParams.target);
        if (!!container) {
          loadFrameAdvisor(container, validatedOptions, () => { resolve(undefined); });
        } else {
          reject(new MountError(captureParams.target));
        }
      });
    });
  },

  close(): void {
    closeFrameAdvisorComponent();
    window.removeEventListener('error', onError);
  },

  vtoCapture(
    vtoCaptureParams: VtoCaptureParameters
  ): Promise<void> {
    if (!initialized) {
      return Promise.reject(new NotInitializedError());
    }

    const validatedOptions = setDefaultVtoCaptureOptions(vtoCaptureParams.options);
    return RequestHelper.startSession().then(() => {
      return new Promise((resolve, reject) => {
        const container = document.getElementById(vtoCaptureParams.target);
        if (!!container) {
          loadVtoCapture(container, validatedOptions, () => { resolve(undefined); });
        } else {
          reject(new MountError(vtoCaptureParams.target));
        }
      });
    });
  },

  closeVtoCapture(): void {
    closeVtoCaptureComponent();
    window.removeEventListener('error', onError);
  },

  uploadImage(
    uploadParams: UploadImageParameters
  ): Promise<void> {
    if (!initialized) {
      return Promise.reject(new NotInitializedError());
    }

    const validatedOptions = setDefaultUploadImageOptions(uploadParams.options);
    return new Promise((resolve, reject) => {
      const container = document.getElementById(uploadParams.target);
      if (!!container) {
        loadUploadImage(container, validatedOptions, () => { resolve(undefined); });
      } else {
        reject(new MountError(uploadParams.target));
      }
    });
  },

  uploadImageData(
    uploadParams: UploadImageDataParameters
  ): Promise<void> {
    if (!initialized) {
      return Promise.reject(new NotInitializedError());
    }

    const validatedOptions = setDefaultUploadImageOptions(uploadParams.options);
    return uploadImageData(uploadParams.imageData, validatedOptions);
  },

  closeUploadImage(): void {
    closeUploadImageComponent();
    window.removeEventListener('error', onError);
  },
};

export {
  FrameAdvisorCapture,
  GuidanceCommand,
  InitializeParameters,
  FrameAdvisorCallbacks,
  FrameAdvisorRegion,
  CaptureParameters,
  FrameAdvisorResult,
  FrameAdvisorProperty,
  BipaPage,
  PrivacyOptions,
  PrivacyLocalization,
};
