import { BipaPrivacyOptions } from '@luxottica/bipa-manager';
import { DefaultCaptureOptions } from '../constants/DefaultCaptureOptions';
import { DefaultInitializeOptions } from '../constants/DefaultInitializeOptions';
import { DefaultTrackingOptions } from '../constants/DefaultTrackingOptions';
import { DefaultTrackingOptionsPortrait } from '../constants/DefaultTrackingOptionsPortrait';
import { DefaultTrackingOptionsLandscape } from '../constants/DefaultTrackingOptionsLandscape';
import { CaptureOptions, UploadOptions, VtoCaptureOptions } from '../interfaces/CaptureParameters';
import { InitializeOptions, PrivacyOptions } from '../interfaces/InitializeParameters';
import { InitializationParamsError } from '../errors/errors';
import { DefaultUploadOptions } from '../constants/DefaultUploadOptions';
import { DefaultVtoCaptureOptions } from '../constants/DefaultVtoCaptureOptions';
import device, { DeviceType } from 'current-device';

const setDefaultInitializeOptions = (
  inputParams: Partial<InitializeOptions> = {}
): InitializeOptions => {
  // region and source are mandatory
  if (!inputParams.region || !inputParams.source) {
    throw new InitializationParamsError(inputParams);
  }
  return {
    ...DefaultInitializeOptions,
    ...inputParams
  };
};

const setDefaultCaptureOptions = (
  inputParams: Partial<CaptureOptions> = {}
): CaptureOptions => {
  const deviceType: DeviceType = device.tablet() || device.mobile() ? 'mobile' : 'desktop';

  return {
    ...DefaultCaptureOptions,
    ...inputParams,
    tracking: {
      ...DefaultTrackingOptions,
      ...inputParams.tracking,
      standstill: {
        ...(deviceType === 'mobile' ? DefaultTrackingOptionsPortrait.standstill : DefaultTrackingOptionsLandscape.standstill),
        ...inputParams.tracking?.standstill,
      },
    },
    showLoading: (inputParams.showLoading === undefined || inputParams.showLoading === null) ? DefaultCaptureOptions.showLoading : inputParams.showLoading,
  };
};

const setDefaultVtoCaptureOptions = (
  inputParams: Partial<VtoCaptureOptions> = {}
): CaptureOptions => {
  return {
    ...DefaultVtoCaptureOptions,
    ...inputParams,
    tracking: {
      ...DefaultTrackingOptions,
      ...inputParams.tracking,
      standstill: {
        ...DefaultTrackingOptions.standstill,
        ...inputParams.tracking?.standstill,
      },
    }
  };
};

const setDefaultUploadImageOptions = (
  inputParams: Partial<UploadOptions> = {}
): UploadOptions => {
  return {
    ...DefaultUploadOptions,
    ...inputParams,
    showLoading: (inputParams.showLoading === undefined || inputParams.showLoading === null) ? DefaultCaptureOptions.showLoading : inputParams.showLoading,
  };
};

const validatePrivacyOptions = (
  privacy: PrivacyOptions
): BipaPrivacyOptions => {
  // this throws an error if the URL is not valid
  return {
    termsAndConditions: new URL(privacy.termsAndConditions as string ),
    localizationText: privacy.localizationText,
  };
};

export {
  setDefaultInitializeOptions,
  setDefaultCaptureOptions,
  setDefaultVtoCaptureOptions,
  setDefaultUploadImageOptions,
  validatePrivacyOptions,
};
